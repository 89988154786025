// import { createStore } from "zustand/vanilla";
import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

const useTokenStore = create(
  persist(
    (set, get) => ({
      token: "",
      colorMode: undefined,
      vpWidth: 1200,
      svc_pkg_id: 1,
      //  window?.matchMedia("(prefers-color-scheme: dark)").matches
      //   ? "dark"
      //   : "light",
      toggleColorMode: () => {
        console.log("colorMode in toggle", get().colorMode);
        const nMode = get().colorMode === "light" ? "dark" : "light";
        set(() => ({ colorMode: nMode }));
        console.log("colorMode in toggle2", get().colorMode);
        // window.localStorage.setItem("colorMode", get().colorMode);
      },
      setColorMode: (nColorMode) => {
        set(() => ({ colorMode: nColorMode }));
      },
      setVpWidth: (nWidth) => {
        set(() => ({ vpWidth: nWidth }));
      },
    }),
    { name: "zu_token", storage: createJSONStorage(() => localStorage) }
  )
);
export { useTokenStore };
