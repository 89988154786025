import React, { forwardRef } from "react";
import { createStyles, makeStyles } from "@mui/material/styles";
import {
  Box,
  Stack,
  Divider,
  // Drawer,
  Toolbar,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import Collapse from "@mui/material/Collapse";
import Link from "@mui/material/Link";
import {
  SettingsOutlined,
  ChevronLeft,
  ChevronRightOutlined,
  HomeOutlined,
  ShoppingCartOutlined,
  Groups2Outlined,
  ReceiptLongOutlined,
  PublicOutlined,
  PointOfSaleOutlined,
  TodayOutlined,
  CalendarMonthOutlined,
  AdminPanelSettingsOutlined,
  TrendingUpOutlined,
  PieChartOutlined,
  CategoryIcon,
} from "@mui/icons-material";
import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";
import { Icon } from "../home/OverviewIcon";

import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import FlexBetween from "./FlexBetween";
import profileImage from "./assets/user.png";
// import { NavLink, Link } from "react-router-dom";
// import { NavLink as RouterLink, Outlet } from "react-router-dom";
import { NavLink, NavLinkProps } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
// import styled from "@emotion/styled";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import MenuIcon from "@mui/icons-material/Menu";
import MuiDrawer from "@mui/material/SwipeableDrawer";
import Tooltip from "@mui/material/Tooltip";
// import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import IconExpandLess from "@mui/icons-material/ExpandLess";
import IconExpandMore from "@mui/icons-material/ExpandMore";
// import SideBarMenuItem from "./SideBarMenuItem";
// import "./navlinkstyle.css";
// const Link = React.forwardRef(function Link(itemProps, ref) {
//   return <NavLink ref={ref} {...itemProps} role={undefined} />;
// });

// const drawerWidth = "250px";
// const NavLink = styled((props) => (
//   <RouterLink {...props} end={props.to === "/"} />
// ))(({ theme }) => ({
//   textDecoration: "none",
//   backgroundColor: "orange",
//   ":hover": {
//     backgroundColor: "blue",
//     color: "white",
//     border: 0,
//   },
//   "&.active": {
//     // color: "green",
//     // fontSize: theme.spacing(3),
//     backgroundColor: "red",
//   },
// }));

const Sidebar = ({
  navItems,
  anchor,
  user,
  drawerWidth,
  isMobile,
  isMini,
  setIsMini,
  drawerPosition,
  setDrawerPosition,
  isTemporary,
  // itemOpen,
  // setItemopen,
  // clickAwayPos,
  // setClickAwayPos,
}) => {
  const { pathname } = useLocation();
  const [active, setActive] = useState("");
  const navigate = useNavigate();
  const theme = useTheme();
  const activeLink = " bg-blue-100 text-black";
  const normalLink = "";
  // const isExpandable = items && items.length > 0;
  // const useStyles = makeStyles((theme) =>
  //   createStyles({
  //     activeLink: {
  //       background: "orange",
  //       color: "#FFFFFF",
  //       borderRadius: 8,
  //       // "& .MuiSvgIcon-root": {
  //       //   color: "#FFFFFF",
  //       //   stroke: "#FFFFFF",
  //       //   fill: "#19ABC0",
  //       // },
  //     },
  //     normalLink: {
  //       backgroundcolor: "white",
  //       color: "red",
  //       borderRadius: 8,
  //       // "& .MuiSvgIcon-root": {
  //       //   color: "#FFFFFF",
  //       //   stroke: "#FFFFFF",
  //       //   fill: "#19ABC0",
  //       // },
  //     },
  //   })
  // );

  const openedMixin = (theme) => ({
    // "& .MuiBackdrop-root": {
    //   display: "none",
    // },

    // position: "relative",
    ...(anchor === "left"
      ? {
          position: "relative",
          marginRight: "auto",
          width: drawerPosition.width,
        }
      : anchor === "top"
      ? { position: "relative", marginLeft: "auto", width: "100%" }
      : {
          position: "relative",
          marginRight: "auto",
          width: drawerPosition.width,
        }),

    // backgroundColor: theme.palette.background.default,
    backgroundColor: theme.palette.common.compbg,
    // backgroundColor: theme.palette.background.alt,
    color: theme.palette.secondary.main,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    // borderWidth: 0.1,
    borderColor: theme.palette.common.secondary5,
    // width: drawerWidth,
    // width: "100%",
    // overflowX: "hidden",
  });

  const miniMixin = (theme) => ({
    // position: "relative",
    // "& .MuiBackdrop-root": {
    //   display: "none",
    // },
    // "& .MuiDrawer-paper": {
    //   // width: drawerWidth,
    //   // width: `calc(${theme.spacing(7)} + 1px)`,
    //   // position: "absolute",
    //   // height: (props) => props.height,
    //   // transition: "none !important",
    // },
    ...(anchor === "left"
      ? {
          position: "relative",
          marginRight: "auto",
          width: drawerPosition.width,
        }
      : anchor === "top"
      ? { position: "relative", marginLeft: "auto", width: "100%" }
      : {
          position: "relative",
          marginRight: "auto",
          width: drawerPosition.width,
        }),
    // backgroundColor: theme.palette.background.alt,
    backgroundColor: theme.palette.common.compbg,
    color: theme.palette.secondary.main,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    // overflowX: "hidden",
    // width: `calc(${theme.spacing(5)} + 4px)`,
    // width: drawerPosition.width,
    boxSizing: "border-box",
    borderColor: theme.palette.common.secondary5,
    // borderWidth: 0,

    // [theme.breakpoints.up("sm")]: {
    //   width: `calc(${theme.spacing(8)} + 1px)`,
    // },
  });
  const closedMixin = (theme) => ({
    // position: "relative",

    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    // overflowX: "hidden",
    width: "0px",
    // [theme.breakpoints.up("sm")]: {
    //   width: `calc(${theme.spacing(8)} + 1px)`,
    // },
  });

  const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (props) => props !== "drawerposition",
  })(({ theme, drawerposition }) => ({
    // width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    // boxSizing: "border-box",
    borderRadius: "20px 20px 0 0",
    borderRight: 0,
    // closed

    ...(drawerposition.Pos === 0 && {
      ...closedMixin(theme, drawerposition),
      "& .MuiDrawer-paper": closedMixin(theme, drawerposition),
    }),
    // mini
    ...(drawerposition.Pos === 1 && {
      ...miniMixin(theme, drawerposition),
      "& .MuiDrawer-paper": miniMixin(theme, drawerposition),
    }),
    // full
    ...(drawerposition.Pos === 2 && {
      ...openedMixin(theme, drawerposition),
      "& .MuiDrawer-paper": openedMixin(theme, drawerposition),
    }),
  }));

  const [menu, setMenu] = useState({});
  const handleClick = (item) => {
    // console.log("handleClick:item:", item);
    let newData = { ...menu, [item]: !menu[item] };
    setMenu(newData);
    // console.log("handleClick:menu:", menu);
  };
  useEffect(() => {
    setActive(pathname);
  }, [pathname]);
  // console.log("MuiSidebarV1.pathname.substring(1)", pathname.substring(1));
  // console.log("MuiSidebarV1.active", active);
  // console.log("MuiSidebarV1.drawerPosition", drawerPosition);
  // console.log("MuiSidebarV1.isMobile:", isMobile);
  // console.log("MuiSidebarV1.anchor:", anchor);
  // console.log("MuiSidebarV1.navItems:", navItems);
  const toggleDrawer = (newPos) => (event) => {
    // if (
    //   event.type === "keydown" &&
    //   (event.key === "Tab" || event.key === "Shift")
    // ) {
    //   return;
    // }
    // setDrawerPosition(newPos);
    setDrawerPosition((state) => ({
      ...state,
      Pos: newPos,
      width: newPos === 0 ? "0px" : newPos === 1 ? "65px" : "100px",
      // clickAway: 2,
    }));
  };
  const AppMenuItem = (props) => {
    const { id, route_name, icon, route, items = [] } = props;
    // const { name, icon, route, items, itemOpen, setItemopen = [] } = props;

    const isExpandable = items && items.length > 0;
    var fullRoute = `${route}`;
    if (
      route === "/dashboard/ui_list_entity" ||
      route === "/dashboard/ui_list_entity2" ||
      route === "/dashboard/ui_cart_entity"
    ) {
      fullRoute = `${route}/` + props.entity_name;
    }
    // const [itemOpen, setItemopen] = React.useState();

    console.log("AppMenuItem::props:", props);
    // console.log("AppMenuItem::itemOpen:", itemOpen);
    // console.log("AppMenuItem::isExpandable:", isExpandable);
    // console.log("AppMenuItem::items:", items);

    // console.log("AppMenuItem::fullRoute:", fullRoute);
    // console.log("AppMenuItem::active:", active);
    // function handleClick() {
    //   // console.log("AppMenuItem::handleClick::itemOpen:", itemOpen);
    //   setItemopen(!itemOpen);
    //   // console.log("AppMenuItem::handleClick::itemOpen after:", itemOpen);
    // }

    const MenuItemRoot = (
      <ListItem
        key={route_name}
        disablePadding
        sx={{
          m: 0,
          p: 0,
          // border: 1,
          // borderRadius: "20x 20x 2x 20x ",
          backgroundColor:
            active === fullRoute
              ? theme.palette.common.secondary2
              : theme.palette.common.compbg,
        }}
      >
        <ListItemButton
          // component={RouterLink}
          // link={route}
          // clickFn={handleClick}
          // clickFn={() => handleClick(name)}
          // onMouseOver={() => {
          //   console.log("onMouseOver::drawerPosition:", drawerPosition);
          //   console.log("onMouseOver::!route:", !route);

          //   if (drawerPosition.Pos == 1 && !route) {
          //     console.log("onMouseOver::name:", name);
          //     handleClick(name);
          //   }
          // }}
          onClick={() => {
            // setIsSidebarOpen(isMobile ? true : false);
            // setDrawerPosition(isMobile ? 0 : drawerPosition);
            // handleClick(name);
            if (route) {
              setDrawerPosition((state) => ({
                ...state,
                Pos: isMobile ? 0 : state.Pos,
                clickAway: 0,
                width: isMobile ? 0 : state.width,
              }));
              if (
                route === "/dashboard/ui_list_entity" ||
                route === "/dashboard/ui_list_entity2" ||
                route === "/dashboard/ui_cart_entity"
              ) {
                console.log("navitems in onclick");
                // const fullRoute = `${route}/` + props.entity_name;
                console.log("fullRoute:navitems in onclick", fullRoute);
                // setActive(fullRoute);
                navigate(`${fullRoute}`, {
                  state: { title: route_name },
                });

                // navigate(`${route}/` + props.entity_name, {
                //   state: { title: route_name },
                // });
              } else {
                navigate(`${route}`, { state: { title: route_name } });
              }
              // navigate(`${route}`, { state: { title: route_name } });
              // navigate(`ui_list_entity/?entity_id=${id}`, {
              // state: { title: route_name },
              // });

              // component={forwardRef(() => <NavLink exact />)}
            } else {
              // setItemopen(!menu[name]);
              handleClick(route_name);
              // console.log("AppMenuItem::onClick::itemOpen after:", itemOpen);
            }
            // {route}
            setActive(route);
          }}
          sx={{
            // justifyContent: isSidebarOpen ? "initial" : "center",
            m: 0,
            p: 0,
            py: 0.8,
            pl: drawerPosition.Pos === 1 ? 1 : 0.5,
            justifyContent: "flex-start",
            alignItems: "center",
            // border: 1,
            // fontWeight:"bold",
            backgroundColor:
              active === fullRoute
                ? theme.palette.common.secondary2
                : theme.palette.common.compbg,
            // color:
            //   active === route
            //     ? theme.palette.textPrimary
            //     : theme.palette.textAlt,
          }}
        >
          <Tooltip title={route_name}>
            <ListItemIcon
              sx={{
                minWidth: 0,
                m: 0,
                p: 0,
                // border: 1,
                // mr: drawerPosition.Pos === 1 ? 0 : 2,

                // justifyContent: "flex-start",
                // alignItems: "flex-start",
                // backgroundColor:
                //   active === route
                //     ? theme.palette.primary.main
                //     : theme.palette.common.compbg,
              }}
            >
              {/* {icon} */}
              {<Icon type={icon} />}
            </ListItemIcon>
          </Tooltip>

          {drawerPosition.Pos !== 1 && (
            <ListItemText
              primary={route_name}
              primaryTypographyProps={{ style: { whiteSpace: "normal" } }}
              sx={{
                m: 0,
                p: { xs: 1, sm: 0.5 },
                ml: drawerPosition.Pos === 1 ? 0 : 2,
                opacity: drawerPosition.Pos === 1 ? 0 : 1,
              }}
            />
          )}

          {/* {active === route && drawerPosition.Pos === 2 && (
            <ChevronRightOutlined sx={{ ml: "auto" }} />
          )} */}
          {isExpandable && !menu[route_name] && (
            <IconExpandMore sx={{ m: 0, p: 0, border: 0 }} />
          )}
          {isExpandable && menu[route_name] && (
            <IconExpandLess sx={{ m: 0, p: 0, border: 0 }} />
          )}
        </ListItemButton>
      </ListItem>
    );
    const MenuItemChildren = isExpandable ? (
      <Collapse in={menu[route_name]} timeout="auto" unmountOnExit>
        <Divider />
        <List component="div" disablePadding>
          {items.map((item, index) => (
            <AppMenuItem
              drawerPosition={drawerPosition}
              {...item}
              key={index}
            />
          ))}
          {/* <div>CItem:{itemOpen ? 1 : 0}</div> */}
        </List>
      </Collapse>
    ) : null;

    return (
      <>
        <Stack
          sx={{
            m: 0,
            p: 0,
            // , border: 1
          }}
        >
          {MenuItemRoot}
        </Stack>
        <Stack
          sx={{
            m: 0,
            p: 0,
            ml: drawerPosition.Pos === 1 ? 0.5 : 1,
            // border: 1,
          }}
        >
          {MenuItemChildren}
        </Stack>
      </>
    );
  };
  return (
    // <ClickAwayListener
    //   mouseEvent="onMouseDown"
    //   touchEvent="onTouchStart"
    //   disableReactTree
    //   // open={drawerPosition !== 0}
    //   onClickAway={() => {
    //     // alert("Clickaway:" + drawerPosition);
    //     console.log("onClickAway:drawerPosition:", drawerPosition);
    //     // console.log("onClickAway:clickAwayPos:", clickAwayPos);
    //     // setDrawerPosition(
    //     //   isMobile
    //     //     ? drawerPosition === 2
    //     //       ? 0
    //     //       : drawerPosition
    //     //     : drawerPosition
    //     // );
    //     // setClickAwayPos(isMobile && drawerPosition === 2 ? 2 : 0);
    //     // setClickAwayPos(drawerPosition === 2 ? 2 : clickAwayPos);
    //     if (isMobile) {
    //       if (drawerPosition.clickAway === 0 && drawerPosition.Pos === 2) {
    //         // setClickAwayPos(1);
    //         // setDrawerPosition(0);
    //         setDrawerPosition((prevState) => ({
    //           ...prevState,
    //           Pos: 0,
    //           clickAway: 1,
    //         }));
    //       } else if (drawerPosition.clickAway === 2) {
    //         // setClickAwayPos(0);
    //         setDrawerPosition((prevState) => ({
    //           ...prevState,
    //           Pos: 0,
    //           // clickAway: 2,
    //         }));
    //       }
    //     }
    //     // setDrawerPosition(0);
    //     // } else if (clickAwayPos === 0) {
    //     //   setDrawerPosition(drawerPosition === 0 ? 2 : drawerPosition);
    //     // }
    //     console.log("onClickAway:drawerPosition2:", drawerPosition);
    //     // console.log("onClickAway:clickAwayPos2:", clickAwayPos);

    //     // !isMobile ? 2 drawerPosition !== 0
    //     //   ? setDrawerPosition(0)
    //     //   : setDrawerPosition(drawerPosition);
    //     // drawerPosition !== 0 && setDrawerPosition(isMobile ? 0 : drawerPosition)
    //   }}
    // >
    <Drawer
      drawerposition={drawerPosition}
      open={drawerPosition.Pos !== 0}
      // open={false}
      // mini={isMini}
      // open={true}
      // onClose={() => setIsSidebarOpen(false)}
      // onClose={() => setDrawerPosition(0)}
      // onClose={toggleDrawer(0)}
      onClose={toggleDrawer(drawerPosition.Pos)}
      onOpen={toggleDrawer(drawerPosition.Pos)}
      variant={
        isMobile ? "persistent" : isTemporary ? "persistent" : "permanent"
      }
      anchor={anchor}
      // anchor="right"
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
      PaperProps={
        {
          // elevation: 2,
          // border: 1,
          // bordercolor: "common.secondary5",
          // borderRadius: "10px 10px 10px 10px",
        }
      }
      // onMouseLeave={() => {
      //   setDrawerPosition(isMobile ? 0 : drawerPosition);
      // }}
    >
      {/* <div>{drawerPosition.width}</div>
      <div>{drawerPosition.Pos}</div> */}
      {/* <Box
        width="100%"
        sx={{
          alignItems: "center",
          justifyContent: "center",
          p: 0,
          // mx: 2, my: 0
          // ...theme.mixins.toolbar,
          // justifyContent: "flex-end",
        }}
      > */}
      {/* <Box m="1.5rem 2rem 2rem 3rem"> */}
      {/* <Box
          sx={{
            alignItems: "center",
            p: 0,
            // mx: 2, my: 0
            // ...theme.mixins.toolbar,
            // justifyContent: "flex-end",
          }}
        > */}
      {/* <FlexBetween color={theme.palette.secondary.main}> */}
      {/* <Box display="flex" alignItems="center" gap="0.5rem">
              <Typography
                sx={{ color: "secondary.main", underline: "none" }}
                component={Routerlink}
                to="/"
                variant="h4"
                fontWeight="bold"
              >
                NIGGA
              </Typography>
            </Box> */}
      {/* {!isNonMobile && (
          <IconButton onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
            <ChevronLeft />
          </IconButton>
        )} */}
      {/* {!isMini && (
          <IconButton onClick={() => setIsMini(true)}>
            <ChevronLeft />
          </IconButton>
        )} */}
      {/* {(drawerPosition === 1 || drawerPosition === 2) && (
          <IconButton
            onClick={() => setDrawerPosition(drawerPosition === 1 ? 0 : 1)}
          >
            <ChevronLeft />
          </IconButton>
        )} */}
      {/* </FlexBetween> */}
      {/* </Box> */}
      {/* <Divider /> */}
      {/* <List> */}
      {/* <Toolbar /> */}
      <Stack
        direction="column"
        // onMouseLeave={() => {
        //   setDrawerPosition(isMobile ? 0 : drawerPosition);
        // }}
        sx={{
          // bgcolor: "secondary.main",
          // bgcolor: "background.default",
          // justifyContent: "center",
          // alignItems: "center",
          p: 0,
          // pr: 0.2,
          m: 0.2,
          overflow: "auto",
          // border: 1,
          // borderRadius: "10px 10px 10px 10px",
          // , alignItems: "flex-start", pr: 2
        }}
      >
        {/* {navItems.map(({ name, icon, route, items }) => { */}
        {navItems
          .filter((item) => item.route_loc === "L")
          .map((item, index) => (
            <AppMenuItem {...item} key={index} />
          ))}
      </Stack>
      {/* </List> */}
      {/* <Divider sx={{ m: 1 }} /> */}
      {/* </Box> */}

      {/* <Box position="absolute" bottom="2rem">
        <Divider />
        <FlexBetween textTransform="none" gap="1rem" m="1.5rem 2rem 0 3rem">
          <Box
            component="img"
            alt="profile"
            src={profileImage}
            height="40px"
            width="40px"
            borderRadius="50%"
            sx={{ objectFit: "cover" }}
          />
          <Box textAlign="left">
            <Typography
              fontWeight="bold"
              fontSize="0.9rem"
              sx={{ color: theme.palette.secondary[100] }}
            >
              aaaaaa
            </Typography>
            <Typography
              fontSize="0.8rem"
              sx={{ color: theme.palette.secondary[200] }}
            >
              bbbbbbbb
            </Typography>
          </Box>
          <SettingsOutlined
            sx={{
              color: theme.palette.secondary[300],
              fontSize: "25px ",
            }}
          />
        </FlexBetween>
      </Box> */}
      {/* </MuiDrawer> */}
    </Drawer>
    // </ClickAwayListener>
  );
};

export default Sidebar;
