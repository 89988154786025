import myAxios from "../MyAxios";
import { BlockRounded } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useTokenStore } from "./zuTokenStore";
import { useCustStore } from "./zuCustStore";
export const callUICrud = async (ui_obj, ui_action, idata) => {
  // console.log("in callUICrud::ui_obj:", ui_obj);
  // console.log("in callUICrud::ui_action:", ui_action);
  console.log("in callUICrud::idata:", idata, "\n callUICrud::ui_obj:", ui_obj);
  const controller = new AbortController();
  try {
    const res = await myAxios.post(`/assets/handleCrud`, {
      data: idata,
      // crud_json: { obj_type: "asset_type", crud_action: "R" },
      // crud_json: { obj_type: `${ui_obj}`, crud_action: `${ui_action}` },
      crud_json: { obj_type: ui_obj, crud_action: ui_action },
    });
    console.log(
      "direct response from callUICrud:",
      res,
      "\n callUICrud::ui_obj:",
      ui_obj
    );
    // if (res.status === 200 && res.data.statusCd == 0) {
    if (res.status === 200) {
      // console.log("200 response from callUICrud:", res);
      const data = await res.data.data;
      // console.log("callUICrud:", data);
      return data;
    }
  } catch (err) {
    throw err;
  }
  controller.abort();
};

export const menuListLoader = async (ffrows) => {
  // console.log("getData5 :: filteredData2 ::: ", filteredData2);

  var menuListJSON = {};

  for (const ffrow of ffrows) {
    const temp = await callUICrud(ffrow.ui_data.menulist_name, "R", {
      svc_pkg_id: useTokenStore.getState().svc_pkg_id,
      cust_id: useCustStore.getState().currentUser.cust_id,
      menulist_name: ffrow.ui_data.menulist_name,
      list: 1,
    });
    if (temp.length > 0) {
      menuListJSON = { ...menuListJSON, [ffrow.ui_data.menulist_name]: temp };
    }
    // console.log("post loop iteration :: menuListJSON :::", menuListJSON);
  }

  return menuListJSON;
};
