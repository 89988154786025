import React from "react";
// import PropTypes from "prop-types";
// import Button from "@mui/material/Button";
import { Box, Button, Hidden, Tooltip } from "@mui/material";
// import Tooltip from "@mui/material/Tooltip";
// import { styled } from "@mui/system";
import { Icon } from "../home/OverviewIcon";
function StyledButtonWIcon(props) {
  const {
    children,
    size,
    selected,
    sx,
    smDown_hideLabel,
    iconName,
    ...otherProps
  } = props;
  return (
    <Tooltip title={children}>
      <Button
        variant="contained"
        disableElevation
        disableRipple
        size={size ?? "large"}
        startIcon={
          <Icon
            type={iconName}
            // sx={{ mr: -0.6, my: -0.6, p: 0, border: 0 }}
          />
        }
        sx={{
          m: 0,
          p: 1,
          // px: 0,
          mx: 0.5,
          minWidth: 10,
          // border: 1,
          "& .MuiButton-startIcon": {
            m: 0,
            p: 0,
            pr: 0.5,
          },
          fontSize: {
            xs: "subtitle1.fontSize",
            sm: "subtitle1.fontSize",
          },
          fontWeight: "bold",
          color: selected ? "secondary5.common" : "secondary.main",
          // border: selected ? 0.2 : 0,
          // borderColor: "secondary.main",
          borderRadius: props.pill ? 50 : "10px 10px 10px 10px",
          boxShadow: 0,
          ":hover": {
            boxShadow: 0,
            bgcolor: "common.secondary5",
            color: "primary.main",
          },
          ":disabled": {
            boxShadow: 0,
            color: "common.secondary2",
          },
          "&.active": {
            // bgcolor: selected ? "common.compbg" : "secondary.main",
            // color: selected ? "common.secondary5" : "textAlt",
            bgcolor: "common.secondary5",
            color: "primary.main",
            // border: 0.2,
          },
          "&:clicked": {
            // bgcolor: selected ? "common.compbg" : "secondary.main",
            color: selected ? "common.secondary5" : "textAlt",

            // border: 0.2,
          },
          ...sx,
        }}
        {...otherProps}
      >
        {smDown_hideLabel ? <Hidden smDown>{children}</Hidden> : children}
      </Button>
    </Tooltip>
  );
}

// StyledButton.propTypes = {
//   onClick: PropTypes.func.isRequired,
// };

// export default StyledButton;

// const StyledButton = styled(
//   "Button"
//   // , { shouldForwardProp: (props) => props}
// )(({ theme }) => ({
//   variant: "contained",
//   color: "textAlt",
//   disableElevation: true,
// }));
export default StyledButtonWIcon;
