import React, { memo, useEffect, useRef, useMemo, useCallback } from "react";
import { useState } from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { KeenSliderArrows } from "./Overview";
import CircularProgress from "@mui/material/CircularProgress";
import { useTokenStore } from "../store/zuTokenStore";
import { Card, CardMedia } from "@mui/material";
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";

function Reviews(props) {
  const { reviews } = props;
  // console.log("Reviews::reviews:", reviews);
  const ColorMode = useTokenStore(useCallback((state) => state.colorMode));
  // const SlideStack = {
  //   position: "relative",
  //   // bgcolor: "primary.main",
  //   bgcolor: "common.compbg",
  //   mx: 0,
  //   my: 0,
  //   px: 3,
  //   // height: "300px",
  //   // border: 1,
  //   borderRadius: "20px 20px 20px 20px",
  //   // borderRadius: "50%",
  // };
  const SlideStack = {
    position: "relative",
    bgcolor: "common.compbg",
    mx: 1,
    my: 1,
    px: 3,
  };

  // const KeenSliderSlide3 = {
  //   // bgcolor: "common.compbg",
  //   bgcolor: "primary.main",
  //   justifyContent: "center",
  //   alignItems: "center",
  //   textAlign: "center",
  //   m: 0,
  //   p: 0,
  //   // py: 1,
  //   border: "0.0px",
  //   // borderStyle: "solid",
  //   // width: "150px",
  //   // height: { xs: "250px", sm: "300px" },
  //   borderRadius: "20px 20px 20px 20px",
  //   // borderRadius: "50%",
  // };
  const KeenSliderSlide3 = {
    bgcolor: "common.compbg",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    m: 0,
    p: 4,
    mx: 0,
    my: 2,
    borderRadius: "20px 20px 20px 20px",
    boxShadow: 3,
  };
  const [currentSlide3, setCurrentSlide3] = useState(0);
  const [loaded3, setLoaded3] = useState(false);
  const [sliderRef3, instanceRef3] = useKeenSlider(
    {
      loop: false,
      initial: 0,
      slideChanged(slider) {
        setCurrentSlide3(slider.track.details.rel);
      },
      created() {
        setLoaded3(true);
      },
      breakpoints: {
        "(min-width: 0px)": {
          slides: { perView: "1", spacing: 6 },
        },
        "(min-width: 400px)": {
          slides: { perView: "1", spacing: 6 },
        },
        "(min-width: 820px)": {
          slides: { perView: "2", spacing: 6 },
        },
        "(min-width: 1000px)": {
          slides: { perView: "2", spacing: 6 },
        },
      },
      slides: { perView: "2", spacing: 6 },
    },
    [
      (slider) => {
        let timeout;
        let mouseOver = false;
        function clearNextTimeout() {
          clearTimeout(timeout);
        }
        function nextTimeout() {
          clearTimeout(timeout);
          if (mouseOver) return;
          timeout = setTimeout(() => {
            // slider.next();
          }, 6000);
        }
        slider.on("created", () => {
          slider.container.addEventListener("mouseover", () => {
            mouseOver = true;
            clearNextTimeout();
          });
          slider.container.addEventListener("mouseout", () => {
            mouseOver = false;
            nextTimeout();
          });
          nextTimeout();
        });
        const observer = new ResizeObserver(function () {
          slider.update();
        });

        slider.on("created", () => {
          observer.observe(slider.container);
        });
        slider.on("destroyed", () => {
          observer.unobserve(slider.container);
        });
        slider.on("dragStarted", clearNextTimeout);
        slider.on("animationEnded", nextTimeout);
        slider.on("updated", nextTimeout);
      },
    ]
  );

  // console.log(addons);

  return (
    <>
      {reviews.reviews && reviews.reviews.length > 0 ? (
        <Stack
          sx={{
            ...SlideStack,
          }}
        >
          {/* <Stack
            sx={{
              // bgcolor: "primary.main",
              bgcolor: "common.compbg",
              // borderRadius: "20px 20px 20px 20px",
              // mx: 2,
              mx: 0,
              px: 0,
              justifyContent: "center",
              alignItems: "center",
              boxShadow: 0,
              border: 1,
            }}
          > */}
          <Stack
            ref={sliderRef3}
            className="keen-slider"
            direction="row"
            sx={{
              // bgcolor: "common.compbg",
              // justifyContent: "center",
              alignItems: "center",
              // mx: 0,
              // px: 0,
              // border: 1,

              // borderRadius: "20px 20px 20px 20px",
            }}
          >
            {reviews.reviews.map((rev, j) => (
              // <Stack
              //   key={j}
              //   // className="keen-slider__slide"
              //   sx={{
              //     // ...KeenSliderSlide3,
              //     // borderRadius: "20px 20px 20px 20px",
              //     // boxShadow: 3,
              //     // bgcolor: "common.compbg",
              //     m: 0,
              //     p: 0,

              //     // border: 1,
              //   }}
              // >
              <Card
                variant="outlined"
                id="#addon"
                elevation={0}
                key={j}
                className="keen-slider__slide"
                sx={{
                  ...KeenSliderSlide3,
                }}
              >
                {/* <Stack
                  key={j}
                  direction="column"
                  className="keen-slider__slide"
                  sx={{
                    ml: 0,
                    mr: 0,
                    my: 0.5,
                    p: 3,
                    justifyContent: "center",
                    alignItems: "center",
                    // bgcolor: "common.compbg",
                    borderRadius: "20px 20px 20px 20px",
                    // border: 1,
                    // border: "solid",
                    // borderWidth: "2px",
                    // bgcolor: "common.compbg",
                    boxShadow: 3,
                  }}
                > */}
                {/* <FaceIcon
                        fontSize="large"
                        sx={{
                          m: 0,
                          p: 0,

                          // border: 1,
                          // fontSize: "xl",
                          // color: "secondary.main",
                        }}
                      /> */}
                <Box
                  // key={j}
                  sx={{
                    typography: "h5",
                    fontWeight: "bold",
                    color: "textSecondary",
                    textAlign: "center",
                  }}
                >
                  "{rev.review}"
                </Box>
                <Box
                  sx={{
                    color: "textPrimary",
                    typography: "h6",
                    fontWeight: "medium",
                  }}
                >
                  {rev.reviewer}
                </Box>
                <Box
                  sx={{
                    color: "textPrimary",
                    typography: "subtitle1",
                    fontWeight: "medium",
                  }}
                >
                  {rev.reviewercompany}
                </Box>
                {/* </Stack> */}
              </Card>
              // </Stack>
            ))}
          </Stack>

          {loaded3 && instanceRef3.current && (
            <>
              <KeenSliderArrows
                currentSlide={currentSlide3}
                sliderInstance={instanceRef3}
              />
            </>
          )}
          {/* </Stack> */}

          {loaded3 && instanceRef3.current && (
            <Box className="dots" sx={{ bgcolor: "common.compbg" }}>
              {[
                ...Array(
                  instanceRef3.current.track.details.slides.length
                ).keys(),
              ].map((idx) => {
                return (
                  <button
                    key={idx}
                    onClick={() => instanceRef3.current?.moveToIdx(idx)}
                    sx={{ color: "secondary" }}
                    className={
                      "dot" +
                      (currentSlide3 === idx ? " active" : "") +
                      (ColorMode === "dark" ? " dark" : " light")
                    }
                  ></button>
                );
              })}
            </Box>
          )}
        </Stack>
      ) : (
        <>
          <CircularProgress size={indSize} color="secondary" />
        </>
      )}
    </>
  );
}
export default memo(Reviews);
