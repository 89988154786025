import React, { useEffect, useState } from "react";
// import classNames from "classnames";

import { useCartStore } from "../store/zuCartStore";
import { setAccepted } from "../store/zuCartActions";
import Checkbox from "@mui/material/Checkbox";
import { FormControlLabel } from "@mui/material";

import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";

import VirtuosoList from "./VirtuosoList";

function TermsConditions(props) {
  const accepted = useCartStore((state) => state.accepted);
  const [checkbox, setCheckbox] = useState(false);

  useEffect(() => {
    setAccepted(0);
  }, []);

  function handlecheck() {
    setCheckbox(!checkbox);
    setAccepted(!accepted);
  }

  return (
    <>
      <Stack
        direction="column"
        sx={{
          bgcolor: "common.compbg",
          alignItems: "center",
          width: "100%",
          pb: 2,
          pt: 0,
          pl: 0,
        }}
      >
        <Stack
          direction="column"
          sx={{
            alignItems: "flex-start",
            pb: 2,
          }}
        >
          <VirtuosoList />
        </Stack>

        <FormControlLabel
          sx={{
            fontWeight: "bold",
            border: 3,
            borderColor: "white",
            borderRadius: "10px 10px 10px 10px",
            p: 1.5,
          }}
          label={
            <Box
              sx={{
                typography: "h5",
                fontWeight: "medium",
                color: "textSecondary",
              }}
            >
              I have read and accept these terms and conditions
            </Box>
          }
          control={
            <Checkbox
              color="secondary"
              sx={{
                color: "textSecondary",
              }}
              onChange={() => handlecheck()}
            />
          }
        />
      </Stack>
    </>
  );
}

TermsConditions.propTypes = {};

export default TermsConditions;
