import React from "react";
import PropTypes from "prop-types";
import { Icon } from "../../home/OverviewIcon";
import {
  Box,
  Typography,
  useTheme,
  styled,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Divider,
  Tooltip,
} from "@mui/material";
import { useCustStore } from "../../store/zuCustStore";
import StyledButtonWIcon from "../../shared/StyledButtonWIcon";
import { useNavigate } from "react-router-dom";
function DashboardToolbar(props) {
  // const { children, size, selected, sx, iconName, ...otherProps } = props;
  const navigate = useNavigate();
  return (
    <Stack
      useFlexGap
      direction="row"
      // direction={{ xs: "column", sm: "row" }}
      sx={{
        flexWrap: "wrap",
        aligntItems: "center",
        width: { xs: "100%", sm: "100%" },
        // justifyContent: { xs: "center", sm: "space-between" },
        justifyContent: "space-between",
        m: 0,
        p: 0,
      }}
    >
      <Stack
        // direction="row"
        sx={{
          mx: 0.5,
          my: 0,
          justifyContent: "center",
          aligntItems: "center",
        }}
      >
        <Box
          sx={{
            typography: "subtitle1",
            color: "textSecondary",
            fontWeight: "medium",
            textTransform: "capitalize",
            // justifyContent: "center",
            // aligntItems: "center",
            mx: 0,
          }}
        >
          {useCustStore
            .getState()
            .valactions.filter(
              (action) =>
                action.entity_name === "cust_select" && action.action_type === 7
            ).length > 0 ? (
            <StyledButtonWIcon
              iconName="FolderCopyOutlinedIcon"
              // key={`${index}-${index}`}
              onClick={() => {
                navigate(
                  useCustStore
                    .getState()
                    .valactions.filter((action) => action.action_name === "S")
                    .length > 0
                    ? `/dashboard/ui_list_entity/cust_select`
                    : `/dashboard/ui_list_entity/cust_profile/U/${
                        useCustStore.getState().currentUser.cust_id
                      }`
                );
              }}
              sx={{
                border: 0,
                textTransform: "capitalize",
                // fontSize: "medium",
                // ":hover": {
                //   boxShadow: 0,
                //   bgcolor: "",
                // },
                // , color: "secondary.main"
              }}
              variant="text"
            >
              {useCustStore.getState().currentUser.cust_name}
            </StyledButtonWIcon>
          ) : (
            <>{useCustStore.getState().currentUser.cust_name}</>
          )}
        </Box>
      </Stack>
      <Stack
        direction="row"
        useFlexGap
        sx={{
          // display: "flex",
          flexWrap: "wrap",
          mx: 0.5,
          my: 0,
          // border: 1,
          justifyContent: "center",
          aligntItems: "center",
        }}
      >
        <Tooltip
          title={`You are logged in as a(n) ${
            useCustStore.getState().currentUser.role_name
          } in the ${useCustStore.getState().currentUser.grp_name} group.`}
        >
          <Box
            sx={{
              // display: "flex",
              typography: "subtitle1",
              color: "textSecondary",
              fontWeight: "medium",
              m: 0,
              p: 0,
              mr: 1,
              // aligntItems: "center",
              alignContent: "center",
              // justifyContent: "center",
              // border: 1,
            }}
          >
            {useCustStore.getState().currentUser.role_name}
          </Box>
        </Tooltip>

        <Divider orientation="vertical" />

        <StyledButtonWIcon
          iconName="Groups2Outlined"
          // key={`${index}-${index}`}
          onClick={() => {
            navigate(`/dashboard/ui_list_entity/group_select`);
          }}
          sx={{
            // border: 0,
            textTransform: "capitalize",
          }}
          variant="text"
        >
          {useCustStore.getState().currentUser.grp_name}
        </StyledButtonWIcon>
      </Stack>
    </Stack>
  );
}

// StyledButton.propTypes = {
//   onClick: PropTypes.func.isRequired,
// };

// export default StyledButton;

// const StyledButton = styled(
//   "Button"
//   // , { shouldForwardProp: (props) => props}
// )(({ theme }) => ({
//   variant: "contained",
//   color: "textAlt",
//   disableElevation: true,
// }));
export default DashboardToolbar;
