import * as yup from "yup";
import { validationStore } from "../store/validationStore";
export function createYupSchema(schema, fieldArr) {
  //   let schema = [];

  const { colname, validationType, validations, yupVType } = fieldArr.ui_data;
  var nvalidations = [];

  // console.log("fieldArr:", fieldArr);
  // if (validations && validations.length == 0) {
  //   console.log("validationStore:", validationStore);
  //   nvalidations = validationStore[yupVType];
  // } else if (validations) {
  //   nvalidations = validations;
  // }
  if (validations === undefined) {
    // console.log("validationStore:", validationStore);
    nvalidations = validationStore[yupVType];
  } else if (validations.length > 0) {
    nvalidations = validations;
  }

  // console.log("createYupSchema:colname:", colname);
  // console.log("validations:", validations);

  // console.log("yupVType:", yupVType, ", validationType:", validationType);
  // console.log(
  //   "createYupSchema:colname:",
  //   colname,
  //   ", nvalidations:",
  //   nvalidations
  // );

  if (!yup[validationType]) {
    return schema;
  }
  let validator = yup[validationType]();
  nvalidations.forEach((validation) => {
    const { params, type } = validation;
    if (!validator[type]) {
      return;
    }
    validator = validator[type](...params);
  });
  schema[colname] = validator;
  // console.log(" jsFunctions:createYupSchema:genschema", schema);
  return schema;
}
