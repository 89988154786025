import React from "react";
import { useState, Suspense } from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";

// import { Link } from "react-router-dom";
import Link from "@mui/material/Link";
import {
  NavLink as RouterLink,
  Outlet,
  useLoaderData,
  useRouteLoaderData,
  useNavigate,
} from "react-router-dom";
import PropTypes from "prop-types";
import LogoBr from "./shared/LogoBR";
import Badge from "@mui/material/Badge";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
// import LoginIcon from "@mui/icons-material/Login";
// import LogoutIcon from "@mui/icons-material/Logout";

import {
  LightModeOutlined,
  DarkModeOutlined,
  // Menu as MenuIcon,
  // Search,
  // SettingsOutlined,
  // ArrowDropDownOutlined,
} from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
// import HomeIcon from "@mui/icons-material/Home";

import LockOpenIcon from "@mui/icons-material/LockOpen";
import StyledButton from "./shared/StyledButton";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";

import NavigationDrawer from "./shared/NavigationDrawer";

import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";

import useScrollTrigger from "@mui/material/useScrollTrigger";
import Fab from "@mui/material/Fab";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import Fade from "@mui/material/Fade";

// import { useOverviewStore } from "./store/zustandOverviewStore";
import { callUICrud } from "./store/crudActions";
import myAxios from "./MyAxios";
import useTheme from "@mui/material/styles/useTheme";

import { useCustStore } from "./store/zuCustStore";
import { useCartStore } from "./store/zuCartStore";
import { useTokenStore } from "./store/zuTokenStore";
import CssBaseline from "@mui/material/CssBaseline";
// import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import useMediaQuery from "@mui/material/useMediaQuery";
import { refreshCart } from "./store/zuCartActions";
// import { setValidatedUser } from "./store/zuCustActions";

function ScrollTop(props) {
  const { children } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      "#back-to-top-anchor"
    );

    if (anchor) {
      anchor.scrollIntoView({
        // behavior: "smooth",
        block: "center",
      });
    }
  };

  // const handleCSCollapseClick = (event) => {
  //   const anchor = (event.target.ownerDocument || document).querySelector(
  //     "#cs-top"
  //   );

  //   if (anchor) {
  //     anchor.scrollIntoView({
  //       // behavior: "smooth",
  //       block: "center",
  //     });
  //   }
  // };

  return (
    <Fade in={trigger}>
      <Stack
        onClick={handleClick}
        role="presentation"
        sx={{
          position: "fixed",
          bottom: (theme) => theme.spacing(2),
          // left: (theme) => theme.spacing(2),
          // bottom: 12,
          // right: 2,
          ml: 2,
          // justifyContent: "center",
          // alignItems: "center",
        }}
      >
        {children}
      </Stack>
    </Fade>
  );
}

ScrollTop.propTypes = {
  children: PropTypes.element.isRequired,
};

export function NavBar(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const navigate = useNavigate();
  const nopen = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [alignment, setAlignment] = React.useState("left");

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const [selectedBtn, setSelectedBtn] = React.useState(0);
  const [selectedBtn2, setSelectedBtn2] = React.useState(0);
  const menuIconSx = {
    m: 0,
    p: 0,
    color: "secondary.main",
    fontSize: "30px",
  };

  const menuButtonSx = {
    mx: { xs: 0.1, sm: 0.1 },
    my: 0,
    px: { xs: 1, sm: 2 },
    py: 0.1,
    minWidth: "100px",
    // minHeight: "30px",
    fontSize: {
      xs: "h7.fontSize",
      sm: "h6.fontSize",
    },
    fontWeight: "bold",
    textTransform: "none",

    // color: "primary.main",
    color: "textAlt",
    bgcolor: "secondary.main",
    border: 0.2,
    borderRadius: "5px 5px 5px 5px",
    borderColor: "secondary.main",
    ":hover": {
      bgcolor: "common.secondary5",
      borderColor: "secondary.main",
      border: 0.2,
    },
    "&.active": {
      bgcolor: "common.compbg",
      color: "textSecondary",
      border: 0.2,
    },
    "&:clicked": {
      bgcolor: "common.compbg",
      color: "textSecondary",
      border: 0.2,
    },
    ":disabled": {
      boxShadow: 0,
      bgcolor: "common.commbg",
      border: 0.2,
      borderColor: "secondary.main",
      color: "textAlt",
    },
  };
  const validatedUser = useCustStore(
    useCallback((state) => state.validatedUser)
  );
  const toggleColorMode = useTokenStore(
    useCallback((state) => state.toggleColorMode)
  );
  const cart = useCartStore((state) => state.cart);
  // const { topbanner, data2, data3 } = useLoaderData();
  // const { topbanner } = useLoaderData();
  const { topbanner } = useRouteLoaderData("root");

  // console.log("Navbar::loaderdata", topbanner);
  const theme = useTheme();

  // console.log("cart in navbar first", cart);
  // const validatedUser = useCustStore((state) => state.validatedUser);

  const findTotal = () => {
    var cart_total = null;
    cart && cart.map((line) => (cart_total = cart_total + line.quantity));
    return cart_total;
    // if (cart.length > 0) {
    //   return (
    //     cart[0].quantity +
    //     cart[1].quantity +
    //     cart[2].quantity +
    //     cart[3].quantity
    //   );
    // } else return null;
  };

  const {
    // openLoginDialog,
    // handleMobileDrawerOpen,
    // handleMobileDrawerClose,
    mobileDrawerOpen,
    selectedTab,
  } = props;
  // const isMobile = useMediaQuery("(Max-width: 600px)");
  const [isMobile, setIsMobile] = useState(useMediaQuery("(Max-width: 600px)"));
  const [isMobileDrawerOpen, setIsMobileDrawerOpen] = useState(false);
  // const [drawerPosition, setDrawerPosition] = useState(0);
  // const [clickAwayPos, setClickAwayPos] = useState(0);
  const [drawerPosition, setDrawerPosition] = useState({
    Pos: 0,
    clickAway: 0,
  });
  // const [drawerPosition, setDrawerPosition] = useState(isMobile ? 1 : 2);

  const handleMobileDrawerOpen = useCallback(() => {
    setIsMobileDrawerOpen(true);
  }, [setIsMobileDrawerOpen]);

  const handleMobileDrawerClose = useCallback(() => {
    setIsMobileDrawerOpen(false);
    setDrawerPosition((prevState) => ({ ...prevState, Pos: 0 }));
  }, [setIsMobileDrawerOpen]);
  // console.log("useCustStore-1", useCustStore.getState());

  // console.log(currentUser);
  const menuItems = [
    {
      route: "/",
      route_name: "Home",
      color: "darkBlack",
      // icon: <HomeIcon />,
      icon: "HomeOutlined",
      underline: "none",
      showLoggedIn: 1,
      showLoggedOut: 1,
    },
    // {
    //   route: "/rec",
    //   name: "RECRUITING",
    //   color: "darkBlack",
    //   icon: <HomeIcon className="primary" />,
    // },
    {
      route: "/login",
      route_name: "Login",
      color: "darkBlack",
      // icon: <LoginIcon />,
      icon: "LoginIcon",
      underline: "none",
      showLoggedIn: 0,
      showLoggedOut: 1,
    },
    {
      route: "/logout",
      route_name: "LogOut",
      // onClick: custReset(),
      color: "darkBlack",
      // icon: <LogoutIcon />,
      icon: "LogoutIcon",
      underline: "none",
      showLoggedIn: 1,
      showLoggedOut: 0,
    },
    // {
    //   name: "Login",
    //   color: "darkBlack",
    //   onClick: /,
    //   icon: <LockOpenIcon className="text-white" />,
    // },
  ];
  const tabs = [
    {
      route: "/overview",
      name: "Overview",
      color: "darkBlack",
      badge: "n",

      // icon: <HomeIcon className="primary" />,
      underline: "none",
    },

    {
      route: "/howitworks",
      name: "How It Works",
      color: "darkBlack",
      badge: "n",
      // icon: <HomeIcon className="primary" />,
      underline: "none",
    },
    {
      route: "/signup",
      name: "Sign Up",
      color: "darkBlack",
      badge: "y",
      // icon: <HomeIcon className="primary" />,
      underline: "none",
    },
  ];
  const [alertopen, setAlertopen] = React.useState(false);

  // const [topbanner, setTopbanner] = useState([]);
  // const updateFAQ = useOverviewStore((state) => state.updFaqs);

  // setTopbanner(topbanner[0]);

  // const updateFAQS = useOverviewStore(
  //   useCallback((state) => state.updFaqs, [])
  // );

  // const updateslides = useOverviewStore(
  //   useCallback((state) => state.updSlides, [])
  // );
  // const updatetiles = useOverviewStore(
  //   useCallback((state) => state.updTiles, [])
  // );
  // const toggleColorMode = useTokenStore(
  //   useCallback((state) => state.toggleColorMode)
  // );
  // const faqs = useOverviewStore((state) => state.faqs);
  // const tiles = useOverviewStore((state) => state.tiles);
  // const slides = useOverviewStore((state) => state.slides);

  // const validatedUser = useCustStore((state) => state.validatedUser);
  // const CurrentUser = useCustStore((state) => state.currentUser);

  // console.log(tiles);
  // console.log(slides);
  // console.log(faqs);
  // console.log("useCustStore", useCustStore.getState());
  // console.log("CurrentUser in NavBar", CurrentUser);

  const custReset = useCustStore((state) => state.reset);
  // const cartReset = useCartStore((state) => state.reset);
  useEffect(() => {
    console.log("Navbar::useEffect:custReset");
    console.log("Navbar::useEffect:validatedUser:", validatedUser);
    console.log("Navbar::useEffect:cart:", cart);
    // console.log("Navbar::useEffect:ipjson:", useCartStore.getState().ipjson);
    custReset();
    if (useCustStore.getState().validatedUser && cart.length < 1) {
      console.log("Navbar::useEffect:ipjson:", useCartStore.getState().ipjson);
      refreshCart(useCartStore.getState().ipjson);
    }
  }, []);

  useEffect(() => {
    if (topbanner && topbanner.length > 0) {
      setAlertopen(true);
    }
  }, []);
  useEffect(() => {
    window.onresize = () => {
      // console.log("NavigationDrawer.useEffect.isMobile:", isMobile);
      // console.log("NavigationDrawer.useEffect.drawerPosition:", drawerPosition);
      if (
        !isMobile
        // && drawerPosition !== 0
      ) {
        // onClose();
        // setDrawerPosition(0);
        setDrawerPosition((prevState) => ({ ...prevState, Pos: 0 }));
        // console.log("Navbar.useEffect2.isMobile:", isMobile);
        // console.log("Navbar.useEffect2.drawerPosition:", drawerPosition);
      }
    };
  }, [isMobile]);

  const filterMenuItems = menuItems.filter(
    (item) =>
      (validatedUser && item.showLoggedIn === 1) ||
      (!validatedUser && item.showLoggedOut === 1)
  );
  console.log("Navbar:validatedUser:", validatedUser);
  console.log("Navbar:filterMenuItems:", filterMenuItems);
  // console.log("Navbar:drawerPosition:", drawerPosition);
  // console.log("Navbar:clickAwayPos:", clickAwayPos);
  // console.log("Navbar:useCustStore:", useCustStore.getState());
  // console.log("Navbar:useCartStore:", useCartStore.getState());
  return (
    <>
      {/* <Suspense fallback={<CircularProgress size={25} color="secondary" />}> */}
      <Stack
        direction="column"
        // sx={{bgcolor: "primary.main"}}
      >
        <CssBaseline />
        {/* <ClickAwayListener
            mouseEvent="onMouseDown"
            touchEvent="onTouchStart"
            disableReactTree
            // open={drawerPosition !== 0}
            onClickAway={() => {
              // alert("Clickaway:" + drawerPosition);
              console.log("onClickAway:drawerPosition:", drawerPosition);

              if (isMobile) {
                setDrawerPosition((prevState) => ({
                  ...prevState,
                  Pos: prevState.Pos === 2 ? 0 : prevState.Pos,
                  clickAway: 0,
                }));
                // if (
                //   drawerPosition.clickAway === 0 &&
                //   drawerPosition.Pos === 2
                // ) {
                //   // setClickAwayPos(1);
                //   // setDrawerPosition(0);
                //   setDrawerPosition((prevState) => ({
                //     ...prevState,
                //     Pos: 0,
                //     clickAway: 1,
                //   }));
                // } else if (drawerPosition.clickAway === 2) {
                //   // setClickAwayPos(0);
                //   setDrawerPosition((prevState) => ({
                //     ...prevState,
                //     Pos: 0,
                //     // clickAway: 2,
                //   }));
                // }
              }

              console.log("onClickAway:drawerPosition2:", drawerPosition);
            }}
          > */}
        <AppBar
          // id="back-to-top-anchor"
          position="sticky"
          // position="relative"
          elevation={0}
          // color="secondary"
          sx={{
            my: 0,
            mx: 0,
            py: 0,
            px: 0,
            // mb: 2,
            // backgroundColor:"primary.main"
            backgroundColor: "background.default",
            // justifyContent: "center",
          }}
        >
          {
            topbanner && topbanner.length > 0 && (
              <>
                <Collapse in={alertopen} unmountOnExit>
                  <Alert
                    severity="info"
                    sx={{
                      my: "0",
                      py: "0",
                      bgcolor: "common.secondary2",
                    }}
                    action={
                      <IconButton
                        aria-label="close"
                        onClick={() => {
                          setAlertopen(false);
                        }}
                      >
                        <CloseIcon sx={{ ...menuIconSx }} />
                      </IconButton>
                    }
                  >
                    <>
                      {topbanner.map((banner, j) => (
                        <Typography
                          key={j}
                          variant="h6"
                          color="textSecondary"
                          component="p"
                        >
                          {banner.msg}
                        </Typography>
                      ))}
                    </>
                  </Alert>
                </Collapse>
                <Divider />
              </>
            )
            // : (
            //   <></>
            // )
          }
          {/* Main Menu Bar */}
          <Toolbar
            // id="back-to-top-anchor"
            disableGutters
            sx={{
              // mx: 1,
              my: 0,
              py: 0,
              // bgcolor: "common.compbg",
              // bgcolor: "primary.main",
            }}
          >
            {/* <img src="pictures/breazylogo.jpg" /> */}
            <LogoBr />
            <Stack
              direction="row"
              sx={{
                alignItems: "center",
                justifyContent: "center",
              }}
              // id="back-to-top-anchor"
            >
              <Box>
                {validatedUser && (
                  <>
                    <Button
                      component={RouterLink}
                      to="/dashboard"
                      id="profile-button"
                      aria-controls={nopen ? "profile-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={nopen ? "true" : undefined}
                      // onClick={handleClick}
                      endIcon={<KeyboardArrowDownIcon />}
                      // onMouseOver={handleClick}
                    >
                      <AccountCircleIcon sx={{ ...menuIconSx }} />
                    </Button>

                    <Menu
                      id="profile-menu"
                      anchor="right"
                      MenuListProps={
                        ({
                          "aria-labelledby": "profile-button",
                        },
                        { onMouseLeave: handleClose })
                      }
                      anchorEl={anchorEl}
                      open={nopen}
                      onClose={handleClose}
                      TransitionComponent={Fade}
                      // MenuListProps={{ onMouseLeave: handleClose }}
                    >
                      <MenuItem divider onClick={handleClose}>
                        Profile
                      </MenuItem>
                      <MenuItem divider onClick={handleClose}>
                        My account
                      </MenuItem>
                      <MenuItem
                        component={RouterLink}
                        underline="none"
                        // key={element.name}
                        to="/logout"
                        onClick={handleClose}
                      >
                        Logout
                      </MenuItem>
                    </Menu>
                  </>
                )}
              </Box>
              <Box>
                <IconButton onClick={() => toggleColorMode()}>
                  {theme.palette.mode === "dark" ? (
                    <LightModeOutlined sx={{ ...menuIconSx }} />
                  ) : (
                    <DarkModeOutlined sx={{ ...menuIconSx }} />
                  )}
                </IconButton>
              </Box>
              <Box sx={{ display: { xs: "block", sm: "none" } }}>
                <IconButton
                  // onClick={handleMobileDrawerOpen}
                  onClick={() => {
                    // alert("Navbar:IconButton:" + drawerPosition);
                    // console.log("Navbar:drawerPosition:" + drawerPosition);
                    // console.log("Navbar:clickAwayPos:" + clickAwayPos);
                    setDrawerPosition((prevState) => ({
                      ...prevState,
                      Pos: prevState.Pos === 0 ? 2 : 0,
                      clickAway: 0,
                    }));
                    // if (drawerPosition.clickAway === 1) {
                    //   setDrawerPosition((prevState) => ({
                    //     ...prevState,
                    //     clickAway: 2,
                    //   }));
                    //   // setClickAwayPos(2);
                    //   // } else if (clickAwayPos === 2) {
                    //   //   // setDrawerPosition(drawerPosition === 2 ? 0 : 2);
                    //   //   setClickAwayPos(0);
                    // } else if (drawerPosition.clickAway === 0) {
                    //   // setDrawerPosition(drawerPosition === 2 ? 0 : 2);
                    //   setDrawerPosition((prevState) => ({
                    //     ...prevState,
                    //     Pos: 2,
                    //     clickAway: 0,
                    //   }));
                    //   // setDrawerPosition(2);
                    //   // setClickAwayPos(0);
                    // }
                    // else if (clickAwayPos === 2) {
                    //   setDrawerPosition(drawerPosition === 0 ? 2 : 0);
                    //   setClickAwayPos(0);
                    // }
                    // setDrawerPosition(
                    //   clickAwayPos === 2 ? 0 : drawerPosition === 0 ? 2 : 0
                    // );
                    // setClickAwayPos(clickAwayPos === 2 ? 0 : clickAwayPos);
                    // alert("Navbar:IconButton2:" + drawerPosition);
                    // console.log("Navbar:drawerPosition2:" + drawerPosition);
                    // console.log("Navbar:clickAwayPos2:" + clickAwayPos);
                  }} // open full}
                  aria-label="Open Navigation"
                  size="large"
                >
                  <MenuIcon sx={{ ...menuIconSx }} />
                </IconButton>
              </Box>

              <Stack
                direction="row"
                sx={{ alignItems: "center", justifyContent: "center" }}
              >
                <Box sx={{ display: { xs: "none", sm: "block" } }}>
                  <>
                    {filterMenuItems.map((element, i) => {
                      if (
                        (validatedUser && element.showLoggedIn === 1) ||
                        (!validatedUser && element.showLoggedOut === 1)
                      ) {
                        // console.log("i:", i);
                        if (element.route) {
                          return (
                            <StyledButton
                              component={RouterLink}
                              key={element.route_name}
                              to={element.route}
                              sx={{ minWidth: "100px" }}
                              onClick={() => {
                                setSelectedBtn(i);
                                // handleMobileDrawerClose;
                                // setDrawerPosition(0);
                                setDrawerPosition((prevState) => ({
                                  ...prevState,
                                  Pos: 0,
                                }));
                              }}
                              selected={selectedBtn === i}
                            >
                              {element.route_name}
                            </StyledButton>
                          );
                        }
                        return (
                          <StyledButton
                            onClick={element.onClick}
                            sx={{ minWidth: "100px" }}
                            key={element.route_name}
                          >
                            {element.route_name}
                          </StyledButton>
                        );
                      }
                    })}
                  </>
                </Box>
                {/* <Box>
                    <StyledButton
                      id="fade-button"
                      aria-controls={nopen ? "fade-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={nopen ? "true" : undefined}
                      onClick={handleClick}
                      endIcon={<KeyboardArrowDownIcon />}
                      // onMouseEnter={handleClick}
                      onMouseOver={handleClick}
                      sx={{ minWidth: "100px" }}
                    >
                      TEST
                    </StyledButton>
                    <Menu
                      id="fade-menu"
                      anchor="right"
                      MenuListProps={
                        ({
                          "aria-labelledby": "fade-button",
                        },
                        { onMouseLeave: handleClose })
                      }
                      anchorEl={anchorEl}
                      open={nopen}
                      onClose={handleClose}
                      TransitionComponent={Fade}
                      // MenuListProps={{ onMouseLeave: handleClose }}
                    >
                      <MenuItem divider onClick={handleClose}>
                        Profile
                      </MenuItem>
                      <MenuItem divider onClick={handleClose}>
                        My account
                      </MenuItem>
                      <MenuItem
                        component={RouterLink}
                        underline="none"
                        // key={element.name}
                        to="/logout"
                        onClick={handleClose}
                      >
                        Logout
                      </MenuItem>
                    </Menu>
                  </Box> */}
              </Stack>
            </Stack>
          </Toolbar>

          {/* Tabs */}
          <Toolbar
            // id="back-to-top-anchor"
            disableGutters
            sx={{
              // mx: 1,
              my: 0,
              py: 0,
              justifyContent: "center",
            }}
          >
            {/* <img src="pictures/breazylogo.jpg" /> */}

            <Stack
              direction="row"
              sx={{ mx: 0, my: 0, justifyContent: "center" }}
            >
              {/* <Box sx={{ display: { xs: "block", sm: "none" } }}>
              <IconButton
                onClick={handleMobileDrawerOpen}
                aria-label="Open Navigation"
                size="large"
              >
                <MenuIcon color="secondary" />
              </IconButton>
            </Box> */}
              {/* <Box sx={{ display: { xs: "none", sm: "block" } }}> */}
              <Box>
                {/* {validatedUser ? (
                <>
                  <Stack
                    direction="row"
                    sx={{ alignItems: "center", justifyContent: "center" }}
                  >
                    <IconButton component={RouterLink} to="/dashboard">
                      <AccountCircleIcon fontSize="large" />
                    </IconButton>

                    <Box sx={{ typography: "h7", fontWeight: "bold", px: 3 }}>
                      {currentUser.login}
                    </Box>

                    <Link
                      component={RouterLink}
                      underline="none"
                      // key={element.name}
                      to="/"
                      sx={{
                        // textDecoration: "none !important",
                        fontWeight: "bold",
                        pr: 3,
                      }}
                      onClick={() => {
                        useCustStore.set({ validatedUser: null });
                      }} // underline={element.underline}
                      variant="h5"
                      color="secondary.main"
                    >
                      Log Out
                    </Link>
                  </Stack>
                </>
              ) : ( */}
                <>
                  {tabs.map((element, m) => {
                    if (element.route) {
                      return (
                        <Badge
                          key={m}
                          invisible={element.badge === "n" || findTotal() < 0}
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                          color="primary"
                          badgeContent={findTotal()}
                          max={10000}
                          overlap="circular"
                          sx={{
                            "& .MuiBadge-badge": {
                              color: "secondary.main",
                              fontWeight: "bold",
                              borderColor: "secondary.main",
                              border: 1,
                            },
                          }}
                        >
                          <Link
                            // component={RouterLink}
                            underline="none"
                            // key={element.name}
                            key={m}
                            // to={element.route}
                            variant="h6"
                            sx={{
                              mx: { xs: 0.5, sm: 2 },
                              my: 0,
                              px: { xs: 1, sm: 3 },
                              py: { xs: 0.5, sm: 0.2 },
                              // fontSize: { xs: "h6", sm: "h6" },
                              fontWeight: "bold",
                              textTransform: "none",

                              color:
                                selectedBtn2 === m
                                  ? "textSecondary"
                                  : "textAlt",
                              // bgcolor: "secondary.main",
                              bgcolor:
                                selectedBtn2 === m
                                  ? "common.compbg"
                                  : "secondary.main",
                              // minHeight: "40px",
                              border: selectedBtn2 === m ? 1 : 0,
                              borderRadius: "20px 20px 20px 20px",
                              borderColor:
                                selectedBtn2 === m
                                  ? "secondary.main"
                                  : "primary.main",
                              ":hover": {
                                bgcolor: "common.secondary7",
                                border: 0,
                              },
                              "&.active": {
                                bgcolor:
                                  selectedBtn2 === m
                                    ? "common.compbg"
                                    : "secondary.main",
                                color:
                                  selectedBtn2 === m
                                    ? "textSecondary"
                                    : "textAlt",
                                border: 1,
                              },
                            }}
                            onClick={() => {
                              setSelectedBtn2(m);
                              navigate(`${element.route}`);
                            }}
                            // onMouseOver={() => {
                            //   setSelectedBtn2(m);
                            //   navigate(`${element.route}`);
                            // }}
                          >
                            {element.name}
                          </Link>
                        </Badge>
                      );
                    }
                    // return <></>;
                  })}
                </>
              </Box>
            </Stack>
          </Toolbar>
          <ScrollTop {...props}>
            <Fab color="secondary" size="small" aria-label="scroll back to top">
              <KeyboardArrowUpIcon color="primary" />
            </Fab>
          </ScrollTop>

          <NavigationDrawer
            menuItems={filterMenuItems}
            drawerPosition={drawerPosition} // closed
            setDrawerPosition={setDrawerPosition}
            selectedItem={selectedTab}
            onClose={handleMobileDrawerClose}
            // clickAwayPos={clickAwayPos}
            // setClickAwayPos={setClickAwayPos}
          />
        </AppBar>
        {/* </ClickAwayListener> */}
        <Stack id="back-to-top-anchor"></Stack>
        <Suspense
          fallback={
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress size={45} color="secondary" />
            </Box>
          }
        >
          <Outlet />
        </Suspense>
      </Stack>
      {/* </Suspense> */}
    </>
  );
}

NavBar.propTypes = {
  handleMobileDrawerOpen: PropTypes.func,
  handleMobileDrawerClose: PropTypes.func,
  mobileDrawerOpen: PropTypes.bool,
  selectedTab: PropTypes.string,
  // openRegisterDialog: PropTypes.func.isRequired,
  // openLoginDialog: PropTypes.func.isRequired,
};

// export NavBar;

// export const NavBarLoader = async () => {
//   try {
//     // console.log(
//     //   "useTokenStore.getState().token",
//     //   useTokenStore.getState().token
//     // );
//     const res = await myAxios.get(`/ui/topbanner`, {
//       // signal: controller.signal,
//     });

//     if (res.status === 200) {
//       const data = await res.data;
//       if (data && data.length > 0) {
//         // setTopbanner(data);
//         // setAlertopen(true);
//         return data;
//       } else {
//         console.log("no topbanner data");
//         // setAlertopen(false);
//       }
//     }
//   } catch (err) {
//     throw err;
//   }
// };

// export const NavBarLoader = async () => {
//   // const user_id = useCustStore(useCallback((state) => state.currentUser.id));
//   // const grp_id = useCustStore(useCallback((state) => state.currentUser.grp_id));
//   // const svc_pkg_id = useTokenStore(useCallback((state) => state.svc_pkg_id));
//   try {
//     const topbanner = await callUICrud("topbanner", "R", {
//       svc_pkg_id: useTokenStore.getState().svc_pkg_id,
//       // svc_pkg_id: svc_pkg_id,
//       user_id: useCustStore.getState().currentUser.id,
//       grp_id: useCustStore.getState().currentUser.grp_id,
//       // user_id: user_id,
//       // grp_id: grp_id,
//     });
//     return topbanner;
//   } catch (err) {
//     throw err;
//   }
// };
