import { useCartStore } from "./zuCartStore";
import { useCustStore } from "./zuCustStore";
import { callUICrud } from "./crudActions";
import myAxios from "../MyAxios";

// export const addUpdIpCookie2 = (colname, newValue) => {
//   useCartStore.setState((state) => {
//     const newCart = { ...state.cart[0], [colname]: newValue };
//     // console.log("newCart" + JSON.stringify(newCart));
//     state.cart[0] = newCart;
//   });
// };

// export const addUpdIpCookie = (colname, newValue) => {
//   const newCart = {...useCartStore.getState().cart[0], [colname]: newValue };
//   console.log("newCart in addUpdIpCookie:" + JSON.stringify (newCart));
//   // useCartStore.setState((state) => ({cart: newCart}));
//   useCartStore.setState((state) => {state.cart = newCart});
//   console.log("addUpdIpCookie state.cart:" + JSON.stringify (useCartStore.get().cart));
// };
// export const replaceCart = (ncart) => {
//   useCartStore.setState((state) => ({cart: ncart}));
//   // console.log("replaceCart:state.cart :" + JSON.stringify(state.cart));
//   // useCartStore.setState((state) => {
//   //   state.cart = ncart;
//   // });
// };
export const refreshCart = async (ncart) => {
  // console.log("ncart in refreshCart:", JSON.stringify(ncart));
  // console.log("ncart in refreshCart:", ncart);
  console.log("ncart in refreshCart:", useCartStore.getState().cart);

  const controller = new AbortController();
  try {
    // const res = await myAxios.post(`/ui/updcart`, ncart, {
    // const res = await myAxios.post(
    //   `/ui/updcart`,
    //   useCartStore.getState().cart.length > 0
    //     ? useCartStore.getState().cart
    //     : ncart,
    //   {
    //     signal: controller.signal,
    //   }
    // );
    const data = await callUICrud(
      "cart_upd",
      "U",
      ncart?.length > 0 ? ncart : useCartStore.getState().cart
      // useCartStore.getState().cart.length > 0
      //   ? useCartStore.getState().cart
      //   : ncart
    );

    console.log("refreshCart::data", data);

    if (data?.length > 0) {
      // const data = await res.data;
      console.log("refreshCart::data,status=200:", data);
      if (data && data?.length > 0) {
        useCartStore.setState((state) => ({ cart: data }));
        useCustStore.setState((state) => ({
          currentUser: { ...state.currentUser, cust_id: data[0].cust_id },
        }));
        useCustStore.setState((state) => ({
          cust: { ...state.cust, cust_id: data[0].cust_id },
        }));
      }
      // console.log("refreshed useCustStore:", useCustStore.getState());
      console.log("refreshed useCartStore:", useCartStore.getState());
    }
  } catch (err) {
    console.log("refreshCart::err:", err);
    throw err;
  }
  controller.abort();
  // console.log(
  //   "refreshCart::Cart at End:" + JSON.stringify(useCartStore.getState().cart)
  // );
};

export const setAccepted = (bool) => {
  useCartStore.setState((state) => ({ accepted: bool }));
};

export const addUpdCartItem = async (item_id, newValue, refresh) => {
  // console.log("addUpdCartItem:colname:" + item_id + ", newValue:" + newValue);
  // console.log(useCartStore.getState().cart);
  let USDollar = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  const ncart = useCartStore.getState().cart.map((orderrow) => {
    // console.log("orderrow", orderrow);
    if (orderrow.item_id === item_id) {
      console.log(
        "orderrow.item_id:" +
          orderrow.item_id +
          ", orderrow.quantity:" +
          orderrow.quantity +
          ", newValue:" +
          newValue
      );
      return {
        ...orderrow,
        quantity: newValue,
        line_total: newValue * orderrow.price,
        line_total_curr: `${USDollar.format(newValue * orderrow.price)}`,
      };
    } else {
      return orderrow;
    }
  });
  useCartStore.setState((state) => ({ cart: ncart }));

  // var ncart = useCartStore
  //   .getState()
  //   .cart.filter((orderrow) => orderrow.item_id === item_id);
  // console.log("ncart filtered in addupdCartItem:", ncart);
  // ncart[0] = { ...ncart[0], quantity: newValue };
  // console.log(
  //   "orderrow.item_id:" +
  //     ncart[0].item_id +
  //     ", orderrow.quantity:" +
  //     ncart[0].quantity +
  //     ", newValue:" +
  //     newValue
  // );
  console.log("ncart in addupdCartItem:", ncart);
  // console.log("new state val", useCartStore.getState().cart);

  // await refreshCart(ncart);
  // await refreshCart(JSON.stringify(ncart));
  console.log(
    "refreshed cart in addUpdCartItem:" + useCartStore.getState().cart
  );

  console.log(
    "refreshed cart in addUpdCartItem:" +
      JSON.stringify(useCartStore.getState().cart)
  );

  // useCartStore.setState(
  //   (state) => {
  //     const ncart = state.cart.map((orderrow) => {
  //       if (orderrow.item_id === item_id) {
  //         // console.log(
  //         //   "orderrow.item_id:" +
  //         //     orderrow.item_id +
  //         //     ", orderrow.quantity:" +
  //         //     orderrow.quantity
  //         // );
  //         return { ...orderrow, quantity: newValue };
  //       } else {
  //         return orderrow;
  //       }
  //     });
  //     // console.log("ncart in addupdCartItem:" + JSON.stringify(ncart));
  //     refreshCart(ncart);
  //     // console.log(
  //     // "state.cart in addupdCartItem:" + JSON.stringify(state.cart)
  //     // );
  //     // set({ cart: ncart });
  //     // state.cart = ncart;
  //   }
  //   // console.log("state.cart in addupdCartItem:" + JSON.stringify(cart));
  // );
};
