import { Virtuoso } from "react-virtuoso";
import { useWindowWidth, useWindowHeight } from "@react-hook/window-size";
import { useTheme } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";

import terms from "./tncdata.json";

export default function VirtuosoList() {
  console.log("inside vlist");
  const theme = useTheme();
  const height = useWindowHeight();
  const width = useWindowWidth();

  return (
    <>
      <Virtuoso
        style={{
          width: 0.85 * Math.min(width, theme.breakpoints.values["lg"]),
          height: height / 2,
        }}
        data={terms}
        itemContent={(index, user) => (
          <Stack sx={{ pb: 1 }} direction="row">
            <Box
              sx={{
                typography: "subtitle1",
                width: "2%",
                mr: 4,
                fontWeight: "medium",
                textAlign: "right",
                // alignItems: "right",
                justifyContent: "flex-end",
              }}
            >
              {index + 1}.
            </Box>
            <Box
              sx={{
                width: "97%",
                typography: "subtitle1",
                fontWeight: "medium",
                color: "secondary.main",
                justifyContent: "flex-start",
                pr: 1,
              }}
            >
              {user.a}
            </Box>
          </Stack>
        )}
      />
    </>
  );
}
