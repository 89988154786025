import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useCustStore } from "../store/zuCustStore";
import { recoveryUI } from "../store/custUIFormFields";

import { addNewUserInfo } from "../store/zuCustActions";
import { checkUser, updUser } from "../store/zuCustActions";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import { SignUpCusTxtFld } from "../home/SignUpCusTxtFld";
import StyledButton from "../shared/StyledButton";
import StyledSection from "../shared/StyledSection";
import {
  Link as RouterLink,
  useNavigate,
  useLoaderData,
  useLocation,
} from "react-router-dom";
import Typography from "@mui/material/Typography";

import myAxios from "../MyAxios";
import bcrypt from "bcryptjs-react";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { createYupSchema } from "../utils/jsFuncions";

export function PassRecovery(props) {
  // const emailChk2 = useCustStore((state) => state.emailChk2);
  // const custReset = useCustStore((state) => state.reset);
  const { state } = useLocation();
  console.log("state :: ", state);
  const custResetEmailChk = useCustStore((state) => state.resetEmailChk);
  // const CurrentUser = useCustStore((state) => state.currentUser);
  const { data1 } = useLoaderData();
  console.log("data1", data1);
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    if (
      useCustStore.getState().validatedUser == true &&
      data1.loc === "forgotpwd"
    ) {
      console.log("beast, validatedUsr", useCustStore.getState().validatedUser);
      // navigate("/dashboard");
    }
  }, [useCustStore.getState().validatedUser]);

  useEffect(() => {
    // custReset();
    custResetEmailChk();
  }, []);

  const emailChk = useCustStore((state) => state.emailChk);
  const validatedUser = useCustStore((state) => state.validatedUser);

  const navigate = useNavigate();

  const handleDashSend = () => {
    navigate("/dashboard");
  };
  const handleBackSend = () => {
    navigate(-1);
  };

  const onSubmit = async (data) => {
    console.log("onsubmit data" + JSON.stringify(data));

    try {
      const usrData = {
        user_email: data.user_email0,
        user_pwd: data.user_pwd0,
        user_id: state.data.id,
      };
      console.log("onsubmit userData", usrData);
      const res = await updUser(usrData);
      // if (res.status === 200) {
      console.log("response from backend call", res);
      if (res[0].statuscd == 0) {
        setSubmitted(true);
        console.log("submitted", submitted);
      }
      // const data = await res.data;
      // console.log("refreshCust-res.data:" + JSON.stringify(res.data));
      // }
      console.log(
        "useCustStore.getState().validatedUser",
        useCustStore.getState()
      );

      // if (useCustStore.getState().validatedUser == true) {
      //   console.log(
      //     "beast, validatedUsr",
      //     useCustStore.getState().validatedUser
      //   );
      //   navigate("/dashboard");
      // }
      // }
    } catch (err) {
      throw err;
    }

    // checkUser(data).then((res) => {
    //   // useCustStore.setState({ validatedUser: res });
    //   if (res) {
    //     navigate("/dashboard");
    //   }
    // });
  };
  // const schema = yup.object().shape(recoveryUI.reduce(createYupSchema, {}));
  // console.log("validation schema", schema);
  const methods = useForm({
    shouldUnregister: false,
    // resolver: yupResolver(schema),
    resolver: yupResolver(
      yup.object().shape(recoveryUI.reduce(createYupSchema, {}))
    ),
    mode: "onBlur",
    reValidateMode: "onBlur",
  });

  console.log("methods", methods);

  return (
    <StyledSection headerText="Reset Password" appendSx={{ mt: 3 }} logo>
      <Box id="back-to-top-anchor"></Box>
      <Stack direction="row" sx={{ alignItems: "flex-end" }}>
        {/* <Stack> */}
        {/* <Typography>Password Successfully Changed.</Typography> */}
        <StyledButton onClick={handleBackSend}>Back</StyledButton>
        {/* </Stack> */}
      </Stack>
      <Stack
        sx={{
          pt: 1,
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Box
          sx={{
            textAlign: "center",
            fontWeight: "medium",
            color: "textSecondary",
          }}
        >
          Enter the email associated with your login
        </Box>
      </Stack>
      <Stack
        direction="column"
        sx={{
          alignItems: "center",
          justifyContent: "center",
          // width: "100%",
        }}
      >
        <FormProvider {...methods}>
          <form>
            <Stack
              direction="column"
              sx={{
                alignItems: "center",
                justifyContent: "center",
                // width: "100%",
              }}
            >
              {recoveryUI.map((field, j) => (
                <React.Fragment key={j}>
                  <Stack
                    key={j}
                    sx={{
                      mt: 3,
                      // justifyContent: "center",
                      // alignItems: "center",
                      // bgcolor: "common.compbg",
                      width: "100%",
                    }}
                    // direction="column"
                    // display={
                    //   field.ui_data.initHidden !== "Y"
                    //     ? "block"
                    //     : emailChk2.verifyStatus === 2 &&
                    //       field.ui_data.colname === "everify0"
                    //     ? "block"
                    //     : emailChk2.codeStatus === 1 &&
                    //       field.ui_data.colname === "user_pwd0"
                    //     ? "block"
                    //     : "none"
                    // }
                    display={
                      field.ui_data.initHidden !== "Y"
                        ? "block"
                        : emailChk.verifyStatus === 2 &&
                          field.ui_data.colname === "everify0"
                        ? "block"
                        : emailChk.verifyStatus === 3 &&
                          field.ui_data.colname === "user_pwd0"
                        ? "block"
                        : "none"
                    }
                  >
                    <SignUpCusTxtFld
                      allprops={field.ui_data}
                      key={j}
                      required={field.ui_data.required === "Y" ? true : false}
                      type={field.ui_data.type}
                      label={field.ui_data.collabel}
                      name={field.ui_data.colname}
                      // value={
                      //   field.ui_data.type === "email"
                      //     ? emailChk2.verifiedEmail
                      //     : ""
                      // }
                      value={
                        field.ui_data.id === "Email" ? state?.data?.login : ""
                      }
                      validationType={field.ui_data.validationType}
                      mask={field.ui_data.mask}
                      placeholder={field.ui_data.placeholder}
                      initHidden={field.ui_data.initHidden}
                      helperText={field.ui_data.helpertext}
                      onfieldChg={(data) => {
                        console.log("data", data);
                        // addNewUserInfo(field.ui_data.colname, data);
                        console.log("validatedUser", validatedUser);
                      }}
                    />
                  </Stack>
                </React.Fragment>
              ))}
              {emailChk.verifyCode === 1 ? (
                <StyledButton
                  onClick={() => sendCode(emailChk.verifiedEmail, 1)}
                  sx={{
                    m: 0,
                    p: 0,
                    // fontSize: "h5",
                    // fontWeight: "bold",
                    border: "0px",
                    borderStyle: "solid",
                    borderRadius: "10px 10px 10px 10px",
                  }}
                >
                  Resend
                </StyledButton>
              ) : (
                <></>
              )}
              <Stack
                sx={{
                  mt: 3,
                  width: "100%",
                }}
              >
                <StyledButton
                  type="submit"
                  sx={{ py: 0.5 }}
                  onClick={methods.handleSubmit(onSubmit)}
                >
                  Submit
                </StyledButton>
              </Stack>
              {/* // useCustStore.getState().validatedUser && */}

              {submitted ? (
                <>
                  <Typography>Successfully Changed Pwd</Typography>
                </>
              ) : (
                <></>
              )}
              {/* {useCustStore.getState().validatedUser &&
              data1.loc === "forgotpwd" ? (
                <>
                  <Stack sx={{ p: 2 }}>
                    <Typography>Password Successfully Reset</Typography>
                    <StyledButton onClick={handleDashSend}>
                      Dashboard
                    </StyledButton>
                  </Stack>
                </>
              ) : (
                <></>
              )} */}
            </Stack>
          </form>
        </FormProvider>
      </Stack>

      {/* <Stack sx={{ py: 3 }}>
        <Box
          // display={emailChk2.verifyStatus === 2 ? "" : "none"}
          display={emailChk.verifyStatus === 2 ? "" : "none"}
          sx={{ textAlign: "center", color: "textSecondary" }}
        >
          Verification code has been sent to your email.
        </Box>
        <Box
          // display={emailChk2.codeStatus === 1 ? "" : "none"}
          display={emailChk.verifyStatus === 3 ? "" : "none"}
          sx={{ textAlign: "center", color: "textSecondary" }}
        >
          Enter a new password
        </Box>
      </Stack> */}
    </StyledSection>
  );
}

PassRecovery.propTypes = {};

// export default PassRecovery;
