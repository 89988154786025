import React, { useEffect } from "react";
// import classNames from "classnames";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useCartStore } from "../store/zuCartStore";
import { useCustStore } from "../store/zuCustStore";
import Stack from "@mui/material/Stack";
import { Typography } from "@mui/material";

function Receipt(props) {
  const TAX_RATE = 0.07;
  const cart = useCartStore((state) => state.cart);
  const cust = useCustStore((state) => state.cust);

  console.log(cart);
  console.log(cust);

  function findTotal() {
    var total = 0;
    cart.map((field) => {
      total += field.quantity * field.price;
    });
    return total;
  }
  var total = findTotal();
  const tableTitleSx = {
    color: "textSecondary",
    fontWeight: "bold",
    fontSize: "h5.fontSize",
  };
  const tableCellSx = {
    color: "textPrimary",
    fontWeight: "medium",
    fontSize: "h6.fontSize",
  };
  return (
    <>
      <Stack
        sx={{
          alignItems: "center",
        }}
      >
        <Stack
          component={Paper}
          elevation={1}
          sx={{
            alignItems: "center",
            borderRadius: "20px 20px 20px 20px",
            bgcolor: "primary.main",
            // width: "100%",
            // border: 1,
          }}
        >
          <TableContainer sx={{ pt: 0 }}>
            <Table
              sx={
                {
                  // minWidth: 700
                }
              }
              aria-label="spanning table"
            >
              <TableHead>
                {/* <TableRow
                sx={
                  {
                    // border: 1, borderColor: "secondary.main"
                  }
                }
                align="center"
              >
                <TableCell
                  sx={{
                    color: "common.black",
                    fontWeight: "bold",
                    fontSize: "large",
                    border: 1,
                    borderColor: "common.black",
                  }}
                  align="left"
                  colSpan={5}
                  // variant="h3"
                >
                  Receipt for {cust.cust_name}'s Order:
                </TableCell>
              </TableRow> */}
                {/* <TableRow sx={{ border: 2, borderColor: "secondary.main" }}>
                <TableCell
                  sx={{
                    color: "secondary.main",
                    fontWeight: "bold",
                    fontSize: "medium",
                    border: 1,
                    borderColor: "secondary.main",
                  }}
                  align="center"
                  colSpan={3}
                  // variant="h3"
                >
                  Services Ordered
                </TableCell>
                <TableCell
                  sx={{
                    color: "secondary.main",
                    fontWeight: "bold",
                    fontSize: "medium",
                  }}
                  align="right"
                >
                  Price
                </TableCell>
              </TableRow> */}
                <TableRow>
                  <TableCell
                    sx={{
                      ...tableTitleSx,
                    }}
                  >
                    #
                  </TableCell>
                  <TableCell
                    sx={{
                      ...tableTitleSx,
                    }}
                  >
                    Service
                  </TableCell>
                  <TableCell
                    sx={{
                      ...tableTitleSx,
                    }}
                    align="right"
                  >
                    Qty.
                  </TableCell>
                  <TableCell
                    sx={{
                      ...tableTitleSx,
                    }}
                    align="right"
                  >
                    Monthly Rate
                  </TableCell>
                  <TableCell
                    sx={{
                      ...tableTitleSx,
                    }}
                    align="right"
                  >
                    Total
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {cart
                  .filter((ord_dtl) => ord_dtl.quantity > 0)
                  .map((ord_row, m) => (
                    <TableRow key={ord_row.ui_data.introline}>
                      <TableCell
                        sx={{
                          ...tableCellSx,
                        }}
                      >
                        {ord_row.order_line_num}
                      </TableCell>
                      <TableCell
                        sx={{
                          ...tableCellSx,
                        }}
                      >
                        {ord_row.ui_data.introline}
                      </TableCell>
                      <TableCell sx={{ ...tableCellSx }} align="right">
                        {ord_row.quantity}
                      </TableCell>
                      <TableCell sx={{ ...tableCellSx }} align="right">
                        {ord_row.price_curr}
                      </TableCell>
                      <TableCell sx={{ ...tableCellSx }} align="right">
                        {ord_row.line_total_curr}
                      </TableCell>
                    </TableRow>
                  ))}

                <TableRow>
                  <TableCell rowSpan={3} />
                  <TableCell
                    sx={{
                      ...tableTitleSx,
                    }}
                    colSpan={3}
                  >
                    Subtotal
                  </TableCell>
                  <TableCell
                    sx={{
                      ...tableCellSx,
                    }}
                    align="right"
                  >
                    ${total}.00
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      ...tableTitleSx,
                    }}
                    colSpan={3}
                  >
                    Tax ({`${(TAX_RATE * 100).toFixed(0)} %`})
                  </TableCell>

                  <TableCell
                    sx={{
                      ...tableCellSx,
                    }}
                    align="right"
                  >
                    ${(1 * TAX_RATE * total).toFixed(2)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      ...tableTitleSx,
                    }}
                    colSpan={3}
                  >
                    Monthly Total
                  </TableCell>
                  <TableCell
                    sx={{
                      ...tableCellSx,
                    }}
                    align="right"
                  >
                    ${parseInt((1 * TAX_RATE * total).toFixed(2)) + total}.00
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      </Stack>
    </>
  );
}

Receipt.propTypes = {
  // classes: PropTypes.object.isRequired,
  // item: PropTypes.oneOfType([
  //   PropTypes.string,
  //   PropTypes.element,
  //   PropTypes.array,
  // ]).isRequired,
};

export default Receipt;
