import { useCustStore } from "./zuCustStore";
import { useCartStore } from "./zuCartStore";
// import { useNavigate } from "react-router-dom";
import myAxios from "../MyAxios";
import bcrypt from "bcryptjs-react";
import { BlockRounded } from "@mui/icons-material";
import { Navigate, useNavigate } from "react-router-dom";
import { callUICrud } from "./crudActions";
export const setValidatedUser = (nValidatedUser) => {
  console.log("input validateduser", nValidatedUser);
  useCustStore.setState(() => ({ validatedUser: nValidatedUser }));
  console.log(
    "useCustStore.getState().validatedUser",
    useCustStore.getState().validatedUser
  );
};

export const dupEmailcheck = async (emailid, chkType) => {
  console.log("dupEmailcheck:chkType:", chkType);

  //ChkType = R (recovery email chk), S (Signup Email chk)
  // const custReset = useCustStore((state) => state.reset);
  const useremail = { uemail: emailid };
  if (useCustStore.getState().emailChk.verifiedEmail !== emailid) {
    // console.log("dupEmailcheck:useremail:", useremail);
    // console.log(
    //   "dupEmailcheck:useCustStore.getState().emailChk:",
    //   useCustStore.getState().emailChk
    // );
    const controller = new AbortController();
    // custReset();
    useCustStore.setState((state) => ({
      emailChk: {
        ...state.emailChk,
        verifiedEmail: emailid,
        status: false,
        msg: "",
        verifyStatus: 0,
        verifyCode: 0,
        helperText: "",
      },
    }));
    try {
      // console.log("dupEmailcheck:useremail:", useremail);
      const res = await myAxios.post(`/ui/checkdupemail`, useremail);
      // console.log("dupEmailcheck:res", res);
      if (res.status === 200) {
        const data = await res.data;
        console.log("dupEmailcheck:res.data", data);
        // console.log("dupEmailcheck:res.msg", data.msg);
        if (chkType === "S") {
          useCustStore.setState((state) => ({
            emailChk: {
              ...state.emailChk,
              verifiedEmail: emailid,
              verifyStatus: data.statusCd === 0 ? 1 : 0,
              msg: data.msg,
              status: data.statusCd === 0 ? true : false,
            },
          }));
        } else {
          useCustStore.setState((state) => ({
            emailChk: {
              ...state.emailChk,
              verifiedEmail: emailid,
              verifyStatus: data.statusCd === 1 ? 1 : 0,
              msg: data.msg,
              status: data.statusCd === 1 ? true : false,
            },
          }));
          if (chkType === "L") {
            useCustStore.setState((state) => ({
              currentUser: {
                ...state.currentUser,
                id: data.data[0].id,
              },
            }));
          }
        }
        console.log(
          "dupEmailcheck:useCustStore.getState().",
          useCustStore.getState()
        );
        if (
          useCustStore.getState().emailChk.verifyStatus === 1 &&
          chkType !== "L"
        ) {
          console.log(
            "useCustStore.getState().emailChk",
            useCustStore.getState().emailChk
          );
          sendCode(useCustStore.getState().emailChk.verifiedEmail);
        }
        return useCustStore.getState().emailChk.status;
      }
    } catch (err) {
      throw err;
    }
    controller.abort();
    return useCustStore.getState().emailChk.status;
  } else {
    return useCustStore.getState().emailChk.status;
    // return true;
  }
};
export const dupEmailcheck2 = async (emailid) => {
  const controller = new AbortController();
  try {
    const useremail = { uemail: emailid };
    // console.log("useremail:", useremail);
    const res = await myAxios.post(`/ui/checkdupemail`, useremail);
    // console.log("dupEmailcheck2:res", res);
    if (res.status === 200) {
      const data = await res.data;
      // console.log("dupEmailcheck2:res.statusCd", data.statusCd);
      // console.log("dupEmailcheck2:data.msg", data.msg);
      useCustStore.setState((state) => ({
        emailChk2: {
          ...state.emailChk2,
          verifiedEmail: emailid,
          verifyStatus: data.statusCd === 1 ? 1 : 0,
          msg: data.msg,
          status: data.statusCd === 1 ? true : false,
        },
      }));
      // console.log(
      //   "useCustStore.getState().emailChk2",
      //   useCustStore.getState().emailChk2
      // );

      if (useCustStore.getState().emailChk2.verifyStatus === 1) {
        // console.log(
        //   "useCustStore.getState().emailChk2.verifyStatus",
        //   useCustStore.getState().emailChk2.verifyStatus
        // );
        sendCode2(useCustStore.getState().emailChk2.verifiedEmail);
      }
    }
  } catch (err) {
    throw err;
  }
  controller.abort();
};

export const genVerifyCode = async () => {
  const ncode = Math.floor(Math.random() * (9999 - 1000 + 1)) + 1000;
  console.log("ncode:", ncode);
  useCustStore.setState((state) => ({
    emailChk: { ...state.emailChk, verifyCode: ncode },
  }));
  console.log(
    "genVerifyCode:useCustStore.getState().emailChk :",
    useCustStore.getState().emailChk
  );
  setTimeout(() => {
    if (useCustStore.getState().emailChk.verifyStatus != 3) {
      useCustStore.setState((state) => ({
        emailChk: {
          ...state.emailChk,
          verifyCode: 1,
          helperText: "Code Expired, Resend",
        },
        // emailChk: { ...state.emailChk, verifyCode: 0, verifyStatus: 1 },
      }));
      console.log(
        "genVerifyCode in timeout:",
        useCustStore.getState().emailChk
      );
    } else null;
  }, 1 * 60 * 5000);
};
export const genVerifyCode2 = async () => {
  const ncode = Math.floor(Math.random() * (9999 - 1000 + 1)) + 1000;
  console.log("ncode2:", ncode);
  useCustStore.setState((state) => ({
    emailChk2: { ...state.emailChk2, verifyCode: ncode },
  }));
  console.log("genVerifyCode2:", useCustStore.getState().emailChk2);
  setTimeout(() => {
    if (useCustStore.getState().emailChk2.verifyStatus != 3) {
      useCustStore.setState((state) => ({
        emailChk2: { ...state.emailChk2, verifyCode: 0 },
      }));
      console.log(
        "genVerifyCode2.emailChk2 in timeout:",
        useCustStore.getState().emailChk2
      );
    } else null;
  }, 5 * 60 * 5000);
};

export const addNewCustInfo = async (colname, newValue) => {
  // useCustStore.setState((state) => {
  //   if (colname === "cust_pwd") {
  //     var salt = bcrypt.genSaltSync(10);
  //     // console.log("salt", salt);
  //     var hash = bcrypt.hashSync(newValue, salt);
  //     const newCust = { ...state.cust, [colname]: hash };
  //     state.cust = newCust;

  //   } else {
  //     const newCust = { ...state.cust, [colname]: newValue };
  //     state.cust = newCust;
  //   }
  // });
  if (colname === "cust_pwd") {
    var salt = bcrypt.genSaltSync(10);
    // console.log("salt", salt);
    var hash = bcrypt.hashSync(newValue, salt);
    useCustStore.setState((state) => ({
      cust: { ...state.cust, [colname]: hash },
    }));
  } else {
    useCustStore.setState((state) => ({
      cust: { ...state.cust, [colname]: newValue },
    }));
  }
  console.log(
    "addNewCustInfo::useCustStore.getState():",
    useCustStore.getState()
  );
};

// export const setValidatedUser = (nValidatedUser) =>{
//   useCustStore.setState((state)=>({ validatedUser : nValidatedUser}))
// }

export const addNewUserInfo = async (colname, newValue) => {
  // useCustStore.setState((state) => {
  //   if (colname === "cust_pwd") {
  //     var salt = bcrypt.genSaltSync(10);
  //     // console.log("salt", salt);
  //     var hash = bcrypt.hashSync(newValue, salt);
  //     const userInfo = { ...state.currentUser, [colname]: hash };
  //     state.currentUser = userInfo;
  //   } else {
  //     const userInfo = { ...state.currentUser, [colname]: newValue };
  //     state.currentUser = userInfo;
  //   }
  // });
  if (colname === "cust_pwd") {
    var salt = bcrypt.genSaltSync(10);
    // console.log("salt", salt);
    var hash = bcrypt.hashSync(newValue, salt);
    useCustStore.setState((state) => ({
      currentUser: {
        ...state.currentUser,
        [colname]: hash,
      },
    }));
  } else {
    // const userInfo = { ...state.currentUser, [colname]: newValue };
    // state.currentUser = userInfo;
    useCustStore.setState((state) => ({
      currentUser: {
        ...state.currentUser,
        [colname]: newValue,
      },
    }));
    console.log(useCustStore.getState().currentUser);
  }
};

export const addTempUserInfo = async (colname, newValue) => {
  // useCustStore.setState((state) => {
  //   if (colname === "cust_pwd") {
  //     var salt = bcrypt.genSaltSync(10);
  //     // console.log("salt", salt);
  //     var hash = bcrypt.hashSync(newValue, salt);
  //     const userInfo = { ...state.currentUser, [colname]: hash };
  //     state.currentUser = userInfo;
  //   } else {
  //     const userInfo = { ...state.currentUser, [colname]: newValue };
  //     state.currentUser = userInfo;
  //   }
  // });
  if (colname === "cust_pwd") {
    var salt = bcrypt.genSaltSync(10);
    // console.log("salt", salt);
    var hash = bcrypt.hashSync(newValue, salt);
    useCustStore.setState((state) => ({
      tempUser: {
        ...state.tempUser,
        [colname]: hash,
      },
    }));
  } else {
    // const userInfo = { ...state.currentUser, [colname]: newValue };
    // state.currentUser = userInfo;
    useCustStore.setState((state) => ({
      tempUser: {
        ...state.tempUser,
        [colname]: newValue,
      },
    }));
    console.log(
      "useCustStore.getState().tempUser",
      useCustStore.getState().tempUser
    );
  }
};

export const checkZip = async (ncust) => {
  console.log("ncust zipcode", ncust);
  try {
    const res = await myAxios.post(`/ui/getcitystate`, ncust);
    console.log("zipcode city", res);
    return res;
  } catch (err) {
    throw err;
  }
};

export const refreshCust = async (ncust) => {
  console.log("ncust in refreshCust:" + JSON.stringify(ncust));
  const controller = new AbortController();
  try {
    const res = await callUICrud("cust_info", "U", ncust);
    // const res = await myAxios.post(`/ui/updcust`, ncust);
    if (res.status === 200) {
      console.log("response from backend call", res);
      const data = await res.data;
      return data[0].sp_update_cust_info;
    }
  } catch (err) {
    throw err;
  }
  controller.abort();
};
// export const checkUser = async (user) => {
export const checkUser = async (user_email, user_pwd, skip_pwdchk) => {
  console.log(
    "checkUser::user_email:",
    user_email,
    "\ncheckUser::user_pwd:",
    user_pwd,
    "\ncheckUser::skip_pwdchk:",
    skip_pwdchk
  );
  // console.log("validpwd cust currentuser", useCustStore.getState().currentUser)

  const controller = new AbortController();
  try {
    // const navigate = useNavigate();
    // const res2 = await myAxios.post(`/ui/chkuser`, user);
    // const res2 = await myAxios.post(`/assets/handleCrud`, {
    //   data: user,
    //   crud_json: { obj_type: "chk_login", crud_action: "R" },
    // });
    // const res = await callUICrud("chk_login", "R", user);
    const res = await callUICrud("chk_login", "R", {
      user_email: user_email,
      grp_id: useCustStore.getState().currentUser.grp_id
        ? useCustStore.getState().currentUser.grp_id
        : null,
    });
    console.log("checkUser.res2::", res);
    const data = await res[0];
    console.log("checkUser.data:", data);
    // if (res2.status === 200 && res2.data.statusCd === "0") {
    console.log("checkUser:data", data);
    console.log(" data.pwd:", data.pwd);
    // const validpwd = await bcrypt.compare(user.user_pwd, data.pwd);
    var validpwd;
    if (skip_pwdchk && !skip_pwdchk) {
      validpwd = await bcrypt.compare(user_pwd ? user_pwd : "", data.pwd);
      console.log("validpwd", validpwd);
    } else {
      validpwd = true;
    }
    const validChange = useCustStore.getState().validatedUser || validpwd;

    if (validChange) {
      // // route tree for use by Sidebar
      // const navRoutes = await callUICrud("db_nav_list", "R", {
      //   usr_id: data.id,
      //   grp_id: data.grp_id,
      //   list_type: "R",
      // });
      // console.log("checkLogin::navRoutes:", navRoutes);
      // // flattened route list for use in route validation
      // const routesflattened = await callUICrud("db_nav_list", "R", {
      //   usr_id: data.id,
      //   grp_id: data.grp_id,
      //   list_type: "F",
      // });
      // console.log("checkLogin::routesflattened:", routesflattened);
      // const actionsflattened = await callUICrud("db_nav_action", "R", {
      //   usr_id: data.id,
      //   grp_id: data.grp_id,
      // });
      // console.log("checkLogin::actionsflattened:", actionsflattened);
      await buildSidebar(data.id, data.grp_id);
      // var addldatapoints = await callUICrud("group", "R", { user_id: data.id });
      // var currGroup = addldatapoints.filter(
      //   (grp) => data.grp_id === grp.grp_id
      // )[0].grp_name;
      // console.log("grp_name", currGroup);
      console.log("mrtlist::skip_pwdchk:", skip_pwdchk);
      useCustStore.setState((state) => ({
        currentUser: {
          ...state.currentUser,
          id: data.id,
          cust_id: data.cust_id,
          cust_name: data.cust_name,
          fname: data.fname,
          lname: data.lname,
          login: data.login,
          pwd: data.pwd,
          role: data.role,
          role_name: data.role_name,
          grp_id: data.grp_id,
          grp_name: data.grp_name,
          startdate: data.startdate,
        },
        // navroutes: navRoutes,
        // valroutes: routesflattened,
        // valactions: actionsflattened,
        reDir: skip_pwdchk ? "Y" : "N",
        validatedUser: true,
      }));
    } else {
      useCustStore.setState((state) => ({
        validatedUser: false,
        reDir: skip_pwdchk ? "Y" : "N",
      }));
    }
    console.log("validpwd cust user", useCustStore.getState(), validpwd);
    return validpwd;
    // } else {
    //   console.log("handleCrud error in checkUser", res2);
    //   throw JSON.stringify(res2.data);
    // }
  } catch (err) {
    throw err;
  }
  controller.abort();
};
export const buildSidebar = async (user_id, grp_id) => {
  // console.log("checkUser::", user);
  // console.log("validpwd cust currentuser", useCustStore.getState().currentUser)

  const controller = new AbortController();
  try {
    // const res2 = await myAxios.post(`/ui/chkuser`, user);
    // const res2 = await myAxios.post(`/assets/handleCrud`, {
    //   data: user,
    //   crud_json: { obj_type: "chk_login", crud_action: "R" },
    // });
    // const res = await callUICrud("chk_login", "R", user);
    // console.log("checkUser.res2::", res);
    // const data = await res[0];
    // console.log("checkUser.data:", data);
    // // if (res2.status === 200 && res2.data.statusCd === "0") {
    // console.log("checkUser:data", data);
    // console.log(" data.pwd:", data.pwd);
    // const validpwd = await bcrypt.compare(user.user_pwd, data.pwd);
    // console.log("validpwd", validpwd);

    // if (validpwd) {
    // route tree for use by Sidebar
    const navRoutes = await callUICrud("db_nav_list", "R", {
      usr_id: user_id,
      grp_id: grp_id,
      list_type: "R",
    });
    console.log("checkLogin::navRoutes:", navRoutes);
    // flattened route list for use in route validation
    const routesflattened = await callUICrud("db_nav_list", "R", {
      usr_id: user_id,
      grp_id: grp_id,
      list_type: "F",
    });
    console.log("checkLogin::routesflattened:", routesflattened);
    const actionsflattened = await callUICrud("db_nav_action", "R", {
      usr_id: user_id,
      grp_id: grp_id,
    });
    console.log("checkLogin::actionsflattened:", actionsflattened);
    useCustStore.setState((state) => ({
      // currentUser: {
      //   ...state.currentUser,
      //   id: data.id,
      //   cust_id: data.cust_id,
      //   fname: data.fname,
      //   lname: data.lname,
      //   login: data.login,
      //   pwd: data.pwd,
      //   role: data.role,
      //   grp_id: data.grp_id,
      //   grp_name: data.grp_name,
      //   startdate: data.startdate,
      // },
      navroutes: navRoutes,
      valroutes: routesflattened,
      valactions: actionsflattened,
      // validatedUser: true,
    }));
    // } else {
    //   useCustStore.setState((state) => ({
    //     validatedUser: false,
    //   }));
    // }
    return true;
    // } else {
    //   console.log("handleCrud error in checkUser", res2);
    //   throw JSON.stringify(res2.data);
    // }
  } catch (err) {
    throw err;
  }
  controller.abort();
};
export const updUser = async (usrData) => {
  const controller = new AbortController();
  // console.log("updUser::", usrData);
  var salt = bcrypt.genSaltSync(10);
  // console.log("salt", salt);
  var hash = bcrypt.hashSync(usrData.user_pwd, salt);
  const upduserdata = {
    ...usrData,
    // user_id: useCustStore.getState().currentUser.id,
    user_email: usrData.user_email,
    user_pwd: hash,
  };
  console.log("upduserdata::", upduserdata);
  try {
    const res = await callUICrud("users", "U", upduserdata);
    console.log("res", res);
    if (res.length > 0) {
      console.log("updUser from backend call", res);
      const data = await res[0];
      console.log("updUser:refreshCust-res.data:" + JSON.stringify(res[0]));
      return res;
    }
  } catch (err) {
    throw err;
  }
};

export const sendMail = async (usrInfo, token) => {
  console.log("usrINfo  " + JSON.stringify(usrInfo));
  const controller = new AbortController();
  try {
    const res = await myAxios.post(`/mailer`, {
      Email: usrInfo.login,
      Subject: "BREazy Order Confirmation",
      Variables: {
        name: usrInfo.cust_name,
        service1: useCartStore.getState().cart[0].ui_data.introline,
        qty1: useCartStore.getState().cart[0].quantity,
        service2: useCartStore.getState().cart[1].ui_data.introline,
        qty2: useCartStore.getState().cart[1].quantity,
        service3: useCartStore.getState().cart[2].ui_data.introline,
        qty3: useCartStore.getState().cart[2].quantity,
        quickbooksinvoice1: "www.quickbooks.com",
        cart: useCartStore.getState().cart,
        // total:
      },
    });
    if (res.status === 200) {
      const data = await res.data;
      // console.log("refreshCart-res.data:" + JSON.stringify(data));
    }
  } catch (err) {
    console.log(err.response.data);
    throw err;
  }
  controller.abort();
};

export const sendCode = async (emailid, resend) => {
  console.log("Email In SendCode   " + emailid);
  // useCustStore.setState((state) => {
  //   state.genVerifyCode();
  // });
  // useCustStore.getState().genVerifyCode();

  if (useCustStore.getState().emailChk.verifyStatus === 1 || resend === 1) {
    genVerifyCode();
    // console.log("getState() In SendCode   ", useCustStore.getState());
    const controller = new AbortController();
    try {
      // console.log(
      //   "new verifyCode:useCustStore.getState().emailChk ",
      //   useCustStore.getState().emailChk
      // );
      const res = await myAxios.post(`/mailer/verify`, {
        Email: emailid,
        Subject: "BREazy Order Confirmation",
        Variables: {
          code: useCustStore.getState().emailChk.verifyCode,
        },
      });
      if (res.status === 200) {
        useCustStore.setState((state) => ({
          emailChk: {
            ...state.emailChk,
            verifyStatus: 2,
          },
        }));
        console.log(
          "After email send, getState() In SendCode   ",
          useCustStore.getState()
        );
        const data = await res.data;
        // console.log("sendCode.data:", JSON.stringify(data));
        console.log("sendCode.data:", data);
      }
    } catch (err) {
      console.log(err.response.data);
      throw err;
    }
    controller.abort();
  }
};

export const sendCode2 = async (emailid) => {
  console.log("Email In SendCode2   " + emailid);
  // useCustStore.setState((state) => {
  //   state.genVerifyCode2();
  // });
  // useCustStore.getState().genVerifyCode2();
  genVerifyCode2();
  const controller = new AbortController();
  try {
    const emailChk2 = useCustStore.getState().emailChk2;
    console.log("emailChk2 after genVerifyCode2: ", emailChk2);
    const res = await myAxios.post(`/mailer/verify`, {
      Email: emailid,
      Subject: "BREazy Order Confirmation",
      Variables: {
        code: emailChk2.verifyCode,
        // total:
      },
    });
    console.log("mailer call res::", res);
    if (res.status === 200) {
      useCustStore.setState((state) => ({
        emailChk2: {
          ...state.emailChk2,
          verifyStatus: 2,
        },
      }));
      const data = await res.data;
      console.log("emailChk2 after sendemail: ", emailChk2);
      console.log("sendCode2.data:" + JSON.stringify(data));
      console.log("emailChk2.verifyStatus", emailChk2.verifyStatus);
    }
  } catch (err) {
    console.log(err.response.data);
    throw err;
  }
  controller.abort();
};
