import React from "react";
import Undraw from "react-undraw";
import PhoneIcon from "@mui/icons-material/Phone";
import FaxIcon from "@mui/icons-material/Fax";
import BackupIcon from "@mui/icons-material/Backup";
import EmailIcon from "@mui/icons-material/Email";

import CategoryIcon from "@mui/icons-material/Category";
import {
  HomeOutlined,
  Groups2Outlined,
  SettingsOutlined,
  ShoppingCartOutlined,
  Edit as EditIcon,
  Delete as DeleteIcon,
  DeleteSweepOutlined as DeleteSweepOutlinedIcon,
  DeleteOutlined as DeleteOutlinedIcon,
  ForwardToInbox as ForwardToInboxIcon,
  AddBoxOutlined as AddBoxOutlinedIcon,
  EditOutlined as EditOutlinedIcon,
  MenuOpen as MenuOpenIcon,
  Home as HomeIcon,
  Login as LoginIcon,
  Logout as LogoutIcon,
  KeyboardDoubleArrowLeft as KeyboardDoubleArrowLeftIcon,
  LockReset as LockResetIcon,
  ForwardToInboxOutlined as ForwardToInboxOutlinedIcon,
  VisibilityOutlined as VisibilityOutlinedIcon,
  KeyboardBackspace as KeyboardBackspaceIcon,
  AccountBoxOutlined as AccountBoxOutlinedIcon,
  ArrowCircleLeftOutlined as ArrowCircleLeftOutlinedIcon,
  ArrowCircleRightOutlined as ArrowCircleRightOutlinedIcon,
  ArrowCircleDownOutlined as ArrowCircleDownOutlinedIcon,
  ArrowCircleUpOutlined as ArrowCircleUpOutlinedIcon,
  FolderCopyOutlined as FolderCopyOutlinedIcon,
} from "@mui/icons-material";
import VisibilityIcon from "@mui/icons-material/Visibility";

// import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
// import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
// import {
//   ChevronLeft,
//   ChevronRightOutlined,

//   ReceiptLongOutlined,
//   PublicOutlined,
//   PointOfSaleOutlined,
//   TodayOutlined,
//   CalendarMonthOutlined,
//   AdminPanelSettingsOutlined,
//   TrendingUpOutlined,
//   PieChartOutlined,
// } from "@mui/icons-material";

//make sure you yarn global add typescript before reinstalling react-undraw to ensure successful build

export function Icon({ type, ...props }) {
  const getIcon = () => {
    switch (type) {
      case "phone":
        return <PhoneIcon {...props} />;
      case "fax":
        return <FaxIcon {...props} />;
      case "backup":
        return <BackupIcon {...props} />;
      case "email":
        return <EmailIcon {...props} />;
      case "EditIcon":
        return <EditIcon {...props} />;
      case "DeleteIcon":
        return <DeleteIcon {...props} />;
      case "DeleteOutlinedIcon":
        return <DeleteOutlinedIcon {...props} />;
      case "DeleteSweepOutlinedIcon":
        return <DeleteSweepOutlinedIcon {...props} />;
      case "HomeOutlined":
        return <HomeOutlined {...props} />;
      case "Groups2Outlined":
        return <Groups2Outlined {...props} />;
      case "SettingsOutlined":
        return <SettingsOutlined {...props} />;
      case "ShoppingCartOutlined":
        return <ShoppingCartOutlined {...props} />;
      case "CategoryIcon":
        return <CategoryIcon {...props} />;

      case "ForwardToInboxIcon":
        return <ForwardToInboxIcon {...props} />;
      case "AddBoxOutlinedIcon":
        return <AddBoxOutlinedIcon {...props} />;
      case "EditOutlinedIcon":
        return <EditOutlinedIcon {...props} />;
      case "LoginIcon":
        return <LoginIcon {...props} />;
      case "LogoutIcon":
        return <LogoutIcon {...props} />;
      case "MenuOpenIcon":
        return <MenuOpenIcon {...props} />;
      case "KeyboardDoubleArrowLeftIcon":
        return <KeyboardDoubleArrowLeftIcon {...props} />;
      case "LockResetIcon":
        return <LockResetIcon {...props} />;
      case "ForwardToInboxOutlinedIcon":
        return <ForwardToInboxOutlinedIcon {...props} />;
      case "VisibilityOutlinedIcon":
        return <VisibilityOutlinedIcon {...props} />;
      case "KeyboardBackspaceIcon":
        return <KeyboardBackspaceIcon {...props} />;
      case "AccountBoxOutlinedIcon":
        return <AccountBoxOutlinedIcon {...props} />;
      case "ArrowCircleLeftOutlinedIcon":
        return <ArrowCircleLeftOutlinedIcon {...props} />;
      case "ArrowCircleRightOutlinedIcon":
        return <ArrowCircleRightOutlinedIcon {...props} />;

      case "ArrowCircleDownOutlinedIcon":
        return <ArrowCircleDownOutlinedIcon {...props} />;
      case "ArrowCircleUpOutlinedIcon":
        return <ArrowCircleUpOutlinedIcon {...props} />;
      case "FolderCopyOutlinedIcon":
        return <FolderCopyOutlinedIcon {...props} />;

      default:
        return <PhoneIcon {...props} />;
    }
  };

  return getIcon();
}
export function Image({ type, ...props }) {
  // const height = "100%";
  const getImage = () => {
    Undraw.defaultProps.primaryColor = `${props.primaryColor}`;
    // console.log("type:", type);toLowerCase()
    switch (type.toLowerCase()) {
      // case "phone":
      //   // return <Undraw name="phonecall" height="150px" />;
      //   return <Undraw name="uc" height={props.height} />;
      // case "email":
      //   return <Undraw name="email" height={props.height} />;
      // case "calendar":
      //   return <Undraw name="calendar" height={props.height} />;
      //   case "cloudfiles":
      //     return <Undraw name="cloudfiles" height={props.height} />;

      //     case "fax":
      //   return <Undraw name="cxds" height={props.height} />;
      // case "backup":
      //   return <Undraw name="cxds" height={props.height} />;
      // case "security":
      //   return <Undraw name="security" height={props.height} />;
      default:
        return <Undraw name={type.toLowerCase()} height={props.height} />;
    }
  };

  return getImage();
}
