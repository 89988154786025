import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { checkUser } from "../store/zuCustActions";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import { SignUpCusTxtFld } from "../home/SignUpCusTxtFld";
import { loginUI } from "../store/custUIFormFields";
import StyledButton from "../shared/StyledButton";
import StyledHeader from "../shared/StyledHeader";
import StyledSection from "../shared/StyledSection";
import { Link } from "@mui/material";
import {
  Link as RouterLink,
  useNavigate,
  useLocation,
  redirect,
  Navigate,
} from "react-router-dom";
import { useCartStore } from "../store/zuCartStore";
import { useCustStore } from "../store/zuCustStore";
import myAxios from "../MyAxios";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { createYupSchema } from "../utils/jsFuncions";

// import { setValidatedUser } from "../store/zuCustActions";

export function LoginPage(props) {
  const [logout, setLogout] = useState(false);

  const locData = useLocation();
  const validatedUser = useCustStore((state) => state.validatedUser);
  // const custReset = useCustStore((state) => state.reset);
  // const cartReset = useCartStore((state) => state.reset);

  // const CurrentUser = useCustStore((state) => state.currentUser);
  // useEffect(() => {
  //   console.log("useCustStore in LoginPage");
  //   custReset();
  //   cartReset();
  // }, []);
  const navigate = useNavigate();

  useEffect(() => {
    // if (locData.pathname === "/logout" && !logout) {
    // custReset();
    // setLogout(true);
    // console.log("logout", logout);
    // } else
    if (useCustStore.getState().validatedUser) {
      console.log(
        "LoginPage:Useeffect:validatedUser",
        useCustStore.getState().validatedUser
      );
      console.log("LoginPage:locData", locData);
      useCartStore.setState({
        cart: [],
      });
      if (locData.state?.redirRoute) {
        navigate(locData.state.redirRoute);
      } else {
        navigate("/dashboard");
      }
    }
  }, [useCustStore.getState().validatedUser]);

  console.log("LoginPage::locData:", locData);
  const localCheck = async (data, e) => {
    // e.preventDefault();
    const controller = new AbortController();
    try {
      // const res = await myAxios.post(`/ui/getcartul`, data);
      // if (res.status === 200) {
      //   console.log("response from backend call", res);
      //   console.log("refreshCust-res.data:" + JSON.stringify(res.data));
      //   // useCartStore.setState((state) => ({ cart: res.data }));
      //   // console.log("CurrentUser in login before checkuser:", CurrentUser);
      //   const res2 = await checkUser(data);
      //   console.log(
      //     "useCustStore.getState().validatedUser:::",
      //     useCustStore.getState().validatedUser
      //   );

      // console.log(
      //   "useCustStore.getState().validatedUser after check user in loginpage:::",
      //   validatedUser
      // );

      // if (validatedUser) {
      //   // setValidatedUser(res);
      //   // console.log("CurrentUser in login after checkuser:", CurrentUser);
      //   // console.log(
      //   //   "ValidatedUser in login after checkuser:",
      //   //   useCustStore.getState().validatedUser
      //   // );
      //   // if (useCustStore.getState().currentUser?.cust_id !== null) {
      //   // console.log("before navigate");
      //   navigate("/dashboard");
      //   // return redirect("/dashboard");
      //   // }
      // }

      console.log("LoginPage before checkuser:data", data);
      // const res = await checkUser(data);
      const res = await checkUser(data.user_email, data.user_pwd);
      console.log("LoginPage after checkuser:res", res);
      // if (validatedUser === true) {
      //   <Navigate to="/dashboard" />;
      // }
      //   if (useCustStore.getState().validatedUser) {
      //     console.log("LoginPage:Useeffect:validatedUser", validatedUser);
      //     // useCartStore.setState({
      //     //   cart: [],
      //     // });
      //     if (locData.state?.redirRoute) {
      //       navigate(locData.state.redirRoute);
      //     } else {
      //       navigate("/dashboard");
      //     }
      //   }
      // } catch (err) {
      //   throw err;
      // }
      // save valid user in currentUser state in zu_cust
      // try {

      // const res = await checkUser(data);
      // if (res) {
      //   console.log("res", res);
      //   // setValidatedUser(res);
      //   console.log("CurrentUser in login after checkuser:", CurrentUser);
      //   if (CurrentUser) {
      //     console.log("before navigate");
      //     navigate("/dashboard", { replace: true });
      //     // redirect("/dashboard");
      //   }
      // }
    } catch (err) {
      throw err;
    }
    controller.abort();
  };

  const methods = useForm({
    shouldUnregister: false,
    mode: "onBlur",
    reValidateMode: "onBlur",
    resolver: yupResolver(
      yup.object().shape(loginUI.reduce(createYupSchema, {}))
    ),
  });
  return (
    <>
      <StyledSection headerText="Log In" appendSx={{ mt: 3 }} logo>
        <Box id="back-to-top-anchor"></Box>
        {/* {useCustStore.getState().loginReDirRoute &&
          useCustStore.getState().redirMsg && ( */}
        {locData.state?.redirMsg && (
          // {ValidatedUser && !locData.pathname === "/logout" ? (
          <>
            <Stack sx={{ bgcolor: "common.compbg", alignItems: "center" }}>
              <Box sx={{ typography: "h6", textAlign: "center" }}>
                {/* {useCustStore.getState().redirMsg} */}
                {locData.state.redirMsg}
              </Box>
              {/* <Box sx={{ my: 3 }}>
              <StyledButton
                onClick={() => {
                  // setValidatedUser(null);
                  navigate("/logout");
                }}
              >
                Log Out
              </StyledButton>
            </Box> */}
            </Stack>
          </>
        )}
        {/* 
        {!validatedUser && locData.pathname === "/logout" && (
          // {ValidatedUser && !locData.pathname === "/logout" ? (
          <>
            <Stack sx={{ bgcolor: "common.compbg", alignItems: "center" }}>
              <Box sx={{ typography: "h6", textAlign: "center" }}>
                You have been logged out, Please log back in below.
              </Box>
           
            </Stack>
          </>
        )} */}

        {validatedUser && !locData.pathname === "/logout" && (
          // {ValidatedUser && !locData.pathname === "/logout" ? (
          <>
            <Stack sx={{ bgcolor: "common.compbg", alignItems: "center" }}>
              <Box sx={{ typography: "h4", textAlign: "center" }}>
                You are already logged in.
              </Box>
              <Box sx={{ my: 3 }}>
                <StyledButton
                  onClick={() => {
                    // setValidatedUser(null);
                    navigate("/logout");
                  }}
                >
                  Log Out
                </StyledButton>
              </Box>
            </Stack>
          </>
        )}
        <Stack
          direction="column"
          sx={{
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Stack
            direction="column"
            sx={{
              alignItems: "center",
              justifyContent: "center",
              // width: "100%",
            }}
          >
            <FormProvider {...methods}>
              <form>
                <Stack
                  direction="column"
                  sx={{
                    alignItems: "center",
                    justifyContent: "center",
                    // width: "100%",
                  }}
                >
                  {loginUI.map((field, j) => (
                    <React.Fragment key={j}>
                      <Stack
                        key={j}
                        sx={{
                          mt: 2,
                          width: "100%",
                        }}
                      >
                        <SignUpCusTxtFld
                          allprops={field.ui_data}
                          // key={j}
                          required={
                            field.ui_data.required === "Y" ? true : false
                          }
                          // type={field.ui_data.type}
                          // label={field.ui_data.collabel}
                          name={field.ui_data.colname}
                          value=""
                          validationType={field.ui_data.validationType}
                          // mask={field.ui_data.mask}
                          // placeholder={field.ui_data.placeholder}
                          initHidden={field.ui_data.initHidden}
                          // helperText={field.ui_data.helpertext}
                          onfieldChg={(data) => {
                            console.log("data", data);
                            // addNewUserInfo(field.ui_data.colname, data);
                          }}
                        />
                      </Stack>
                    </React.Fragment>
                  ))}
                </Stack>
                {useCustStore.getState().validatedUser === false && (
                  <Stack sx={{ pt: 2 }}>
                    <Box
                      sx={{
                        typography: "body2",
                        color: "#FF0000",
                        textAlign: "center",
                      }}
                    >
                      Incorrect email or password, please try again
                    </Box>
                  </Stack>
                )}
                <Stack sx={{ mt: 3 }}>
                  <StyledButton
                    type="submit"
                    sx={{ py: 0.5 }}
                    onClick={methods.handleSubmit(localCheck)}
                  >
                    Log In
                  </StyledButton>
                </Stack>
              </form>
            </FormProvider>
          </Stack>

          <Stack sx={{ py: 3, alignItems: "center" }}>
            <Link
              component={RouterLink}
              to="/forgotpwd"
              sx={{ color: "secondary.main" }}
            >
              Forgot Password?
            </Link>
          </Stack>
        </Stack>
      </StyledSection>
    </>
  );
}

LoginPage.propTypes = {};

// export default LoginPage;
