import React, { useEffect, useRef, useState } from "react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Card from "@mui/material/Card";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import AddIcon from "@mui/icons-material/Add";
import styled from "@mui/material/styles/styled";
import { Tooltip, Menu, MenuItem } from "@mui/material";
import Stack from "@mui/material/Stack";
import useTheme from "@mui/material/styles/useTheme";
// import { useOverviewStore } from "../../store/zustandOverviewStore";
import { useWindowWidth } from "@react-hook/window-size";
import CardActions from "@mui/material/CardActions";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ArrowDropDownCircleOutlinedIcon from "@mui/icons-material/ArrowDropDownCircleOutlined";
import Collapse from "@mui/material/Collapse";
import CardContent from "@mui/material/CardContent";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import { AccordionDetails } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import SearchIcon from "@mui/icons-material/Search";
import open from "../../pictures/open.png";
import { useCartStore } from "../../store/zuCartStore";
import { FormProvider, useForm } from "react-hook-form";
import Typography from "@mui/material/Typography";
import { SignUpCusTxtFld } from "../../home/SignUpCusTxtFld";
import { addUpdCartItem } from "../../store/zuCartActions";
import { Link, Navigate, useNavigate } from "react-router-dom";
import ButtonGroup from "@mui/material/ButtonGroup";
import StyledButton from "../../shared/StyledButton";
import Button from "@mui/material/Button";
import SwipeableViews from "react-swipeable-views";
import Divider from "@mui/material/Divider";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";

import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";

import AddSharpIcon from "@mui/icons-material/AddSharp";
import RemoveSharpIcon from "@mui/icons-material/RemoveSharp";

import StraightSharpIcon from "@mui/icons-material/StraightSharp";
import TextField from "@mui/material/TextField";
import SouthSharpIcon from "@mui/icons-material/SouthSharp";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

import NorthSharpIcon from "@mui/icons-material/NorthSharp";
import IconButton from "@mui/material/IconButton";
import ItemCard from "../../home/ItemCard";
import myAxios from "../../MyAxios";
import { useLoaderData, useLocation } from "react-router-dom";
import StyledSection from "../../shared/StyledSection";
import { refreshCart } from "../../store/zuCartActions";
import { OverviewCard } from "../../home/OverviewCard";
import TuneSharpIcon from "@mui/icons-material/TuneSharp";

import { ArrowDropDownOutlined } from "@mui/icons-material";

function Assignment(props) {
  const [added, setAdded] = React.useState(false);

  const navigate = useNavigate();

  const { classes, width } = props;

  const { state } = useLocation();

  console.log("navigation state", state);

  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const methods = useForm({
    shouldUnregister: false,
    mode: "onBlur",
  });

  const [expanded, setExpanded] = React.useState(false);

  const { data1, data3 } = useLoaderData();

  const cart = useCartStore((state) => state.cart);
  console.log("cart", cart, "users", data1.data);

  var filteredCart = [];

  const filterCart = () => {
    if (state.data) {
      filteredCart = cart.filter(
        (field) => field.item_id == state.data.item_id
      );
    } else filteredCart = cart;
  };

  filterCart();

  console.log("filteredCart", filteredCart);

  const useScroll = () => {
    const aoRef = useRef(null);
    const executeScrollAO = () =>
      aoRef.current.scrollIntoView({ behavior: "smooth", block: "center" });

    return [executeScrollAO, aoRef];
  };

  const [executeScrollAO, aoRef] = useScroll();

  return (
    <>
      <StyledSection headerText="User Assignments">
        <Stack
          sx={{
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            mb: 2,
          }}
        >
          {filteredCart.map((field, j) => (
            <Stack>
              <Stack
                direction="row"
                sx={{
                  justifyContent: "space-around",
                  width: "100%",
                  bgcolor: "primary.main",
                }}
              >
                <Box
                  sx={{
                    typography: "h5",
                    fontWeight: "bold",
                    color: "secondary.main",
                    borderRadius: "10px 10px 10px 10px",
                  }}
                >
                  {field.ui_data.introline}
                </Box>
                <Box
                  sx={{
                    typography: "h5",
                    fontWeight: "bold",
                    color: "secondary.main",
                    borderRadius: "10px 10px 10px 10px",
                  }}
                >
                  Available Licenses: {field.quantity}
                </Box>
              </Stack>
              {field.quantity > 0 ? (
                <>
                  {data1.data.map((user, j) => (
                    <Stack
                      direction="row"
                      sx={{ justifyContent: "flex-start", width: "100%" }}
                    >
                      <Box
                        sx={{
                          mr: 3,
                          typography: "h7",
                          fontWeight: "bold",
                          color: "secondary.main",
                          borderRadius: "10px 10px 10px 10px",
                        }}
                      >
                        {user.id}
                      </Box>
                      <Box
                        sx={{
                          typography: "h7",
                          fontWeight: "bold",
                          color: "secondary.main",
                          borderRadius: "10px 10px 10px 10px",
                        }}
                      >
                        {user.login}
                      </Box>
                      {field.quantity > 0 ? (
                        <>
                          <Checkbox
                            sx={{
                              color: "secondary.main",
                              "&.Mui-checked": {
                                color: "secondary.main",
                              },
                            }}
                            defaultChecked
                          />
                        </>
                      ) : (
                        <></>
                      )}
                    </Stack>
                  ))}
                </>
              ) : (
                <></>
              )}
            </Stack>
          ))}
        </Stack>
      </StyledSection>
    </>
  );
}

Assignment.propTypes = {
  //   classes: PropTypes.any.isRequired,
};

export default Assignment;
