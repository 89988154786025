import React, { useEffect, useRef, useState } from "react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Card from "@mui/material/Card";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import AddIcon from "@mui/icons-material/Add";
import styled from "@mui/material/styles/styled";
import { Tooltip, Menu, MenuItem } from "@mui/material";
import Stack from "@mui/material/Stack";
import useTheme from "@mui/material/styles/useTheme";
// import { useOverviewStore } from "../../store/zustandOverviewStore";
import { useWindowWidth } from "@react-hook/window-size";
import CardActions from "@mui/material/CardActions";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ArrowDropDownCircleOutlinedIcon from "@mui/icons-material/ArrowDropDownCircleOutlined";
import Collapse from "@mui/material/Collapse";
import CardContent from "@mui/material/CardContent";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import { AccordionDetails } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import SearchIcon from "@mui/icons-material/Search";
import open from "../../pictures/open.png";
import { useCartStore } from "../../store/zuCartStore";
import { FormProvider, useForm } from "react-hook-form";
import Typography from "@mui/material/Typography";
import { SignUpCusTxtFld } from "../../home/SignUpCusTxtFld";
import { addUpdCartItem } from "../../store/zuCartActions";
import { Link, Navigate, useNavigate } from "react-router-dom";
import ButtonGroup from "@mui/material/ButtonGroup";
import StyledButton from "../../shared/StyledButton";
import Button from "@mui/material/Button";
import SwipeableViews from "react-swipeable-views";
import Divider from "@mui/material/Divider";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";

import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";

import AddSharpIcon from "@mui/icons-material/AddSharp";
import RemoveSharpIcon from "@mui/icons-material/RemoveSharp";

import StraightSharpIcon from "@mui/icons-material/StraightSharp";
import TextField from "@mui/material/TextField";
import SouthSharpIcon from "@mui/icons-material/SouthSharp";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

import NorthSharpIcon from "@mui/icons-material/NorthSharp";
import IconButton from "@mui/material/IconButton";
import ItemCard from "../../home/ItemCard";
import myAxios from "../../MyAxios";
import { useLoaderData } from "react-router-dom";
import StyledSection from "../../shared/StyledSection";
import { refreshCart } from "../../store/zuCartActions";
import { OverviewCard } from "../../home/OverviewCard";

import { ArrowDropDownOutlined } from "@mui/icons-material";
import TuneSharpIcon from "@mui/icons-material/TuneSharp";

function TabPanel(props) {
  const { classes, children, value, index, ...other } = props;
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            width: "100%",
            padding: 0,
            margin: 0,
          }}
        >
          {children}
        </Box>
      )}
    </Box>
  );
}

TabPanel.propTypes = {
  classes: PropTypes.string,
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

function DashBdCartEdit(props) {
  const [added, setAdded] = React.useState(false);

  const navigate = useNavigate();

  const { classes, width } = props;
  // const slides = props.slides;
  // console.log("slides", props);
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const methods = useForm({
    shouldUnregister: false,
    mode: "onBlur",
  });

  const AccordionStyle = {
    m: 0,
    p: 0,
    bgcolor: "primary.main",
    borderRadius: "10px 10px 10px 10px",
  };
  const AccordionSumStyle = {
    mx: 0,
    my: 0,
    px: 0.2,
    py: 0,

    flexDirection: "row",
    "& .MuiAccordionSummary-content": {
      mx: 0,
      my: 0,
      px: 0.2,
      py: 0,
    },
  };
  const SumText = {
    typography: "h5",
    color: "textSecondary",
    fontWeight: "medium",
    textAlign: "left",
  };
  const AccordionDtlStyle = {
    m: 0,
    px: 0.2,
    py: 0,
  };
  const DtlText = {
    color: "textPrimary",
    typography: "h6",
    fontWeight: "light",
    textAlign: "left",
  };

  const handleChange = (event, newValue) => {
    // console.log("value in handlechange:", value);
    // console.log("newValue:", newValue);
    setValue(newValue);
    setAdded(false);
  };
  const handleIndexChange = (index) => {
    // console.log("value in handlechange:", value);
    // console.log("index:", index);
    setValue(index);
    setAdded(false);
  };
  // const slides = useOverviewStore((state) => state.slides);
  const wwidth = useWindowWidth();

  const [anchorEl1, setAnchorEl1] = useState(null);

  const handleClick = (event) => {
    // event.preventDefault();
    // console.log("event", event.currentTarget);
    setAnchorEl1(event.currentTarget);

    // console.log("anchorEl::", event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl1(null);
    // navigate("/logout", { replace: true });
    // useCustStore.setState(() => {
    //   valdatedUser: false;
    // });
  };
  const isOpen = Boolean(anchorEl1);
  console.log("isOpen", isOpen);

  // const addons = slides.filter(
  //   (feature) => feature.ui_data.pricetype == "AddOn"
  // );

  // console.log(addons);
  const [expanded, setExpanded] = React.useState(false);

  const { data2, data3 } = useLoaderData();

  // useCartStore.setState({ cart: data1.data });
  // const cart = useCartStore((state) => state.cart);
  // console.log("cart", useCartStore.getState().cart);

  const cart = useCartStore((state) => state.cart);

  // console.log(
  //   "includes test",
  //   cart[1].ui_data.introline.includes(useCartStore.getState().filterParam)
  // );

  const filteredCart = data2.filter(
    (field) =>
      field.ui_data.introline
        .toLowerCase()
        .includes(useCartStore.getState().filterParam.toLowerCase()) ||
      field.ui_data.pricetype.includes(useCartStore.getState().filterParam)
  );

  console.log("DashBdCartEdit::slidedata:", data2);
  console.log("DashBdCartEdit::cart:", cart);
  console.log("DashBdCartEdit::filteredCart:", JSON.stringify(filteredCart));

  // const  = data2.filter((field) => field.data);

  const useScroll = () => {
    const aoRef = useRef(null);
    const executeScrollAO = () =>
      aoRef.current.scrollIntoView({ behavior: "smooth", block: "center" });

    return [executeScrollAO, aoRef];
  };
  // console.log(cart);

  const [executeScrollAO, aoRef] = useScroll();
  // console.log("value:", value);
  const handleExpandClick = () => {
    if (expanded) {
      executeScrollAO();
    }
    setExpanded(!expanded);
  };
  const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
    // return <> {...other}</>;
  })(({ theme, expand }) => ({
    // transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
    // transform: !expand ? "`${...other}` +" : "`${...other}` -",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  }));

  const StyledIconButton = styled((props) => {
    const { slotProps, ...other } = props;
    return <IconButton {...other} />;
    // return <> {...other}</>;
  });

  const [param, setParam] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("e.target.value", param, param.length);

    useCartStore.setState({ filterParam: param });
    refreshCart(cart);
  };

  const z = data2.sort((a, b) =>
    useCartStore.getState().filterParam.length > 0 &&
    a.ui_data.introline.includes(useCartStore.getState().filterParam)
      ? -1
      : 1
  );
  console.log("z", z);

  return (
    <>
      <StyledSection headerText="Modify Subscription" secVm="0">
        <Stack
          sx={{
            bgcolor: "primary.main",
            m: 0,
            border: 1,
            borderWidth: "4px",
            borderColor: "primary.main",
          }}
        >
          <Menu
            anchorEl={anchorEl1}
            open={isOpen}
            onClose={handleClose}
            // TransitionComponent={Fade}
          >
            <MenuItem
              // divider
              onClick={() => {
                handleClose();
                console.log("beast");
                useCartStore.setState({ filterParam: "Phone" });
                refreshCart(cart);
                // setX(!x);
                //   navigate("/logout", { replace: true });
              }}
              // sx={{
              //   color: "textPrimary",
              // }}
            >
              Phones
            </MenuItem>
            <MenuItem
              // divider
              onClick={() => {
                handleClose();
                console.log("beast");
                useCartStore.setState({ filterParam: "Network" });
                // refreshCart(cart);
                // setX(!x);
                //   navigate("/logout", { replace: true });
              }}
            >
              Network
            </MenuItem>
          </Menu>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            sx={{ justifyContent: "space-between" }}
          >
            <form noValidate autoComplete="off" onSubmit={handleSubmit}>
              <Stack
                direction="row"
                sx={{
                  p: { xs: 1 },
                  width: "100%",
                  // border: 1,
                  justifyContent: "center",
                }}
              >
                <IconButton type="submit">
                  <SearchIcon sx={{ height: "100%" }} />
                </IconButton>

                <TextField
                  color="secondary"
                  size="small"
                  margin="none"
                  variant="outlined"
                  placeholder="Search"
                  sx={{
                    "& .MuiInputLabel-root": {
                      color: "secondary.main",
                      fontSize: "medium",
                    },
                    // "& .MuiFormHelperText-root": {
                    //   //helpertext/error
                    //   fontSize: "medium",
                    //   // wordWrap: "break-word",
                    //   // whiteSpace: "pre-wrap",
                    //   width: "inherit",
                    // },
                    // "&  .MuiFormHelperText-root.Mui-error": {
                    //   //<--- here
                    //   // backgroundColor: "red",
                    //   fontSize: "medium",
                    //   m: 0,
                    //   p: 0,
                    //   width: "inherit",
                    // },
                    "& .MuiOutlinedInput-root": {
                      //outline color
                      borderColor: "secondary.main",
                      "& input": {
                        color: "textPrimary",
                      },

                      "& > fieldset": {
                        borderColor: "secondary.main",
                        // borderRadius: 0,
                        color: "secondary.main",
                      },
                      "&:hover fieldset": {
                        borderColor: "common.secondary5",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "secondary.main",
                      },
                    },
                  }}
                  onChange={(e) => {
                    setParam(e.target.value);
                  }}
                />
                {useCartStore.getState().filterParam.length > 0 ? (
                  <>
                    <Button
                      onClick={() => {
                        handleClose();
                        console.log("beast");
                        useCartStore.setState({ filterParam: "" });
                        refreshCart(cart);
                      }}
                      sx={{
                        height: "30%",
                        px: 1,
                        mx: 1,
                        border: 2,
                        borderColor: "secondary.main",
                        color: "secondary.main",
                        borderRadius: "10px 10px 10px 10px",
                      }}
                    >
                      <Box sx={{ typography: "h7", fontWeight: "bold" }}>
                        Clear Filter
                      </Box>
                    </Button>
                  </>
                ) : (
                  <></>
                )}
              </Stack>
            </form>

            <Stack
              direction="row"
              sx={{ justifyContent: "center", alignItems: "center" }}
            >
              <TuneSharpIcon
                sx={{ color: "common.black", height: "100%", mr: 1 }}
              />
              <Button
                sx={{
                  height: "70%",
                  px: 1,
                  border: 2,
                  borderColor: "secondary.main",
                  color: "secondary.main",
                  borderRadius: "20px 20px 20px 20px",
                }}
                onClick={handleClick}
              >
                <Stack
                  direction="row"
                  sx={{ alignItems: "center", justifyContent: "center" }}
                >
                  <Box sx={{ typography: "h7", fontWeight: "bold" }}>
                    Product
                  </Box>
                  <ArrowDropDownOutlined sx={{ m: 0 }} />
                </Stack>{" "}
              </Button>
            </Stack>
          </Stack>
          <StyledSection headerText="" bgcolor="common.compbg">
            {filteredCart.length < 1 && <Box>No matching entries found</Box>}
            <OverviewCard slides={z} cart={cart} mode="D" />
          </StyledSection>
          <StyledSection headerText="Add-Ons" bgcolor="common.compbg" secVm="0">
            <Stack
              direction="column"
              sx={{
                // border: 1,
                mt: -2.5,
                mb: 1,
                p: 0,
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  typography: "subtitle1",
                  color: "textSecondary",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Customize with Add-On's
              </Box>
            </Stack>
            <Stack
              direction="row"
              useFlexGap
              sx={{
                justifyContent: "flex-start",
                alignItems: "flex-start",
                flexWrap: "wrap",
                m: 0,
                p: 0,

                height: "100%",
              }}
            >
              {filteredCart.map(
                (addon, index) =>
                  addon.ui_data.pricetype != "Included" && (
                    <Stack
                      direction="row"
                      sx={{
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        flexWrap: "wrap",
                        m: 0.3,
                        p: 0,
                        width: { xs: "100%", sm: "49%" },
                      }}
                    >
                      {/* <div>addon::item_id: {addon.item_id}</div> */}
                      <ItemCard addon={addon} cart={cart} index={index} />
                    </Stack>
                  )
              )}
            </Stack>
          </StyledSection>
        </Stack>
      </StyledSection>
    </>
  );
}

DashBdCartEdit.propTypes = {
  //   classes: PropTypes.any.isRequired,
};

export default DashBdCartEdit;
