import React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/system";

function StyledButton(props) {
  const { children, size, selected, sx, ...otherProps } = props;
  return (
    <Button
      variant="contained"
      disableElevation
      disableRipple
      size={size ?? "small"}
      sx={{
        m: 0,
        mx: { xs: 0.5, sm: 0.5 },
        my: 0,
        px: { xs: 1, sm: 1 },
        py: 0,
        fontSize: {
          xs: "h6.fontSize",
          sm: "h5.fontSize",
        },
        fontWeight: "medium",
        // color: "textAlt",
        // bgcolor: "secondary.main",
        bgcolor: selected ? "common.compbg" : "secondary.main",
        color: selected ? "textSecondary" : "textAlt",
        border: selected ? 0.2 : 0,
        borderColor: "secondary.main",

        borderRadius: props.pill ? 50 : "10px 10px 10px 10px",
        boxShadow: 0,
        ":hover": {
          // bgcolor: "common.secondary7",
          boxShadow: 0,
          bgcolor: "common.secondary5",
          borderColor: "secondary.main",
          border: 0.2,
        },
        ":disabled": {
          boxShadow: 0,
          bgcolor: "common.commbg",
          border: 0.2,
          borderColor: "secondary.main",
          color: "textAlt",
        },
        "&.active": {
          bgcolor: selected ? "common.compbg" : "secondary.main",
          color: selected ? "textSecondary" : "textAlt",
          border: 0.2,
        },
        "&:clicked": {
          bgcolor: selected ? "common.compbg" : "secondary.main",
          color: selected ? "textSecondary" : "textAlt",
          border: 0.2,
        },
        ...sx,
      }}
      {...otherProps}
    >
      {children}
    </Button>
  );
}

// StyledButton.propTypes = {
//   onClick: PropTypes.func.isRequired,
// };

// export default StyledButton;

// const StyledButton = styled(
//   "Button"
//   // , { shouldForwardProp: (props) => props}
// )(({ theme }) => ({
//   variant: "contained",
//   color: "textAlt",
//   disableElevation: true,
// }));
export default StyledButton;
