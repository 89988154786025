import { Link as RouterLink } from "react-router-dom";
import { dupEmailcheck } from "./zuCustActions";
import { dupEmailcheck2 } from "./zuCustActions";
import { checkZip } from "./zuCustActions";
import { useCustStore } from "./zuCustStore";

export const validationStore = {
  none: [],
  nmpwdstr: [
    { type: "required", params: ["Required"] },
    { type: "typeError", params: ["String"] },
  ],
  hiddenfields: [{ type: "typeError", params: ["String"] }],
  elogin: [
    { type: "required", params: ["Email required"] },
    {
      type: "test",
      params: [
        "dup_email_chk",
        async (value, { createError }) => {
          var validRegex =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          if (
            value !== undefined &&
            value !== null &&
            value.trim() !== "" &&
            value.match(validRegex)
          ) {
            const status = await dupEmailcheck(value, "S");

            if (!status) {
              return createError({
                message: (
                  <>
                    Email exists, Click to <a href="/login">Login</a> or use
                    another email.
                  </>
                ),
              });
            } else {
              return true;
            }
          } else {
            return createError({
              message: "please enter a valid email",
            });
          }
        },
      ],
    },
  ],

  rlogin: [
    { type: "required", params: ["Email required"] },
    {
      type: "test",
      params: [
        "dup_email_chk",
        async (value, { createError }) => {
          var validRegex =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          if (
            value !== undefined &&
            value !== null &&
            value.trim() !== "" &&
            value.match(validRegex)
          ) {
            const status = await dupEmailcheck(value, "R");

            if (!status) {
              return createError({
                message: (
                  <>
                    Email does not exist <a href="/signup">SignUp</a>
                  </>
                ),
              });
            } else {
              return true;
            }
          } else {
            return createError({
              message: "please enter a valid email",
            });
          }
        },
      ],
    },
  ],
  llogin: [
    { type: "required", params: ["Email required"] },
    {
      type: "test",
      params: [
        "dup_email_chk",
        async (value, { createError }) => {
          var validRegex =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          if (
            value !== undefined &&
            value !== null &&
            value.trim() !== "" &&
            value.match(validRegex)
          ) {
            const status = await dupEmailcheck(value, "L");

            if (!status) {
              return createError({
                message: (
                  <>
                    Email does not exist <a href="/signup">SignUp</a>
                  </>
                ),
              });
            } else {
              return true;
            }
          } else {
            return createError({
              message: "please enter a valid email",
            });
          }
        },
      ],
    },
  ],

  everify: [
    {
      type: "typeError",
      params: ["Enter verify code received in your email"],
    },
    {
      type: "test",
      params: [
        "everify",
        // "Please enter the code sent in your email",
        async (value, { createError }) => {
          console.log(
            "everify:useCustStore.getState().emailChk:",
            useCustStore.getState().emailChk
          );
          if (useCustStore.getState().emailChk.verifyStatus < 3) {
            if (parseInt(useCustStore.getState().emailChk.verifyCode) === 10) {
              return createError({
                message: <>Your code has expired, resend code</>,
              });
            } else if (
              useCustStore.getState().emailChk.verifyCode > 1 &&
              parseInt(value) === useCustStore.getState().emailChk.verifyCode
            ) {
              useCustStore.setState((state) => ({
                emailChk: { ...state.emailChk, verifyStatus: 3 },
              }));
              return true;
            } else {
              return createError({
                message: "Please enter the code received in your email",
              });
            }
          } else {
            return true;
          }
        },
      ],
    },
  ],
  emaillogin: [
    { type: "required", params: ["Email required"] },
    {
      type: "test",
      params: [
        "dup_email_chk",
        async (value, { createError }) => {
          var validRegex =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          if (
            value !== undefined &&
            value !== null &&
            value.trim() !== "" &&
            value.match(validRegex)
          ) {
            if (useCustStore.getState().emailChk.verifiedEmail !== value) {
              // await useCustStore.getState().dupEmailcheck(value);
              await dupEmailcheck(value);
              const vmsg = useCustStore.getState().emailChk;
              console.log("vmsg1:", vmsg);
            }
            if (!useCustStore.getState().emailChk.status) {
              return createError({
                message: (
                  <>
                    {useCustStore.getState().emailChk.msg}
                    {/* <Link
                          sx={{ color: "#0000FF" }}
                          underline="always"
                          component={RouterLink}
                          to="/login"
                        > */}
                    Log In
                    {/* </Link> */}
                  </>
                ),
              });
            } else {
              return true;
            }
          } else {
            return createError({
              message: "please enter a valid email",
            });
          }
        },
      ],
    },
  ],
  r_rlogin: [
    { type: "required", params: ["Email required"] },
    {
      type: "test",
      params: [
        "dup_email_chk",
        async (value) => {
          var validRegex =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          if (
            value !== undefined &&
            value !== null &&
            value.trim() !== "" &&
            value.match(validRegex)
          ) {
            console.log("before r_emaillogin dupcheck");
            const status = await dupEmailcheck(value, "S");
            // const status = true;
            console.log("after r_emaillogin dupcheck");
            console.log("after r_emaillogin dupcheck::status", status);
            if (status) {
              // return (
              //   <>
              //     Email does not exist <a href="/signup">SignUp</a>
              //   </>
              // );
              return "please enter a valid email2";
            } else {
              return true;
            }
          } else {
            return "please enter a valid email";
          }
        },
      ],
    },
  ],

  emailcrud: [
    { type: "required", params: ["Email required"] },
    {
      type: "test",
      params: [
        "dup_email_chk",
        async (value, { createError }) => {
          var validRegex =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          if (
            value !== undefined &&
            value !== null &&
            value.trim() !== "" &&
            value.match(validRegex)
          ) {
            return true;
          } else {
            return createError({
              message: "please enter a valid email",
            });
          }
        },
      ],
    },
  ],

  emailverify: [
    {
      type: "typeError",
      params: ["Enter verifyCd sent in your email"],
    },
    {
      type: "test",
      params: [
        "everify",
        // "Please enter the code sent in your email",
        async (value, { createError }) => {
          // console.log(
          //   "useCustStore.getState().emailChk.verifyCode:",
          //   useCustStore.getState().emailChk.verifyCode
          // );
          if (parseInt(useCustStore.getState().emailChk.verifyCode) === 0) {
            return createError({
              message: <>Your code has expired, resnd code</>,
            });
          } else if (
            useCustStore.getState().emailChk.verifyCode > 0 &&
            parseInt(value) === useCustStore.getState().emailChk.verifyCode
          ) {
            useCustStore.setState((state) => ({
              emailChk: { ...state.emailChk, verifyStatus: 3 },
            }));
            return true;
          } else {
            return createError({
              message: "Please enter the code received in your email",
            });
          }
        },
      ],
    },
  ],

  // nmpwdstr:  [
  //     { type: "required", params: ["Password is a required field"] },
  //   ],

  phstr: [
    { type: "required", params: ["Phone# is Required"] },
    { type: "min", params: [12, "Phone min 12 chars"] },
    { type: "max", params: [12, "Phone max 12 chars"] },
  ],
  zipstr: [
    { type: "required", params: ["Zip code required"] },
    { type: "min", params: [5, "Zip min 5 chars"] },
    { type: "max", params: [5, "Zip max 5 chars"] },
    {
      type: "test",
      params: [
        "zip_check",
        async (value, { createError }) => {
          if (value !== undefined && value !== null && value.length >= 5) {
            // console.log("in test schema for zips, value::", value);
            // console.log(
            //   "in test schema for zips, new verifiedZip",
            // useCustStore.getState().emailChk2.verifiedZip
            // );
            if (useCustStore.getState().emailChk.verifiedZip !== value) {
              useCustStore.setState((state) => ({
                cust: { ...state.cust, zipcode: value },
              }));
              // console.log(
              //   "in test schema for zips, new validation",
              //   useCustStore.getState().cust.zipcode
              // );
              const res = await checkZip(useCustStore.getState().cust);
              // console.log("response zip", res);
              if (res.data.status === 200) {
                useCustStore.setState((state) => ({
                  cust: {
                    ...state.cust,
                    city: res.data.data.city,
                    state: res.data.data.state,
                  },
                }));
                useCustStore.setState((state) => ({
                  emailChk: {
                    ...state.emailChk,
                    verifiedZip: value,
                  },
                }));
                console.log(
                  "updated customer 200",
                  useCustStore.getState().cust
                );
                console.log(
                  "updated emailChk",
                  useCustStore.getState().emailChk
                );

                return true;
              } else if (res.data.status === 400) {
                console.log("invalidZip!");
                useCustStore.setState((state) => ({
                  cust: {
                    ...state.cust,
                    city: null,
                    state: "",
                  },
                }));
                console.log(
                  "updated customer 400",
                  useCustStore.getState().cust
                );
                return createError({
                  message: "Plese enter a valid zipcode",
                });
                // return false;
              }
              return true;
            } else {
              // console.log(
              //   "in test schema for zips2::",
              //   useCustStore.getState().emailChk.verifiedZip
              // );
              return true;
            }
          } else return true;
        },
      ],
    },
  ],
  zipstr_old: [
    { type: "required", params: ["Zip code required"] },
    { type: "min", params: [5, "Zip min 5 chars"] },
    { type: "max", params: [5, "Zip max 5 chars"] },
    {
      type: "test",
      params: [
        "zip_check",
        async (value, { createError }) => {
          if (value !== undefined && value !== null && value.length >= 5) {
            console.log("in test schema for zips, value", value);
            // if (useCustStore.getState().cust.zipcode !== value) {
            if (useCustStore.getState().emailChk2.verifiedZip !== value) {
              useCustStore.setState((state) => ({
                cust: { ...state.cust, zipcode: value },
              }));
              console.log(
                "in test schema for zips, useCustStore.getState().cust.zipcode",
                useCustStore.getState().cust.zipcode
              );
              const res = await checkZip(useCustStore.getState().cust);
              console.log("response zip", res);
              if (res.data.status === 200) {
                useCustStore.setState((state) => ({
                  cust: {
                    ...state.cust,
                    city: res.data.data.city,
                    state: res.data.data.state,
                  },
                }));
                useCustStore.setState((state) => ({
                  emailChk2: {
                    ...state.emailChk2,
                    verifiedZip: value,
                  },
                }));
                console.log(
                  "updated customer 200",
                  useCustStore.getState().cust
                );
                return true;
              } else if (res.data.status === 400) {
                console.log("invalidZip!");
                useCustStore.setState((state) => ({
                  cust: {
                    ...state.cust,
                    city: null,
                    state: "",
                  },
                }));
                console.log(
                  "updated customer 400",
                  useCustStore.getState().cust
                );
                return false;
              }
              return true;
            }
          } else return true;
        },
      ],
    },
  ],
  r_zipstr: [
    { type: "required", params: ["Zip code required"] },
    { type: "min", params: [5, "Zip min 5 chars"] },
    { type: "max", params: [5, "Zip max 5 chars"] },
    {
      type: "test",
      params: [
        "zip_check",
        async (value) => {
          if (value !== undefined && value !== null && value.length >= 5) {
            // console.log("in test schema for zips, value::", value);
            // console.log(
            //   "in test schema for zips, new verifiedZip",
            // useCustStore.getState().emailChk2.verifiedZip
            // );
            if (useCustStore.getState().emailChk.verifiedZip !== value) {
              useCustStore.setState((state) => ({
                cust: { ...state.cust, zipcode: value },
              }));
              console.log(
                "in test schema for zips, new validation",
                useCustStore.getState().cust
              );
              const res = await checkZip(useCustStore.getState().cust);
              console.log("response zip", res);
              if (res.data.status === 200) {
                useCustStore.setState((state) => ({
                  cust: {
                    ...state.cust,
                    city: res.data.data.city,
                    state: res.data.data.state,
                  },
                }));
                useCustStore.setState((state) => ({
                  emailChk: {
                    ...state.emailChk,
                    verifiedZip: value,
                  },
                }));
                console.log(
                  "updated customer 200",
                  useCustStore.getState().cust
                );
                console.log(
                  "updated emailChk",
                  useCustStore.getState().emailChk
                );

                return true;
              } else if (res.data.status === 400) {
                console.log("invalidZip!");
                useCustStore.setState((state) => ({
                  cust: {
                    ...state.cust,
                    city: null,
                    state: "",
                  },
                }));
                console.log(
                  "updated customer 400",
                  useCustStore.getState().cust
                );
                return "Plese enter a valid zipcode";
                // return false;
              }
              return true;
            } else {
              // console.log(
              //   "in test schema for zips2::",
              //   useCustStore.getState().emailChk.verifiedZip
              // );
              return true;
            }
          } else return true;
        },
      ],
    },
  ],
};

// export const recoveryUI = [
//   {
//     ui_data: {
//       introline: "Email",
//       helpertext: "",
//       id: "Email",
//       colname: "cust_email0",
//       collabel: "Email",
//       required: "Y",
//       type: "email",
//       validationType: "string",
//       defaultValue: "123@gmail.com",
//       validations: [
//         {
//           type: "test",
//           params: [
//             "dup_email_chk2",
//             async (value, { createError }) => {
//               var validRegex = /\S+@\S+\.\S+/;
//               if (
//                 value !== undefined &&
//                 value !== null &&
//                 value.trim() !== "" &&
//                 value.match(validRegex)
//               ) {
//                 if (useCustStore.getState().emailChk2.verifiedEmail !== value) {
//                   await dupEmailcheck2(value);
//                   const vmsg = useCustStore.getState().emailChk2;
//                   console.log("vmsg2:", vmsg);
//                 }
//                 if (!useCustStore.getState().emailChk.status) {
//                   // if (!vmsg.status) {
//                   return createError({
//                     message: (
//                       <>
//                         There is no acct associated w this email{" "}
//                         <Link
//                           sx={{ color: "#0000FF" }}
//                           underline="always"
//                           component={RouterLink}
//                           to="/"
//                         >
//                           Sign Up
//                         </Link>
//                       </>
//                     ),
//                   });
//                 } else {
//                   return true;
//                 }
//               } else {
//                 return createError({
//                   message: "please enter a valid email",
//                 });
//               }
//             },
//           ],
//         },
//       ],
//     },
//     form_pg_num: 1,
//     form_page_seq: 1,
//   },
//   {
//     ui_data: {
//       introline: "Verify Code",
//       helpertext: "We sent a code to your email, Please enter here to verify",
//       id: "everify0",
//       colname: "everify0",
//       collabel: "Verify Code",
//       required: "Y",
//       type: "text",
//       initHidden: "Y",
//       initHidden2: "N",
//       validationType: "string",
//       // defaultValue: "",
//       defaultValue: "0000",
//       mask: "0000",
//       placeholder: "0000",
//       validations: [
//         {
//           type: "test",
//           params: [
//             "everify0",
//             "Please enter the code sent in your email",
//             async (value, { createError }) => {
//               if (
//                 parseInt(useCustStore.getState().emailChk2.verifyCode) === 0
//               ) {
//                 return createError({
//                   message: <>Your code has expired, resend code</>,
//                 });
//               } else if (
//                 parseInt(value) === useCustStore.getState().emailChk2.verifyCode
//               ) {
//                 useCustStore.setState((state) => ({
//                   emailChk2: {
//                     ...state.emailChk2,
//                     verifyStatus: 3,
//                     codeStatus: 1,
//                   },
//                 }));

//                 return true;
//               }
//               return false;
//             },
//           ],
//         },
//       ],
//     },
//     form_pg_num: 1,
//     form_page_seq: 1,
//   },
//   {
//     ui_data: {
//       introline: "Password",
//       helpertext: "",
//       id: "pwd",
//       colname: "cust_pwd",
//       collabel: "Password",
//       required: "Y",
//       initHidden: "Y",
//       initHidden2: "Y",
//       type: "password",
//       validationType: "string",
//       defaultValue: "password",
//       validations: [
//         { type: "required", params: ["Password is a required field"] },
//       ],
//     },
//     form_pg_num: 1,
//     form_page_seq: 1,
//   },
// ];
