import axios from "axios";

import { useTokenStore } from "./store/zuTokenStore";

const myAxios = axios.create(
  {
    baseURL: process.env.REACT_APP_PUBLIC_URL,
    // baseURL: "http://localhost:3000",
  },
  console.log("baseURL", process.env.REACT_APP_PUBLIC_URL)
);

myAxios.interceptors.request.use(
  (config) => {
    // Do something before request is sent
    // console.log("in myaxios interceptor: ", useTokenStore.getState().token);
    const tokenstate = useTokenStore.getState().token;
    config.headers["Authorization"] = `Bearer ${tokenstate}`;
    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

export default myAxios;
