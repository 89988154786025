import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
// import { Link } from "react-router-dom";
import Link from "@mui/material/Link";
import {
  Stack,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Drawer,
  IconButton,
  Typography,
  Toolbar,
  Box,
} from "@mui/material";

import { NavLink as RouterLink, Outlet } from "react-router-dom";

import CloseIcon from "@mui/icons-material/Close";

import useMediaQuery from "@mui/material/useMediaQuery";
import useTheme from "@mui/material/styles/useTheme";
// // FIXME checkout https://mui.com/components/use-media-query/#migrating-from-withwidth
// const withWidth = () => (WrappedComponent) => (props) =>
//   <WrappedComponent {...props} width="xs" />;
import { useCustStore } from "../store/zuCustStore";
import Sidebar from "../dashboard/MuiSidebarV1";
function NavigationDrawer(props) {
  const {
    onClose,
    menuItems,
    selectedItem,
    drawerPosition,
    setDrawerPosition,
    // clickAwayPos,
    // setClickAwayPos,
  } = props;
  const theme = useTheme();
  const mqSm = useMediaQuery(theme.breakpoints.up("sm"));
  const validatedUser = useCustStore((state) => state.validatedUser);
  const isMobile = useMediaQuery("(Max-width: 600px)");
  // const isMobile = useMediaQuery(theme.breakpoints.up("xs"));
  // const [isSidebarOpen, setIsSidebarOpen] = useState(isNonMobile);
  const drawerWidth = "200px";
  const [isMini, setIsMini] = useState(false);
  // drawerPosition, 0 - closed, 1 - mini, 2 - full
  // const [drawerPosition, setDrawerPosition] = useState(isMobile ? 1 : 2);

  // useEffect(() => {
  //   window.onresize = () => {
  //     if (mqSm && open) {
  //       onClose();
  //     }
  //   };
  // }, [open, onClose]);
  // useEffect(() => {
  //   window.onresize = () => {
  //     console.log("NavigationDrawer.useEffect.isMobile:", isMobile);
  //     console.log("NavigationDrawer.useEffect.drawerPosition:", drawerPosition);
  //     if (
  //       !isMobile
  //       // && drawerPosition !== 0
  //     ) {
  //       // onClose();
  //       // setDrawerPosition(0);
  //       setDrawerPosition((prevState) => ({ ...prevState, Pos: 0 }));
  //       console.log("NavigationDrawer.useEffect2.isMobile:", isMobile);
  //       console.log(
  //         "NavigationDrawer.useEffect2.drawerPosition:",
  //         drawerPosition
  //       );
  //     }
  //   };
  // }, [isMobile]);

  // console.log("NavigationDrawer.isMobile:", isMobile);
  // console.log("NavigationDrawer.drawerPosition:", drawerPosition);
  return (
    <Stack
      direction="row"
      sx={{
        p: 0,
        m: 0,
        my: 0.2,
        // mx: 1,
        width: "100%",
        // bgcolor: "background.default",
      }}
    >
      {/* <Stack
      // display={isNonMobile && isSidebarOpen ? "block" : "none"}
      sx={{
        m: 0,
        p: 0,
        bgcolor: "common.compbg",
        //   // alignItems: "left",
        //   mx: 0.5,
        // height: "100%",
      }}
    > */}
      <Sidebar // user={data || {}}
        navItems={menuItems}
        anchor={isMobile ? "top" : "left"}
        isMobile={isMobile}
        drawerWidth={drawerWidth}
        isMini={isMini}
        setIsMini={setIsMini}
        drawerPosition={drawerPosition}
        setDrawerPosition={setDrawerPosition}
        // clickAwayPos={clickAwayPos}
        // setClickAwayPos={setClickAwayPos}
      />
      {/* </Stack> */}
      {/* <Stack
        // direction={{ xs: "column", sm: "column", md: "row" }}
        sx={{
          width:
            drawerPosition === 0
              ? "100%"
              : drawerPosition === 1
              ? "95%"
              : `calc(100% - ${drawerWidth})`,
          height: "100%",
          // alignItems: "flex-start",
          // justifyContent: "space-around",
          p: 0,
          // mx: 0.5,
          // px: 0.5,
          mx: 0.2,
          // bgcolor: "background.alt",
        }}
      > */}
      {/* <Box component="main" sx={{ flexGrow: 1, p: 0, m: 0.5 }}> */}
      {/* <Outlet context={[drawerWidth]} /> */}
      {/* </Box> */}
      {/* </Stack> */}
    </Stack>
  );
}

NavigationDrawer.propTypes = {
  // anchor: PropTypes.string.isRequired,
  // open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  menuItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  // width: PropTypes.string.isRequired,
  selectedItem: PropTypes.string,
};

export default // withWidth()
NavigationDrawer;
