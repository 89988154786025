import React, {
  useState,
  useRef,
  useCallback,
  Suspense,
  useEffect,
} from "react";
import useTheme from "@mui/material/styles/useTheme";
import useMediaQuery from "@mui/material/useMediaQuery";
import Paper from "@mui/material/Paper";
import myAxios from "../MyAxios";
import Typography from "@mui/material/Typography";
// import Card from "@mui/material/Card";
import { Card } from "@mui/material";
import { List } from "@mui/material";
import { ListItem } from "@mui/material";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";

import CircularProgress from "@mui/material/CircularProgress";
// import { refreshCart } from "../store/zuCartActions";

import styled from "@mui/material/styles/styled";
import reviews from "./reviews.json";
import { Icon } from "./OverviewIcon";

import IconButton from "@mui/material/IconButton";

// import animation from "./women_reading.json";
import animation from "./lf30_editor_yqjhy9by.json";
// import animationt from "./women_reading.json";
// import SvgIcon from "@mui/material/SvgIcon";

// import Lottie from "lottie-react";
// import Lottie from "react-lottie-light";
import Lottie from "@rookino/react-lottie-light";
// import FaceIcon from "@mui/icons-material/Face";

//lists
// import List from "@mui/material/List";
// import open from "../pictures/open.png";

// import CheckIcon from "@mui/icons-material/Check";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import ArrowDropDownCircleOutlinedIcon from "@mui/icons-material/ArrowDropDownCircleOutlined";

// import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
// import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";

// import ForwardIcon from "@mui/icons-material/Forward";
// import ArrowBackIcon from "@mui/icons-material/ArrowBack";

// import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
// import NavigateNextIcon from "@mui/icons-material/NavigateNext";

// import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
// import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
// import ArrowRightOutlinedIcon from "@mui/icons-material/ArrowRightOutlined";

// import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
// import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";

// import AddSharpIcon from "@mui/icons-material/AddSharp";
// import RemoveSharpIcon from "@mui/icons-material/RemoveSharp";

// import SouthSharpIcon from "@mui/icons-material/SouthSharp";
// import NorthSharpIcon from "@mui/icons-material/NorthSharp";

// import StraightSharpIcon from "@mui/icons-material/StraightSharp";

// import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
// import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined";
// import AddIcon from "@mui/icons-material/Add";
// import RemoveIcon from "@mui/icons-material/Remove";
// import GppMaybeIcon from "@mui/icons-material/GppMaybe";

// import styled from "@mui/material/styles/styled";
// import Accordion from "@mui/material/Accordion";
// import { Accordion } from "@mui/material";
// import AccordionDetails from "@mui/material/AccordionDetails";
// import AccordionSummary from "@mui/material/AccordionSummary";
// import { AccordionDetails } from "@mui/material";
// import { AccordionSummary } from "@mui/material";

// import Collapse from "@mui/material/Collapse";
// import { Collapse } from "@mui/material";

// import { Image } from "./OverviewIcon";

import { OverviewCard } from "./OverviewCard";
import Tiles from "./Tiles";
import Reviews from "./Reviews";
// import CardActions from "@mui/material/CardActions";
// import CardContent from "@mui/material/CardContent";

// import StyledHeader from "../shared/StyledHeader";

// import { useOverviewStore } from "../store/zustandOverviewStore";
import { useCartStore } from "../store/zuCartStore";
import { useCustStore } from "../store/zuCustStore";
import { useTokenStore } from "../store/zuTokenStore";
import VerticalAddons from "./VerticalAddons";

// import { SignUpCusTxtFld } from "./SignUpCusTxtFld";
// import { addUpdCartItem } from "../store/zuCartActions";
// import { FormProvider, useForm } from "react-hook-form";
// import { Link } from "react-router-dom";
// import StyledButton from "../shared/StyledButton";
import "./Overview.css";
// import { useKeenSlider } from "keen-slider/react";
// import "keen-slider/keen-slider.min.css";
import StyledSection from "../shared/StyledSection";
import { callUICrud } from "../store/crudActions";
import {
  useLoaderData,
  defer,
  useRouteLoaderData,
  useLocation,
} from "react-router-dom";

// import ScrollTo from "react-scroll-into-view";

const lottieOptions = {
  loop: true,
  autoplay: true,
  animationData: animation,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const mainText = {
  color: "secondary.main",
  fontWeight: "bold",
};

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
  // return <> {...other}</>;
})(({ theme, expand }) => ({
  // transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  // transform: !expand ? "`${...other}` +" : "`${...other}` -",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const sharedStyle = {
  m: 0,
  p: 0,
  // mt: 2,
  width: { xs: "100%", sm: "50%" },
  alignItems: { xs: "center", sm: "flex-start" },
  justifyContent: { xs: "center", sm: "flex-start" },
  textAlign: { xs: "center", sm: "left" },
};
const lottieStyle = {
  m: 0,
  p: 0,
  width: { xs: "100%", sm: "50%" },
  alignItems: { xs: "center", sm: "flex-start" },
  justifyContent: { xs: "center", sm: "flex-start" },
  textAlign: { xs: "center", sm: "left" },
};

const SlideStack = {
  position: "relative",
  // bgcolor: "primary.main",
  bgcolor: "common.compbg",
  mx: 0,
  my: 0,
  p: 0,
  // height: "300px",
  // border: 1,
  borderRadius: "20px 20px 20px 20px",
  // borderRadius: "50%",
};
// const KeenSliderSlide = {
//   // bgcolor: "common.compbg",
//   bgcolor: "primary.main",
//   textAlign: "center",
//   justifyContent: "center",
//   alignItems: "center",
//   m: 0,
//   p: 0,
//   border: "0.0px",
//   borderStyle: "solid",
//   width: { xs: "250px", sm: "300px" },
//   height: { xs: "250px", sm: "300px" },
//   // borderRadius: "10px 10px 10px 10px",
//   borderRadius: "50%",
//   // border: 1,
// };
// const KeenSliderSlide3 = {
//   // bgcolor: "common.compbg",
//   bgcolor: "primary.main",
//   justifyContent: "center",
//   alignItems: "center",
//   textAlign: "center",
//   m: 0,
//   p: 0,
//   // py: 1,
//   border: "0.0px",
//   // borderStyle: "solid",
//   // width: "150px",
//   // height: { xs: "250px", sm: "300px" },
//   borderRadius: "20px 20px 20px 20px",
//   // borderRadius: "50%",
// };

export function Overview(props) {
  // const methods = useForm({
  //   shouldUnregister: false,
  //   mode: "onBlur",
  // });
  const locData = useLocation();
  // console.log("locData overview", locData);
  const theme = useTheme();
  useEffect(() => {
    //   // custReset();
    //   // cartReset();
    //   // console.log("Overview:useEffect:custStore", useCustStore.getState());
    //   // console.log("Overview:useEffect:cartStore", useCartStore.getState());
    // if (useCustStore.getState().validatedUser && cart.length < 1) {
    //   refreshCart(useCartStore.getState().ipjson);
    // }
  }, []);

  const cart = useCartStore((state) => state.cart);
  console.log("Overview::cart", cart);

  // const [currentSlide2, setCurrentSlide2] = useState(0);
  // const [loaded2, setLoaded2] = useState(false);
  // const [sliderRef2, instanceRef2] = useKeenSlider(
  //   {
  //     initial: 0,
  //     loop: false,
  //     slideChanged(slider) {
  //       setCurrentSlide2(slider.track.details.rel);
  //     },
  //     created() {
  //       setLoaded2(true);
  //     },
  //     breakpoints: {
  //       "(min-width: 0px)": {
  //         slides: { perView: "1", spacing: 6 },
  //       },
  //       "(min-width: 400px)": {
  //         slides: { perView: "1", spacing: 6 },
  //       },
  //       "(min-width: 820px)": {
  //         slides: { perView: "2", spacing: 6 },
  //       },
  //       "(min-width: 1000px)": {
  //         slides: { perView: "2", spacing: 6 },
  //       },
  //     },
  //     slides: { perView: "2", spacing: 6 },
  //   },
  //   [
  //     (slider) => {
  //       let timeout;
  //       let mouseOver = false;
  //       function clearNextTimeout() {
  //         clearTimeout(timeout);
  //       }
  //       function nextTimeout() {
  //         clearTimeout(timeout);
  //         if (mouseOver) return;
  //         timeout = setTimeout(() => {
  //           // slider.next();
  //         }, 6000);
  //       }
  //       const observer = new ResizeObserver(function () {
  //         slider.update();
  //       });

  //       slider.on("created", () => {
  //         observer.observe(slider.container);
  //       });
  //       slider.on("destroyed", () => {
  //         observer.unobserve(slider.container);
  //       });
  //       slider.on("created", () => {
  //         slider.container.addEventListener("mouseover", () => {
  //           mouseOver = true;
  //           clearNextTimeout();
  //         });
  //         slider.container.addEventListener("mouseout", () => {
  //           mouseOver = false;
  //           nextTimeout();
  //         });
  //         nextTimeout();
  //       });
  //       slider.on("dragStarted", clearNextTimeout);
  //       slider.on("animationEnded", nextTimeout);
  //       slider.on("updated", nextTimeout);
  //     },
  //   ]
  // );

  // const [currentSlide1, setCurrentSlide1] = useState(0);
  // const [loaded1, setLoaded1] = useState(false);
  // const [sliderRef1, instanceRef1] = useKeenSlider(
  //   {
  //     // mode: "free-snap",
  //     loop: true,
  //     initial: 0,

  //     slideChanged(slider) {
  //       setCurrentSlide1(slider.track.details.rel);
  //     },
  //     created() {
  //       setLoaded1(true);
  //     },
  //     breakpoints: {
  //       "(min-width: 0px)": {
  //         slides: { perView: "1", spacing: 2 },
  //       },
  //       "(min-width: 400px)": {
  //         slides: { perView: "1", spacing: 2 },
  //       },
  //       "(min-width: 820px)": {
  //         slides: { perView: "2", spacing: 2 },
  //       },
  //       "(min-width: 1000px)": {
  //         slides: { perView: "3", spacing: 2 },
  //       },
  //     },
  //     slides: { perView: "3", spacing: 2 },
  //     // slides: { origin: "center", perView: "auto", spacing: 20 },

  //     // slides: { perView: "4", spacing: 12 },
  //   },
  //   [
  //     (slider) => {
  //       let timeout;
  //       let mouseOver = false;
  //       function clearNextTimeout() {
  //         clearTimeout(timeout);
  //       }
  //       function nextTimeout() {
  //         clearTimeout(timeout);
  //         if (mouseOver) return;
  //         timeout = setTimeout(() => {
  //           slider.next();
  //         }, 5000);
  //       }
  //       slider.on("created", () => {
  //         slider.container.addEventListener("mouseover", () => {
  //           mouseOver = true;
  //           clearNextTimeout();
  //         });
  //         slider.container.addEventListener("mouseout", () => {
  //           mouseOver = false;
  //           nextTimeout();
  //         });
  //         nextTimeout();
  //       });
  //       const observer = new ResizeObserver(function () {
  //         slider.update();
  //       });

  //       slider.on("created", () => {
  //         observer.observe(slider.container);
  //       });
  //       slider.on("destroyed", () => {
  //         observer.unobserve(slider.container);
  //       });
  //       slider.on("dragStarted", clearNextTimeout);
  //       slider.on("animationEnded", nextTimeout);
  //       slider.on("updated", nextTimeout);
  //     },
  //   ]
  // );

  // const [currentSlide2, setCurrentSlide2] = useState(0);
  // const [loaded2, setLoaded2] = useState(false);
  // const [sliderRef2, instanceRef2] = useKeenSlider(
  //   {
  //     initial: 0,
  //     loop: false,
  //     slideChanged(slider) {
  //       setCurrentSlide2(slider.track.details.rel);
  //     },
  //     created() {
  //       setLoaded2(true);
  //     },
  //     breakpoints: {
  //       "(min-width: 0px)": {
  //         slides: { perView: "1", spacing: 2 },
  //       },
  //       "(min-width: 400px)": {
  //         slides: { perView: "1", spacing: 2 },
  //       },
  //       "(min-width: 820px)": {
  //         slides: { perView: "2", spacing: 2 },
  //       },
  //       "(min-width: 1000px)": {
  //         slides: { perView: "2", spacing: 2 },
  //       },
  //     },
  //     slides: { perView: "2", spacing: 2 },
  //   },
  //   [
  //     (slider) => {
  //       let timeout;
  //       let mouseOver = false;
  //       function clearNextTimeout() {
  //         clearTimeout(timeout);
  //       }
  //       function nextTimeout() {
  //         clearTimeout(timeout);
  //         if (mouseOver) return;
  //         timeout = setTimeout(() => {
  //           // slider.next();
  //         }, 6000);
  //       }
  //       slider.on("created", () => {
  //         slider.container.addEventListener("mouseover", () => {
  //           mouseOver = true;
  //           clearNextTimeout();
  //         });
  //         slider.container.addEventListener("mouseout", () => {
  //           mouseOver = false;
  //           nextTimeout();
  //         });
  //         nextTimeout();
  //       });
  //       slider.on("dragStarted", clearNextTimeout);
  //       slider.on("animationEnded", nextTimeout);
  //       slider.on("updated", nextTimeout);
  //     },
  //   ]
  // );

  // const [currentSlide3, setCurrentSlide3] = useState(0);
  // const [loaded3, setLoaded3] = useState(false);
  // const [sliderRef3, instanceRef3] = useKeenSlider(
  //   {
  //     loop: false,
  //     initial: 0,
  //     slideChanged(slider) {
  //       setCurrentSlide3(slider.track.details.rel);
  //     },
  //     created() {
  //       setLoaded3(true);
  //     },
  //     breakpoints: {
  //       "(min-width: 0px)": {
  //         slides: { perView: "1", spacing: 6 },
  //       },
  //       "(min-width: 400px)": {
  //         slides: { perView: "1", spacing: 6 },
  //       },
  //       "(min-width: 820px)": {
  //         slides: { perView: "2", spacing: 6 },
  //       },
  //       "(min-width: 1000px)": {
  //         slides: { perView: "2", spacing: 6 },
  //       },
  //     },
  //     slides: { perView: "2", spacing: 6 },
  //   },
  //   [
  //     (slider) => {
  //       let timeout;
  //       let mouseOver = false;
  //       function clearNextTimeout() {
  //         clearTimeout(timeout);
  //       }
  //       function nextTimeout() {
  //         clearTimeout(timeout);
  //         if (mouseOver) return;
  //         timeout = setTimeout(() => {
  //           // slider.next();
  //         }, 6000);
  //       }
  //       slider.on("created", () => {
  //         slider.container.addEventListener("mouseover", () => {
  //           mouseOver = true;
  //           clearNextTimeout();
  //         });
  //         slider.container.addEventListener("mouseout", () => {
  //           mouseOver = false;
  //           nextTimeout();
  //         });
  //         nextTimeout();
  //       });
  //       const observer = new ResizeObserver(function () {
  //         slider.update();
  //       });

  //       slider.on("created", () => {
  //         observer.observe(slider.container);
  //       });
  //       slider.on("destroyed", () => {
  //         observer.unobserve(slider.container);
  //       });
  //       slider.on("dragStarted", clearNextTimeout);
  //       slider.on("animationEnded", nextTimeout);
  //       slider.on("updated", nextTimeout);
  //     },
  //   ]
  // );

  // const [expanded, setExpanded] = React.useState(-1);
  // const [expanded, setExpanded] = React.useState(false);
  // use following code if need to expand one at a time
  // const handleExpandClick = (i) => {
  //   setExpanded(expanded === i ? -1 : i);
  // };
  // expand all option
  // const useScroll = () => {
  //   const csRef = useRef(null);
  //   const executeScrollCS = () =>
  //     csRef.current.scrollIntoView(
  //       // true,
  //       {
  //         behavior: "smooth",
  //         block: "center",
  //       }
  //     );

  //   return [executeScrollCS, csRef];
  // };
  // const handleExpandClick = () => {
  //   if (expanded) {
  //     executeScrollCS();
  //   }
  //   setExpanded(!expanded);
  // };

  //keen slider
  const { tiles, slides } = useRouteLoaderData("root");
  // const { tiles, slides } = useLoaderData();
  // const coreITcart = cart.filter((cartItem) => cartItem.item_id === 3);
  const coreITcart = cart.filter((cartItem) => cartItem.item_type === "P");
  // const includes = slides.data.filter(
  //   (included) => included.ui_data.pricetype.toLowerCase() == "included"
  // );

  console.log("Overview::tiles:", tiles);
  console.log("Overview::slides:", slides);
  // console.log("instanceRef2:", instanceRef2);
  // console.log("sliderRef2:", sliderRef2);
  // console.log("instanceRef2:", instanceRef2.current?.options?.slides.perView);
  // const {res, res2, res3} = useLoaderData();
  // console.log("res", res)
  // console.log(tiles);
  const indSize = 25;
  // console.log("Overview:custStore", useCustStore.getState());
  // console.log("Overview:cartStore", useCartStore.getState());
  // const [executeScrollCS, csRef] = useScroll();
  // useEffect(executeScroll, []); // Scroll on mount
  const isMobile = useMediaQuery("(Max-width: 600px)");
  return (
    <Suspense fallback={<CircularProgress size={25} color="secondary" />}>
      <Stack
        direction="column"
        sx={
          {
            // border: 0,
            // borderColor: "primary.main",
            // m: 0,
            // py: 0,
            // px: 0,
          }
        }
      >
        <StyledSection bgcolor="primary.main" appendSx={{ my: 0 }} secVm="0">
          <Stack
            direction={{ xs: "column", sm: "row" }}
            sx={{ m: 0, p: 0, mt: 0 }}
          >
            <Stack
              sx={{
                ...sharedStyle,
              }}
            >
              <Box
                sx={{
                  typography: "h4",
                  // color: "common.black",
                  bgcolor: "primary.main",
                  color: "textPrimary",
                  fontWeight: "bold",
                  m: 0,
                  p: 0,
                }}
              >
                All inclusive, white-glove
              </Box>

              <Box
                sx={{
                  typography: "h3",

                  color: "textSecondary",
                  // fontWeight: theme.typography.fontWeightBold,
                  fontWeight: "bold",
                  m: 0,
                  p: 0,
                  // mt: 1,
                }}
              >
                Managed Operations
              </Box>

              <Box
                sx={{
                  typography: "h4",
                  // color: "common.black",
                  color: "textPrimary",
                  fontWeight: "bold",
                  m: 0,
                  p: 0,
                  // mt: 2,
                }}
              >
                Core IT & communications
              </Box>
              <Box
                sx={{
                  typography: "h3",
                  // color: "common.black",
                  color: "textSecondary",
                  fontWeight: "bold",
                  m: 0,
                  p: 0,
                  // mt: 1,
                }}
              >
                {coreITcart[0] && (
                  <>
                    @{coreITcart[0].price_curr}/{coreITcart[0].disp_props.unit}/
                    {coreITcart[0].disp_props.freq}
                  </>
                )}
              </Box>
            </Stack>
            <Stack
              sx={{
                ...lottieStyle,
              }}
            >
              <Lottie
                options={lottieOptions}
                // height={300}
                // width={300}
                // height={isMobile ? {"150"} : {"300"}}
                // width={isMobile ? "150" : "300"}
                // isStopped={true}
                // isPaused={false}
                loop={true}
              />
            </Stack>
          </Stack>
        </StyledSection>
        {/* <Box sx={{ mx: 0, my: 4, p: 0 }}></Box> */}
        {/* <div ref={csRef}> </div> */}
        <StyledSection
          // bgcolor="common.compbg"
          bgcolor="primary.main"
          // appendSx={{ my: 0 }}
          // secVm="0"
          // appendSx={{ borderRadius: "10%" }}
        >
          <Tiles
            // slides={slides.data}
            tiles={tiles}
            // cart={cart}
            // scrollInViewFn={scrollInViewFn}
            mode="W"
          />
          {/* {tiles.data && tiles.data.length > 0 ? ( */}
          {/* {tiles && tiles.length > 0 ? (
            <Stack
              direction="column"
              sx={{
                ...SlideStack,
                p: 0,
                mt: -3,
                // bgcolor: "common.compbg",
                bgcolor: "primary.main",
                borderRadius: "50%",
              }}
            >
              <Stack
                sx={{
                  m: 0,
                  p: 0,
                  // bgcolor: "common.compbg",
                  // borderRadius: "50%",
                  // border: 1,
                }}
                ref={sliderRef1}
                className="keen-slider"
                direction="row"
              >
                
                {tiles.map((tile, j) => (
                  <Stack
                    key={j}
                    className="keen-slider__slide"
                    sx={{
                      ...KeenSliderSlide,
                      // width: { xs: "250px", sm: "310px" },
                      // height: { xs: "250px", sm: "310px" },

                      borderRadius: "50%",
                      // m: 0,
                      // p: 0,
                      // border: 1,
                      // bgcolor: "common.compbg",
                    }}
                  >
                    <Stack
                      sx={{
                        ...KeenSliderSlide,
                        bgcolor: "common.compbg",
                        borderRadius: "50%",
                        border: "solid",
                        borderWidth: "0px",
                        borderColor: "primary.main",
                      }}
                    >
                      <Stack
                        sx={{
                          ...KeenSliderSlide,
                          bgcolor: "common.compbg",
                          p: 2,
                          mx: 0,

                          my: 1,
                          border: "solid",
                          borderWidth: "5px",
                          borderColor: "primary.main",
                        }}
                      >
                        <Typography
                          variant={"h5"}
                          display="inline"
                          sx={{
                            color: "textSecondary",
                            // color: "textPrimary",
                            wordBreak: "break-word",
                            fontWeight: "bold",
                            whiteSpace: "pre-line",
                          }}
                        >
                          {tile.ui_data.title}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                ))}
              </Stack>

              <Stack
                direction="row"
                sx={{ alignItems: "center", justifyContent: "center", mt: 1 }}
              >
                {loaded1 && instanceRef1.current && (
                  <KeenSliderArrows
                    currentSlide={currentSlide1}
                    sliderInstance={instanceRef1}
                    arrowPos="bottom"
                  />

                  // <>
                  //       <Arrow
                  //         left
                  //         onClick={(e) =>
                  //           e.stopPropagation() || instanceRef1.current?.prev()
                  //         }
                  //         disabled={currentSlide1 === 0}
                  //       />

                  //       <Arrow
                  //         onClick={(e) =>
                  //           e.stopPropagation() || instanceRef1.current?.next()
                  //         }
                  //         disabled={
                  //           currentSlide1 ===
                  //           instanceRef1.current.track.details.slides.length -
                  //             instanceRef1.current.options.slides.perView
                  //         }
                  //       />
                  //     </>
                )}
              </Stack>
            </Stack>
          ) : (
            <>
              <CircularProgress size={indSize} color="secondary" />
            </>
          )} */}
        </StyledSection>

        {/* Features Slides */}

        <OverviewCard
          // slides={slides.data}
          slides={slides}
          // cart={cart}
          // scrollInViewFn={scrollInViewFn}
          mode="W"
        />
        {/* <Box sx={{ mx: 0, my: 4, p: 0 }}></Box> */}

        <StyledSection headerText="Add-Ons" bgcolor="common.compbg">
          <Stack
            direction="column"
            sx={{
              // border: 1,
              mb: 0,
              my: 1,

              p: 0,
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                typography: "h6",
                color: "textSecondary",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              Customize with Add-On's
            </Box>
          </Stack>
          {/* <VerticalAddons slides={slides.data} /> */}
          <VerticalAddons slides={slides} />
        </StyledSection>

        {/* <Box sx={{ mx: 0, my: 4, p: 0 }}></Box> */}
        {/* <Stack sx={{ ...SlideStack }}>
          <StyledHeader text="Our Customers Love It!" />
        </Stack> */}

        <StyledSection
          headerText="Our Customers Love it!"
          bgcolor="common.compbg"
          // bgcolor="primary.main"
          // appendSx={{ my: 0 }}
          // secVm="0"
        >
          <Reviews reviews={reviews} />
        </StyledSection>
        {/* <Box sx={{ mx: 0, my: 2, p: 0 }}></Box> */}
      </Stack>
    </Suspense>
  );
}

export async function OverviewLoader() {
  console.log("OverviewLoader");
  try {
    const tileData = await callUICrud("tile", "R", {
      svc_pkg_id: useTokenStore.getState().svc_pkg_id,
      user_id: useCustStore.getState().currentUser.id,
      grp_id: useCustStore.getState().currentUser.grp_id,
    });
    console.log("tile.loader:", tileData);

    const slideData = await callUICrud("slide", "R", {
      svc_pkg_id: useTokenStore.getState().svc_pkg_id,
      user_id: useCustStore.getState().currentUser.id,
      grp_id: useCustStore.getState().currentUser.grp_id,
    });
    console.log("slide.loader:", slideData);

    return [tileData, slideData];
  } catch (e) {
    throw e;
  }
}

function Arrow2(props) {
  const disabeld = props.disabled ? " arrow--disabled" : "";
  return (
    <svg
      onClick={props.onClick}
      className={`arrow ${
        props.left ? "arrow--left" : "arrow--right"
      } ${disabeld}`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30 30"
    >
      {props.left && (
        <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
      )}
      {!props.left && (
        <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
      )}
    </svg>
  );
}

export function Arrow(props) {
  // const disabeld = props.disabled ? " arrow--disabled" : "";
  return (
    <>
      {props.left && (
        // <ArrowBackIosNewOutlinedIcon

        // <ArrowCircleLeftOutlinedIcon
        //   color={props.disabled ? "disabled" : "secondary"}
        //   onClick={props.onClick}
        //   fontSize="large"
        //   sx={{
        //     position: "absolute",
        //     justifyContent: "center",
        //     alignItems: "center",
        //     top: "35%",
        //     left: "-0px",
        //     m: 0,
        //   }}
        // />
        <Icon
          type="ArrowCircleLeftOutlinedIcon"
          color={props.disabled ? "disabled" : "secondary"}
          onClick={props.onClick}
          fontSize="large"
          // sx={{
          //   position: "absolute",
          //   justifyContent: "center",
          //   alignItems: "center",
          //   top: "35%",
          //   left: "-0px",
          //   m: 0,
          // }}
          sx={{
            position: "absolute",
            justifyContent: "center",
            alignItems: "center",
            top: "130px",
            left: "-12px",
            m: 0,
            // mr: 2,
          }}
        />
      )}

      {!props.left && (
        <Icon
          type="ArrowCircleRightOutlinedIcon"
          color={props.disabled ? "disabled" : "secondary"}
          onClick={props.onClick}
          fontSize="large"
          // sx={{
          //   position: "absolute",
          //   justifyContent: "center",
          //   alignItems: "center",
          //   top: "35%",
          //   right: "-0px",
          //   m: 0,
          // }}
          sx={{
            position: "absolute",
            justifyContent: "center",
            alignItems: "center",
            top: "130px",
            right: "-12px",
            m: 0,
            // ml: 2,
          }}
        />
        //   <ArrowCircleRightOutlinedIcon
        //   color={props.disabled ? "disabled" : "secondary"}
        //   onClick={props.onClick}
        //   fontSize="large"
        //   sx={{
        //     position: "absolute",
        //     justifyContent: "center",
        //     alignItems: "center",
        //     top: "35%",
        //     right: "-0px",
        //     m: 0,
        //   }}
        // />
      )}
    </>
  );
}

function ArrowBottom(props) {
  // const disabeld = props.disabled ? " arrow--disabled" : "";
  return (
    <>
      {props.left && (
        // <ArrowBackIosNewOutlinedIcon
        <Icon
          type="ArrowCircleLeftOutlinedIcon"
          color={props.disabled ? "disabled" : "secondary"}
          onClick={props.onClick}
          fontSize="large"
          sx={{
            // position: "absolute",
            justifyContent: "center",
            alignItems: "center",
            // top: "35%",
            // left: "-0px",
            m: 0,
          }}
        />
      )}

      {!props.left && (
        // <ArrowForwardIosIcon
        <Icon
          type="ArrowCircleRightOutlinedIcon"
          color={props.disabled ? "disabled" : "secondary"}
          onClick={props.onClick}
          fontSize="large"
          sx={{
            // position: "absolute",
            justifyContent: "center",
            alignItems: "center",
            // top: "35%",
            // right: "-0px",
            m: 0,
          }}
        />
      )}
    </>
  );
}

export function KeenSliderArrows(props) {
  return (
    <>
      {props.arrowPos !== "bottom" ? (
        <>
          <Arrow
            left
            onClick={(e) =>
              e.stopPropagation() || props.sliderInstance.current?.prev()
            }
            disabled={props.currentSlide === 0}
            arrowPos={props.arrowPos}
          />
          <Arrow
            onClick={(e) =>
              e.stopPropagation() || props.sliderInstance.current?.next()
            }
            disabled={
              props.currentSlide ===
              props.sliderInstance.current.track.details.slides.length -
                props.sliderInstance.current?.options?.slides.perView
            }
          />
        </>
      ) : (
        <>
          <ArrowBottom
            left
            onClick={(e) =>
              e.stopPropagation() || props.sliderInstance.current?.prev()
            }
            disabled={props.currentSlide === 0}
            arrowPos={props.arrowPos}
          />
          <ArrowBottom
            onClick={(e) =>
              e.stopPropagation() || props.sliderInstance.current?.next()
            }
            disabled={
              props.currentSlide ===
              props.sliderInstance.current.track.details.slides.length -
                props.sliderInstance.current?.options?.slides.perView
            }
          />
        </>
      )}
    </>
  );
}
