import { createContext, useState, useMemo } from "react";
import { createTheme, responsiveFontSizes } from "@mui/material/styles";
// import { purple, green } from "@mui/material/colors";
import randomColor from "randomcolor";

import "@fontsource/baloo-bhai-2/400.css";
import "@fontsource/baloo-bhai-2/500.css";
import "@fontsource/baloo-bhai-2/600.css";
import "@fontsource/baloo-bhai-2/700.css";
import "@fontsource/baloo-bhai-2/800.css";

// import "@fontsource/lextrall/400.css";
// import "@fontsource/lextrall/500.css";
// import "@fontsource/lextrall/600.css";
// import "@fontsource/lextrall/700.css";
// import "@fontsource/lextrall/800.css";
// import "@fontsource-variable/baloo-bhaijaan-2";

// import "@fontsource-variable/roboto-mono";

// // const primary = purple[500];
// // const secondary = green[500];

// // var colors = randomColor({ count: 3, luminosity: "", hue: "" });
// // const primary = colors[1];
// // const secondary = colors[2];
// // const background = colors[3];

// // const primary = "#d4aa00";
// // const secondary = "#f5f5f5";
// // const background = "#d4aa00"; //yelow gold

// const primary = "#ffffff";

// // const primary = "hsla(0,0%,96%,1)";

// // const primary = "hsl(43, 74%, 49%,.1)";

// // const primary = "hsl(43, 74%, 49%,1)";
// // const secondary = "#d4aa00";
// // const secondary = "#DAA520";

// const secondary = "hsl(43, 74%, 49%,1)";
// // const secondary = "hsl(50.6°, 100%, 50%)";
// // const secondary = "#1f1f00";

// // const secondary = "#000080"; // Navy blue
// // const secondary = "hsla(0,0%,98%,1)";
// const secondary7 = "hsl(43, 74%, 49%,.7)";
// const secondary5 = "hsl(43, 74%, 49%,.5)";
// const secondary2 = "hsl(43, 74%, 49%,.1)";
// // const secondary2 = "hsla(0,0%,96%,1)";
// // const secondary = "#023504";

// // const background = "#f5f5f5"; //yelow gold
// // const background = primary; //yelow gold
// const white = "#ffffff";

// const background = white;
// const borderColor = secondary;

// // const primary = "#b3294e";
// // const secondary = "#4829B2";

// // const black = "#343a40";

// const black = "#1f1f00";
// const darkBlack = "rgb(36, 40, 44)";
// // const background = "#f5f5f5";

// // const white = "#f5f5f5";
// // const white = "#ffffff";

// const warningLight = "rgba(253, 200, 69, .3)";
// const warningMain = "rgba(253, 200, 69, .5)";
// const warningDark = "rgba(253, 200, 69, .6)";
// // border
// const borderWidth = 1;
// // const borderColor = "rgba(0, 0, 0, 0.13)";

// // breakpoints
// const xl = 1536;
// const lg = 1080;
// const md = 800;
// const sm = 600;
// const xs = 0;
// const compbg = "#f5f5f5";
// color design tokens export
// export const tokens = (mode) => ({
//   ...(mode === "dark"
//     ? {
//         grey: {
//           100: "#e0e0e0",
//           200: "#c2c2c2",
//           300: "#a3a3a3",
//           400: "#858585",
//           500: "#666666",
//           600: "#525252",
//           700: "#3d3d3d",
//           800: "#292929",
//           900: "#141414",
//         },
//         primary: {
//           0: "#ffffff",
//           100: "#d0d1d5",
//           200: "#a1a4ab",
//           300: "#727681",
//           400: "#1F2A40",
//           500: "#141b2d",
//           600: "#101624",
//           700: "#0c101b",
//           800: "#080b12",
//           900: "#040509",
//         },
//         greenAccent: {
//           100: "#dbf5ee",
//           200: "#b7ebde",
//           300: "#94e2cd",
//           400: "#70d8bd",
//           500: "#4cceac",
//           600: "#3da58a",
//           700: "#2e7c67",
//           800: "#1e5245",
//           900: "#0f2922",
//         },
//         redAccent: {
//           100: "#f8dcdb",
//           200: "#f1b9b7",
//           300: "#e99592",
//           400: "#e2726e",
//           500: "#db4f4a",
//           600: "#af3f3b",
//           700: "#832f2c",
//           800: "#58201e",
//           900: "#2c100f",
//         },
//         blueAccent: {
//           100: "#e1e2fe",
//           200: "#c3c6fd",
//           300: "#a4a9fc",
//           400: "#868dfb",
//           500: "#6870fa",
//           600: "#535ac8",
//           700: "#3e4396",
//           800: "#2a2d64",
//           900: "#151632",
//         },
//         secondary: {
//           // yellow
//           50: "#f0f0f0", // manually adjusted
//           100: "#fff6e0",
//           200: "#ffedc2",
//           300: "#ffe3a3",
//           400: "#ffda85",
//           500: "#ffd166",
//           600: "#cca752",
//           700: "#997d3d",
//           800: "#665429",
//           900: "#332a14",
//         },
//       }
//     : {
//         grey: {
//           100: "#141414",
//           200: "#292929",
//           300: "#3d3d3d",
//           400: "#525252",
//           500: "#666666",
//           600: "#858585",
//           700: "#a3a3a3",
//           800: "#c2c2c2",
//           900: "#e0e0e0",
//         },
//         primary: {
//           0: "#ffffff",
//           100: "#040509",
//           200: "#080b12",
//           300: "#0c101b",
//           400: "#f2f0f0", // manually changed
//           500: "#141b2d",
//           600: "#1F2A40",
//           700: "#727681",
//           800: "#a1a4ab",
//           900: "#d0d1d5",
//         },
//         greenAccent: {
//           100: "#0f2922",
//           200: "#1e5245",
//           300: "#2e7c67",
//           400: "#3da58a",
//           500: "#4cceac",
//           600: "#70d8bd",
//           700: "#94e2cd",
//           800: "#b7ebde",
//           900: "#dbf5ee",
//         },
//         redAccent: {
//           100: "#2c100f",
//           200: "#58201e",
//           300: "#832f2c",
//           400: "#af3f3b",
//           500: "#db4f4a",
//           600: "#e2726e",
//           700: "#e99592",
//           800: "#f1b9b7",
//           900: "#f8dcdb",
//         },
//         blueAccent: {
//           100: "#151632",
//           200: "#2a2d64",
//           300: "#3e4396",
//           400: "#535ac8",
//           500: "#6870fa",
//           600: "#868dfb",
//           700: "#a4a9fc",
//           800: "#c3c6fd",
//           900: "#e1e2fe",
//         },
//         secondary: {
//           // yellow
//           50: "#f0f0f0", // manually adjusted
//           100: "#fff6e0",
//           200: "#ffedc2",
//           300: "#ffe3a3",
//           400: "#ffda85",
//           500: "#ffd166",
//           600: "#cca752",
//           700: "#997d3d",
//           800: "#665429",
//           900: "#332a14",
//         },
//       }),
// });
export const tokensDark = {
  grey: {
    0: "#ffffff", // manually adjusted
    5: "#F5F5F5", // added to match prior grey
    10: "#f6f6f6", // manually adjusted
    50: "#f0f0f0", // manually adjusted
    100: "#e0e0e0",
    200: "#c2c2c2",
    300: "#a3a3a3",
    400: "#858585",
    500: "#666666",
    600: "#525252",
    700: "#3d3d3d",
    800: "#292929",
    900: "#141414",
    1000: "#000000", // manually adjusted
  },
  primary: {
    // blue
    100: "#d3d4de",
    200: "#a6a9be",
    300: "#7a7f9d",
    400: "#4d547d",
    500: "#21295c",
    600: "#191F45", // manually adjusted
    700: "#141937",
    800: "#0d1025",
    900: "#0d1025",
  },
  secondary: {
    // yellow
    50: "#f0f0f0", // manually adjusted
    100: "#fff6e0",
    200: "#ffedc2",
    300: "#ffe3a3",
    400: "#ffda85",
    500: "#ffd166",
    // 600: "#cca752",
    // 600: "hsl(43, 74%, 49%, 1)",
    600: "#D9A520",
    700: "#997d3d",
    800: "#665429",
    900: "#332a14",
  },
  greenAccent: {
    100: "#dbf5ee",
    200: "#b7ebde",
    300: "#94e2cd",
    400: "#70d8bd",
    500: "#4cceac",
    600: "#3da58a",
    700: "#2e7c67",
    800: "#1e5245",
    900: "#0f2922",
  },
};

// function that reverses the color palette
// function reverseTokens(tokensDark) {
//   const reversedTokens = {};
//   Object.entries(tokensDark).forEach(([key, val]) => {
//     const keys = Object.keys(val);
//     const values = Object.values(val);
//     const length = keys.length;
//     const reversedObj = {};
//     // console.log("keys", keys);
//     // console.log("values", values);
//     for (let i = 0; i < length; i++) {
//       reversedObj[keys[i]] = values[length - i - 1];
//       // console.log("reversedObj", reversedObj);
//     }
//     reversedTokens[key] = reversedObj;
//   });
//   // console.log("reversedTokens", reversedTokens);
//   return reversedTokens;
// }
// export const tokensLight = reverseTokens(tokensDark);

// spacing
const spacing = 8;
// mui theme settings
export const themeSettings = (mode) => {
  // const colors = tokens(mode);
  const primary = "#ffffff";
  // const primary = "#fdf5e6";
  // const primary = "#FAFDFF";
  // const primary = "hsl(43, 74%, 49%,0.05)";

  // const primary_dark = "#191F45";
  // const primary_dark = "#141937";
  const primary_dark = "#0D1025";

  const secondary = "hsl(43, 74%, 49%,1)";
  // const secondary = "hsl(47, 33%, 50%,1)";
  const secondary7 = "hsl(43, 74%, 49%,.7)";
  const secondary5 = "hsl(43, 74%, 49%,.5)";
  const secondary2 = "hsl(43, 74%, 49%,.1)";
  const background = primary;
  const secondary_dark = secondary5;
  // const background_dark = "#21295c";
  const background_dark = primary_dark;

  // const background_alt = "#f0f0f0";
  const background_alt = secondary2;
  const background_dark_alt = "#21295c";

  const white = "#ffffff";

  const borderColor = secondary;
  const black = "#1f1f00";
  const darkBlack = "rgb(36, 40, 44)";
  const warningLight = "rgba(253, 200, 69, .3)";
  const warningMain = "rgba(253, 200, 69, .5)";
  const warningDark = "rgba(253, 200, 69, .6)";
  // border
  const borderWidth = 1;
  // breakpoints
  const xl = 1536;
  const lg = 1080;
  const md = 800;
  const sm = 600;
  const xs = 0;

  const compbg_dark1 = "#d3d4de";
  const compbg_dark2 = "#A6A9BE";
  const compbg_dark3 = "#191F45";
  // const compbg = "#ffffff";
  // const compbg = "#7a7f9d";
  // const compbg = "hsl(43, 74%, 49%,0.2)";
  // const compbg = "#e9f6ef";
  const compbg = "#f5f5f5";
  // const compbg = "#fdf5e6";

  const compbg_dark = compbg_dark3;
  const textPrimary = black;
  const textAlt = primary;
  const textPrimary_dark = "#A6A9BE";
  const textAlt_dark = primary_dark;

  return {
    palette: {
      mode: mode,
      ...(mode === "dark"
        ? {
            // palette values for dark mode
            primary: {
              // ...tokensLight.primary,
              // main: tokensDark.primary[600],
              main: primary_dark,
              // main: tokensDark.primary[400],
              // light: tokensDark.primary[400],
            },
            secondary: {
              // ...tokensDark.secondary,
              main: secondary_dark,
              // main: tokensDark.secondary[300],
            },
            common: {
              compbg: compbg_dark,

              black,
              white,
              darkBlack,
              secondary7,
              secondary5,
              secondary2,
            },
            warning: {
              light: warningLight,
              main: warningMain,
              dark: warningDark,
            },
            textPrimary: textPrimary_dark,
            textSecondary: secondary_dark,
            textAlt: textAlt_dark,
            // Used to shift a color's luminance by approximately
            // two indexes within its tonal palette.
            // E.g., shift from Red 500 to Red 300 or Red 700.
            tonalOffset: 0.2,
            neutral: {
              ...tokensDark.grey,
              main: tokensDark.grey[500],
            },
            background: {
              // default: tokensDark.primary[600],
              default: background_dark,
              // alt: tokensDark.primary[500],
              alt: background_dark_alt,
            },
            accent: {
              light: tokensDark.greenAccent[600],
              dark: tokensDark.greenAccent[700],
            },
            spacing,
          }
        : {
            // palette values for light mode

            primary: {
              // ...tokensLight.primary,

              main: primary,
              // main: tokensDark.grey[50],
              // main: tokensDark.grey[0],

              // light: tokensDark.grey[100],
            },
            secondary: {
              // ...tokensLight.secondary,
              // main: tokensDark.secondary[600],
              main: secondary,
              // light: tokensDark.secondary[700],
            },
            common: {
              compbg: compbg,
              black,
              white,
              darkBlack,
              secondary7,
              secondary5,
              secondary2,
            },
            warning: {
              light: warningLight,
              main: warningMain,
              dark: warningDark,
            },
            textPrimary: textPrimary,
            textSecondary: secondary,
            textAlt: textAlt,
            // Used to shift a color's luminance by approximately
            // two indexes within its tonal palette.
            // E.g., shift from Red 500 to Red 300 or Red 700.
            tonalOffset: 0.2,
            neutral: {
              // ...tokensLight.grey,
              main: tokensDark.grey[500],
            },
            background: {
              // default: tokensDark.grey[10],
              // default: tokensDark.grey[0],
              default: background,
              // alt: tokensDark.grey[50],
              alt: background_alt,
            },
            accent: {
              light: tokensDark.greenAccent[600],
              dark: tokensDark.greenAccent[700],
            },
            spacing,
          }),
    },
    // typography: {
    //   fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
    //   fontSize: 12,
    //   h1: {
    //     fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
    //     fontSize: 40,
    //   },
    //   h2: {
    //     fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
    //     fontSize: 32,
    //   },
    //   h3: {
    //     fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
    //     fontSize: 24,
    //   },
    //   h4: {
    //     fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
    //     fontSize: 20,
    //   },
    //   h5: {
    //     fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
    //     fontSize: 16,
    //   },
    //   h6: {
    //     fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
    //     fontSize: 14,
    //   },
    // },
    breakpoints: {
      //   // Define custom breakpoint values.
      //   // These will apply to Material-UI components that use responsive
      //   // breakpoints, such as `Grid` and `Hidden`. You can also use the
      //   // theme breakpoint functions `up`, `down`, and `between` to create
      //   // media queries for these breakpoints
      values: {
        xl: xl,
        lg: lg,
        md: md,
        sm: sm,
        xs: xs,
        // Your custom breakpoints
        // laptop: true;
        // tablet: true;
        // mobile: true;
        // desktop: true;
        // Remove default breakpoints
        // xs: false;
        // sm: false;
        // md: false;
        // lg: false;
        // xl: false;
      },
    },
    border: {
      borderColor: borderColor,
      borderWidth: borderWidth,
    },
    overrides: {
      // MuiSvgIcon: {
      //   colorPrimary: {
      //     color: ["#625b5b", "!important"],
      //   },
      //   colorSecondary: {
      //     color: ["#d5d7d8", "!important"],
      //   },
      // },
      // MuiTab: {
      //   root: {
      //     backgroundColor: secondary,
      //     alignLeft: "true",
      //     // backgroundColor: primary,--
      //     "&$selected": {
      //       // opacity: 0.3,
      //       // backgroundColor: secondary5,
      //       // backgroundColor: secondary, --
      //       // color: primary,
      //       //textColor: primary,
      //       //opacity: 0.5,
      //       //border: `1px solid ${secondary}`,
      //       textAlign: "left",
      //       alignLeft: "true",
      //       marginright: "auto",
      //     },
      //     "&:hover": {
      //       opacity: 0.5,
      //     },
      //   },
      // },
      // selected: {},
      // MuiTabs: {
      //   root: {
      //     //width: "100%",
      //     //height: "100%",
      //   },
      // },
      MuiExpansionPanel: {
        root: {
          position: "static",
        },
      },
      MuiTableCell: {
        root: {
          paddingLeft: spacing * 2,
          paddingRight: spacing * 2,
          borderBottom: `${borderWidth}px solid ${borderColor}`,
          [`@media (max-width:  ${sm}px)`]: {
            paddingLeft: spacing,
            paddingRight: spacing,
          },
        },
      },
      MuiDivider: {
        root: {
          backgroundColor: borderColor,
          height: borderWidth,
        },
      },
      MuiPrivateNotchedOutline: {
        root: {
          borderWidth: borderWidth,
        },
      },
      MuiListItem: {
        divider: {
          borderBottom: `${borderWidth}px solid ${borderColor}`,
        },
      },
      MuiDialog: {
        paper: {
          width: "100%",
          maxWidth: 430,
          marginLeft: spacing,
          marginRight: spacing,
        },
      },
      MuiTooltip: {
        tooltip: {
          backgroundColor: darkBlack,
        },
      },
      MuiExpansionPanelDetails: {
        root: {
          [`@media (max-width:  ${sm}px)`]: {
            paddingLeft: spacing,
            paddingRight: spacing,
          },
        },
      },
    },
    typography: {
      // fontFamily: "'Roboto Mono Variable',  sans-serif",
      // fontFamily: "'Nunito Sans', 'Arial', 'sans-serif'",
      // fontFamily: "'Chilanka', 'cursive', 'sans-serif'",
      fontFamily: "'Baloo Bhai 2', 'cursive', 'Roboto', 'sans-serif'",
      // fontFamily: "'Lextrall', sans-serif",
      // fontFamily: "'Baloo Bhaijaan 2 Variable', sans-serif",

      useNextVariants: true,
    },
  };
};

// spacing
// const spacing = 8;
// let theme = createTheme({
//   // shadows: ["none"],
//   palette: {
//     primary: { main: primary },
//     secondary: { main: secondary },
//     common: {
//       compbg,
//       black,
//       white,
//       darkBlack,
//       secondary7,
//       secondary5,
//       secondary2,
//     },
//     warning: {
//       light: warningLight,
//       main: warningMain,
//       dark: warningDark,
//     },
//     textPrimary: black,
//     textSecondary: white,
//     // Used to shift a color's luminance by approximately
//     // two indexes within its tonal palette.
//     // E.g., shift from Red 500 to Red 300 or Red 700.
//     tonalOffset: 0.2,
//     background: {
//       default: background,
//     },
//     spacing,
//   },
//   breakpoints: {
//     //   // Define custom breakpoint values.
//     //   // These will apply to Material-UI components that use responsive
//     //   // breakpoints, such as `Grid` and `Hidden`. You can also use the
//     //   // theme breakpoint functions `up`, `down`, and `between` to create
//     //   // media queries for these breakpoints
//     values: {
//       xl: xl,
//       lg: lg,
//       md: md,
//       sm: sm,
//       xs: xs,
//       // Your custom breakpoints
//       // laptop: true;
//       // tablet: true;
//       // mobile: true;
//       // desktop: true;
//       // Remove default breakpoints
//       // xs: false;
//       // sm: false;
//       // md: false;
//       // lg: false;
//       // xl: false;
//     },
//   },
//   border: {
//     borderColor: borderColor,
//     borderWidth: borderWidth,
//   },
//   overrides: {
//     // MuiSvgIcon: {
//     //   colorPrimary: {
//     //     color: ["#625b5b", "!important"],
//     //   },
//     //   colorSecondary: {
//     //     color: ["#d5d7d8", "!important"],
//     //   },
//     // },
//     // MuiTab: {
//     //   root: {
//     //     backgroundColor: secondary,
//     //     alignLeft: "true",
//     //     // backgroundColor: primary,--
//     //     "&$selected": {
//     //       // opacity: 0.3,
//     //       // backgroundColor: secondary5,
//     //       // backgroundColor: secondary, --
//     //       // color: primary,
//     //       //textColor: primary,
//     //       //opacity: 0.5,
//     //       //border: `1px solid ${secondary}`,
//     //       textAlign: "left",
//     //       alignLeft: "true",
//     //       marginright: "auto",
//     //     },
//     //     "&:hover": {
//     //       opacity: 0.5,
//     //     },
//     //   },
//     // },
//     // selected: {},
//     // MuiTabs: {
//     //   root: {
//     //     //width: "100%",
//     //     //height: "100%",
//     //   },
//     // },
//     MuiExpansionPanel: {
//       root: {
//         position: "static",
//       },
//     },
//     MuiTableCell: {
//       root: {
//         paddingLeft: spacing * 2,
//         paddingRight: spacing * 2,
//         borderBottom: `${borderWidth}px solid ${borderColor}`,
//         [`@media (max-width:  ${sm}px)`]: {
//           paddingLeft: spacing,
//           paddingRight: spacing,
//         },
//       },
//     },
//     MuiDivider: {
//       root: {
//         backgroundColor: borderColor,
//         height: borderWidth,
//       },
//     },
//     MuiPrivateNotchedOutline: {
//       root: {
//         borderWidth: borderWidth,
//       },
//     },
//     MuiListItem: {
//       divider: {
//         borderBottom: `${borderWidth}px solid ${borderColor}`,
//       },
//     },
//     MuiDialog: {
//       paper: {
//         width: "100%",
//         maxWidth: 430,
//         marginLeft: spacing,
//         marginRight: spacing,
//       },
//     },
//     MuiTooltip: {
//       tooltip: {
//         backgroundColor: darkBlack,
//       },
//     },
//     MuiExpansionPanelDetails: {
//       root: {
//         [`@media (max-width:  ${sm}px)`]: {
//           paddingLeft: spacing,
//           paddingRight: spacing,
//         },
//       },
//     },
//   },
//   typography: {
//     // fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
//     // fontFamily: "'Nunito Sans', 'Arial', 'sans-serif'",
//     // fontFamily: "'Chilanka', 'cursive', 'sans-serif'",
//     fontFamily: "'Baloo Bhai 2', 'cursive', 'Roboto', 'sans-serif'",
//     useNextVariants: true,
//   },
// });

// theme = responsiveFontSizes(theme);
// export const ColorModeContext = createContext({
//   toggleColorMode: () => {},
// });
export const useMode = () => {
  const [mode, setMode] = useState("light");

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () =>
        setMode((prev) => (prev === "light" ? "dark" : "light")),
    }),
    []
  );

  let theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  theme = responsiveFontSizes(theme);
  return [theme, colorMode];
};
// export default theme;
