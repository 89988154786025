import { Link as RouterLink } from "react-router-dom";
import { dupEmailcheck } from "./zuCustActions";
import { dupEmailcheck2 } from "./zuCustActions";
import { checkZip } from "./zuCustActions";
import { useCustStore } from "./zuCustStore";

export const custUI = [
  {
    ui_data: {
      introline: "Company Name",
      helpertext: "",
      id: "CName",
      colname: "cust_name",
      collabel: "Company Name",
      required: "Y",
      type: "text",
      validationType: "string",
      defaultValue: "Reliance Technologies Inc.",
      // validations: [{ type: "required", params: ["Company Name is Required"] }],
      yupVType: "nmpwdstr",
    },
    form_pg_num: 1,
    form_page_seq: 1,
  },
  {
    ui_data: {
      introline: "Email ID",
      helpertext: "",
      id: "Email",
      colname: "login",
      collabel: "Email ID",
      required: "Y",
      type: "text",
      validationType: "string",
      defaultValue: "123@gmail.com",
      placeholder: "123@gmail.com",
      yupVType: "elogin",
      // validations: [
      //   { type: "required", params: ["Email required"] },
      //   {
      //     type: "test",
      //     params: [
      //       "dup_email_chk",
      //       async (value, { createError }) => {
      //         var validRegex =
      //           /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      //         if (
      //           value !== undefined &&
      //           value !== null &&
      //           value.trim() !== "" &&
      //           value.match(validRegex)
      //         ) {
      //           // const cust = useCustStore.getState();
      //           // const emailChk = cust.emailChk;
      //           // console.log(
      //           //   "dup_email_chk:useCustStore.getState().emailChk:",
      //           //   emailChk
      //           // );
      //           // console.log("dup_email_chk:value:", value);
      //           // if (useCustStore.getState().emailChk.verifiedEmail !== value) {
      //           // await useCustStore.getState().dupEmailcheck(value);
      //           // const status = await dupEmailcheck(value);
      //           const status = await dupEmailcheck(value);
      //           // const vmsg = useCustStore.getState().emailChk;
      //           // console.log("2dup_email_chk:status:");
      //           // }
      //           // if (!useCustStore.getState().emailChk.status) {

      //           if (!status) {
      //             return createError({
      //               message: (
      //                 <>
      //                   {/* {useCustStore.getState().emailChk.msg} */}
      //                   Email exists, Click to <a href="/login">Login</a> or use
      //                   another email.
      //                   {/* <Link
      //                     sx={{ color: "#0000FF" }}
      //                     underline="always"
      //                     // component={RouterLink}
      //                     to="/login"
      //                   >
      //                     Log In
      //                   </Link> */}
      //                 </>
      //               ),
      //             });
      //           } else {
      //             return true;
      //           }
      //         } else {
      //           return createError({
      //             message: "please enter a valid email",
      //           });
      //         }
      //       },
      //     ],
      //   },
      // ],
    },
    form_pg_num: 1,
    form_page_seq: 1,
  },
  {
    ui_data: {
      introline: "Verify Cd",
      helpertext: "We sent a code to your email, Please enter here to verify",
      id: "everify",
      colname: "everify",
      collabel: "Verify Cd",
      required: "Y",
      type: "text",
      initHidden: "Y",
      validationType: "string",
      // defaultValue: "",
      defaultValue: "0000",
      mask: "0000",
      placeholder: "0000",
      // validations: [
      //   {
      //     type: "typeError",
      //     params: ["Enter verify code received in your email"],
      //   },
      //   {
      //     type: "test",
      //     params: [
      //       "everify",
      //       // "Please enter the code sent in your email",
      //       async (value, { createError }) => {
      //         console.log(
      //           "everify:useCustStore.getState().emailChk:",
      //           useCustStore.getState().emailChk
      //         );
      //         if (useCustStore.getState().emailChk.verifyStatus < 3) {
      //           if (
      //             parseInt(useCustStore.getState().emailChk.verifyCode) === 0
      //           ) {
      //             return createError({
      //               message: <>Your code has expired, resend code</>,
      //             });
      //           } else if (
      //             useCustStore.getState().emailChk.verifyCode > 0 &&
      //             parseInt(value) ===
      //               useCustStore.getState().emailChk.verifyCode
      //           ) {
      //             useCustStore.setState((state) => ({
      //               emailChk: { ...state.emailChk, verifyStatus: 3 },
      //             }));
      //             return true;
      //           } else {
      //             return createError({
      //               message: "Please enter the code received in your email",
      //             });
      //           }
      //         } else {
      //           return true;
      //         }
      //       },
      //     ],
      //   },
      // ],
      yupVType: "everify",
    },
    form_pg_num: 1,
    form_page_seq: 1,
  },
  {
    ui_data: {
      introline: "Password",
      helpertext: "",
      id: "pwd",
      colname: "cust_pwd",
      collabel: "Password",
      required: "Y",
      type: "password",
      validationType: "string",
      defaultValue: "password",
      // validations: [
      //   { type: "required", params: ["Password is a required field"] },
      // ],
      yupVType: "nmpwdstr",
    },
    form_pg_num: 1,
    form_page_seq: 1,
  },
  {
    ui_data: {
      introline: "Phone",
      helpertext: "",
      id: "Phone",
      colname: "phnum",
      collabel: "Phone Number",
      required: "Y",
      type: "tel",
      // validationType: "yup-phone",
      validationType: "string",
      defaultValue: "000-000-0000",
      mask: "000-000-0000",
      placeholder: "000-000-0000",
      // validations: [
      //   { type: "required", params: ["Phone# is Required"] },
      //   { type: "min", params: [12, "Phone min 12 chars"] },
      //   { type: "max", params: [12, "Phone max 12 chars"] },
      // ],
      yupVType: "phstr",
    },
    form_pg_num: 1,
    form_page_seq: 1,
  },
  {
    ui_data: {
      introline: "Street Address",
      helpertext: "",
      id: "address",
      colname: "address",
      collabel: "Street Address",
      required: "Y",
      type: "text",
      validationType: "string",
      defaultValue: "101 RELIANCE RD.",
      // validations: [
      //   { type: "required", params: ["Street Address is Required"] },
      // ],
      yupVType: "nmpwdstr",
    },
    form_pg_num: 1,
    form_page_seq: 1,
  },
  {
    ui_data: {
      introline: "ZipCode",
      helpertext: "",
      id: "zip",
      colname: "zipcode",
      collabel: "ZipCode",
      required: "Y",
      type: "zip",
      validationType: "string",
      defaultValue: "00000",
      mask: "00000",
      placeholder: "00000",
      // validations: [
      //   { type: "required", params: ["Zip code required"] },
      //   { type: "min", params: [5, "Zip min 5 chars"] },
      //   { type: "max", params: [5, "Zip max 5 chars"] },
      //   {
      //     type: "test",
      //     params: [
      //       "zip_check",
      //       async (value, { createError }) => {
      //         if (value !== undefined && value !== null && value.length >= 5) {
      //           // console.log("in test schema for zips, value::", value);
      //           // console.log(
      //           //   "in test schema for zips, new verifiedZip",
      //           // useCustStore.getState().emailChk2.verifiedZip
      //           // );
      //           if (useCustStore.getState().emailChk.verifiedZip !== value) {
      //             useCustStore.setState((state) => ({
      //               cust: { ...state.cust, zipcode: value },
      //             }));
      //             // console.log(
      //             //   "in test schema for zips, new validation",
      //             //   useCustStore.getState().cust.zipcode
      //             // );
      //             const res = await checkZip(useCustStore.getState().cust);
      //             // console.log("response zip", res);
      //             if (res.data.status === 200) {
      //               useCustStore.setState((state) => ({
      //                 cust: {
      //                   ...state.cust,
      //                   city: res.data.data.city,
      //                   state: res.data.data.state,
      //                 },
      //               }));
      //               useCustStore.setState((state) => ({
      //                 emailChk: {
      //                   ...state.emailChk,
      //                   verifiedZip: value,
      //                 },
      //               }));
      //               console.log(
      //                 "updated customer 200",
      //                 useCustStore.getState().cust
      //               );
      //               console.log(
      //                 "updated emailChk",
      //                 useCustStore.getState().emailChk
      //               );

      //               return true;
      //             } else if (res.data.status === 400) {
      //               console.log("invalidZip!");
      //               useCustStore.setState((state) => ({
      //                 cust: {
      //                   ...state.cust,
      //                   city: null,
      //                   state: "",
      //                 },
      //               }));
      //               console.log(
      //                 "updated customer 400",
      //                 useCustStore.getState().cust
      //               );
      //               return createError({
      //                 message: "Plese enter a valid zipcode",
      //               });
      //               // return false;
      //             }
      //             return true;
      //           } else {
      //             // console.log(
      //             //   "in test schema for zips2::",
      //             //   useCustStore.getState().emailChk.verifiedZip
      //             // );
      //             return true;
      //           }
      //         } else return true;
      //       },
      //     ],
      //   },
      // ],
      yupVType: "zipstr",
    },
    form_pg_num: 1,
    form_page_seq: 1,
  },
];

export const recoveryUI = [
  {
    ui_data: {
      introline: "Email",
      helpertext: "",
      id: "Email",
      colname: "user_email0",
      collabel: "Email",
      required: "Y",
      type: "text",
      validationType: "string",
      defaultValue: "123@gmail.com",
      // validations: [
      //   {
      //     type: "test",
      //     params: [
      //       "dup_email_chk2",
      //       async (value, { createError }) => {
      //         var validRegex = /\S+@\S+\.\S+/;
      //         console.log("dup_email_chk2 inside email check!");
      //         if (
      //           value !== undefined &&
      //           value !== null &&
      //           value.trim() !== "" &&
      //           value.match(validRegex)
      //         ) {
      //           if (useCustStore.getState().emailChk2.verifiedEmail !== value) {
      //             await dupEmailcheck2(value, "R");
      //             const vmsg = useCustStore.getState().emailChk2;
      //             console.log("dup_email_chk2:vmsg2:", vmsg);
      //           }
      //           if (!useCustStore.getState().emailChk2.status) {
      //             // if (!vmsg.status) {
      //             return createError({
      //               message: (
      //                 <>
      //                   Email does not exist <a href="/signup">SignUp</a>
      //                   {/* <Link
      //                     sx={{ color: "#0000FF" }}
      //                     underline="always"
      //                     component={RouterLink}
      //                     to="/"
      //                   >
      //                     Sign Up
      //                   </Link> */}
      //                 </>
      //               ),
      //             });
      //           } else {
      //             return true;
      //           }
      //         } else {
      //           return createError({
      //             message: "please enter a valid email",
      //           });
      //         }
      //       },
      //     ],
      //   },
      // ],
      // yupVType: "emailrecov",
      yupVType: "rlogin",
    },
    form_pg_num: 1,
    form_page_seq: 1,
  },
  {
    ui_data: {
      introline: "Verify Code",
      helpertext: "Please enter the verify code received in your email",
      id: "everify0",
      colname: "everify0",
      collabel: "Verify Code",
      required: "Y",
      type: "text",
      initHidden: "Y",
      initHidden2: "N",
      validationType: "string",
      // defaultValue: "",
      defaultValue: "0000",
      mask: "0000",
      placeholder: "0000",
      // validations: [
      //   {
      //     type: "required",
      //     params: ["Please Enter code received in your email"],
      //   },
      //   {
      //     type: "test",
      //     params: [
      //       "everify0",
      //       " Please Enter code received in your email",
      //       async (value, { createError }) => {
      //         console.log(
      //           "useCustStore.getState().emailChk2::",
      //           useCustStore.getState().emailChk2
      //         );
      //         if (useCustStore.getState().emailChk2.verifyStatus < 3) {
      //           if (
      //             parseInt(useCustStore.getState().emailChk2.verifyCode) === 0
      //           ) {
      //             return createError({
      //               message: <>Your code has expired, resend code</>,
      //             });
      //           } else if (
      //             parseInt(value) ===
      //             useCustStore.getState().emailChk2.verifyCode
      //           ) {
      //             useCustStore.setState((state) => ({
      //               emailChk2: {
      //                 ...state.emailChk2,
      //                 verifyStatus: 3,
      //                 codeStatus: 1,
      //               },
      //             }));
      //             return true;
      //           } else {
      //             return createError({
      //               message: "Please Enter code received in your email",
      //             });
      //           }
      //         } else {
      //           return true;
      //         }
      //       },
      //     ],
      //   },
      // ],
      // yupVType: "emailverify",
      yupVType: "everify",
    },
    form_pg_num: 1,
    form_page_seq: 1,
  },
  {
    ui_data: {
      introline: "Password",
      helpertext: "",
      id: "pwd",
      colname: "user_pwd0",
      collabel: "Password",
      required: "Y",
      initHidden: "Y",
      initHidden2: "Y",
      type: "password",
      validationType: "string",
      defaultValue: "password",
      validations: [
        { type: "required", params: ["Password is a required field"] },
      ],
      yupVType: "nmpwdstr",
    },
    form_pg_num: 1,
    form_page_seq: 1,
  },
];

export const loginUI = [
  {
    ui_data: {
      introline: "Email",
      helpertext: "",
      id: "Email",
      colname: "user_email",
      collabel: "Email",
      required: "Y",
      type: "text",
      validationType: "string",
      defaultValue: "123@gmail.com",
      // validations: [
      //   {
      //     type: "test",
      //     params: [
      //       "dup_email_chk2",
      //       async (value, { createError }) => {
      //         var validRegex = /\S+@\S+\.\S+/;
      //         console.log("inside email check!");
      //         if (
      //           value !== undefined &&
      //           value !== null &&
      //           value.trim() !== "" &&
      //           value.match(validRegex)
      //         ) {
      //           if (useCustStore.getState().emailChk2.verifiedEmail !== value) {
      //             await dupEmailcheck2(value);
      //             const vmsg = useCustStore.getState().emailChk2;
      //             console.log("vmsg2:", vmsg);
      //           }
      //           if (!useCustStore.getState().emailChk2.status) {
      //             // if (!vmsg.status) {
      //             return createError({
      //               message: (
      //                 <>
      //                   Email does not exist <a href="/signup">SignUp</a>
      //                   {/* <Link
      //                     sx={{ color: "#0000FF" }}
      //                     underline="always"
      //                     component={RouterLink}
      //                     to="/"
      //                   >
      //                     Sign Up
      //                   </Link> */}
      //                 </>
      //               ),
      //             });
      //           } else {
      //             return true;
      //           }
      //         } else {
      //           return createError({
      //             message: "please enter a valid email",
      //           });
      //         }
      //       },
      //     ],
      //   },
      // ],
      yupVType: "llogin",
    },
    form_pg_num: 1,
    form_page_seq: 1,
  },
  {
    ui_data: {
      introline: "Password",
      helpertext: "",
      id: "pwd",
      colname: "user_pwd",
      collabel: "Password",
      required: "Y",
      initHidden: "Y",
      initHidden2: "Y",
      type: "password",
      validationType: "string",
      defaultValue: "password",
      // validations: [
      //   { type: "required", params: ["Password is a required field"] },
      // ],
      yupVType: "nmpwdstr",
    },
    form_pg_num: 1,
    form_page_seq: 2,
  },
];
