import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";

export default function LogoBr() {
  return (
    <Stack
      direction="column"
      sx={{
        flexGrow: 1,
        m: 0,
        p: 0,
      }}
    >
      <Stack
        direction="row"
        sx={{
          flexGrow: 1,
          m: 0,
          p: 0,
        }}
      >
        <Link component={RouterLink} to="/">
          <Box
            display="inline"
            typography="h2"
            color="textSecondary"
            underline="none"
            sx={{
              fontWeight: "bold",
            }}
          >
            snr
          </Box>
          <Box
            display="inline"
            typography="h2"
            color="textPrimary"
            underline="none"
            sx={{
              fontWeight: "bold",
            }}
          >
            gi
          </Box>
          {/* <Box
          display="inline"
          typography="h3"
          color="textSecondary"
          underline="none"
          sx={{
            fontWeight: "bold",
          }}
        >
          A
        </Box>
        <Box
          display="inline"
          typography="h3"
          color="textPrimary"
          underline="none"
          sx={{
            fontWeight: "bold",
          }}
        >
          S
        </Box>
        <Box
          display="inline"
          typography="h3"
          color="textSecondary"
          underline="none"
          sx={{
            fontWeight: "bold",
          }}
        >
          W
        </Box>
        <Box
          display="inline"
          typography="h3"
          color="textPrimary"
          underline="none"
          sx={{
            fontWeight: "bold",
          }}
        >
          A
        </Box>
        <Box
          display="inline"
          typography="h3"
          color="textSecondary"
          underline="none"
          sx={{
            fontWeight: "bold",
          }}
        >
          L
        </Box> */}
        </Link>
      </Stack>
      <Box
        display="inline"
        typography="caption"
        color="textSecondary"
        underline="always"
        sx={{
          pb: 1,
          ml: 0.5,
          mt: { xs: -0.8, sm: -1.2, md: -1.5 },
          fontWeight: "medium",
          fontSize: { xs: "0.5rem", sm: "0.65rem", md: "0.8rem" },
        }}
      >
        by Reliance Technologies
      </Box>
    </Stack>
  );
}
