import * as React from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";

import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

import Paper from "@mui/material/Paper";
import SvgIcon from "@mui/material/SvgIcon";

export default function CustomizedTimeline() {
  const iconSX = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
  const sumText = { color: "textSecondary", fontWeight: "medium" };
  const dtlText = { color: "textPrimary", fontWeight: "light" };
  const paperSx = {
    padding: "6px 16px",
    bgcolor: "common.compbg",
    border: 2,
    borderRadius: "20px 20px 20px 20px",
    borderColor: "primary.main",
  };
  return (
    <Timeline
      align="center"
      sx={{
        "& .MuiTimelineOppositeContent-root": {
          flex: 0.05,
        },
      }}
    >
      <TimelineItem
        sx={{
          m: 0,
          p: 0,
        }}
      >
        <TimelineOppositeContent
          sx={{
            m: 0,
            p: 1,
          }}
        >
          <Typography
            fontWeight="medium"
            variant="h5"
            component="span"
            color="secondary"
          >
            You
          </Typography>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot color="secondary" variant="outlined">
            <SvgIcon
              viewBox="0 0 24 24"
              fontSize="large"
              sx={{
                ...iconSX,
              }}
            >
              <path d="M14 21q-.425 0-.712-.288Q13 20.425 13 20t.288-.712Q13.575 19 14 19q1.175 0 2.087-.462Q17 18.075 17 17.5q0-.35-.325-.65-.325-.3-.9-.55l1.475-1.475q.8.475 1.275 1.125.475.65.475 1.55 0 1.65-1.575 2.575Q15.85 21 14 21Zm-9.425-7.65q-.725-.425-1.15-.988Q3 11.8 3 11q0-1.05.775-1.763.775-.712 2.775-1.587 1.575-.725 2.012-1.013Q9 6.35 9 6q0-.4-.488-.7Q8.025 5 7 5q-.625 0-1.05.15-.425.15-.775.5-.275.275-.675.325-.4.05-.725-.225Q3.45 5.5 3.4 5.1q-.05-.4.225-.725Q4.1 3.8 4.988 3.4 5.875 3 7 3q1.8 0 2.9.812Q11 4.625 11 6q0 .975-.725 1.75T7.35 9.475q-1.45.625-1.9.925-.45.3-.45.6 0 .225.287.438.288.212.788.412ZM18.85 10.4 14.6 6.15l1.05-1.05q.6-.6 1.437-.6.838 0 1.413.6l1.4 1.4q.6.575.6 1.412 0 .838-.6 1.438ZM6 19h1.4l7.2-7.2-1.4-1.4L6 17.6Zm-2 2v-4.25l9.2-9.2 4.25 4.25-9.2 9.2Zm9.2-10.6 1.4 1.4Z" />
            </SvgIcon>
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>

        <TimelineContent sx={{ m: 0, p: 1 }}>
          <Paper
            elevation={0}
            sx={{
              ...paperSx,
            }}
          >
            <Stack>
              <Typography sx={{ ...sumText }} variant="h5" component="span">
                Sign Up - It's easy & quick!
              </Typography>
              <Typography variant="h6" component="span" sx={{ ...dtlText }}>
                Signup and pay first invoice received in the email.
              </Typography>
            </Stack>
          </Paper>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem
        sx={{
          m: 0,
          p: 0,
        }}
      >
        <TimelineOppositeContent
          sx={{
            m: 0,
            p: 1,
          }}
        >
          <Typography
            fontWeight="medium"
            variant="h5"
            component="span"
            color="secondary"
          >
            We
          </Typography>
        </TimelineOppositeContent>

        <TimelineSeparator>
          <TimelineDot color="secondary" variant="outlined">
            <SvgIcon
              viewBox="0 0 24 24"
              fontSize="large"
              sx={{
                ...iconSX,
              }}
            >
              <path d="M5.55 19 2 15.45l1.4-1.4 2.125 2.125 4.25-4.25 1.4 1.425Zm0-8L2 7.45l1.4-1.4 2.125 2.125 4.25-4.25 1.4 1.425ZM13 17v-2h9v2Zm0-8V7h9v2Z" />
            </SvgIcon>
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent
          sx={{
            m: 0,
            p: 1,
          }}
        >
          <Paper
            elevation={0}
            sx={{
              ...paperSx,
            }}
          >
            <Stack>
              <Typography variant="h5" component="span" sx={{ ...sumText }}>
                Gather Info
              </Typography>

              <Typography variant="h6" component="span" sx={{ ...dtlText }}>
                We contact you within 24hrs and gather your business
                requirements.
              </Typography>
            </Stack>
          </Paper>
        </TimelineContent>
      </TimelineItem>

      <TimelineItem
        sx={{
          m: 0,
          p: 0,
        }}
      >
        <TimelineOppositeContent
          sx={{
            m: 0,
            p: 1,
          }}
        >
          <Typography
            fontWeight="medium"
            variant="h5"
            color="secondary"
            component="span"
          >
            We
          </Typography>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot color="secondary" variant="outlined">
            <SvgIcon
              viewBox="0 0 24 24"
              fontSize="large"
              sx={{
                ...iconSX,
              }}
            >
              <path d="m18.85 10-.2-1.05q-.225-.075-.412-.188-.188-.112-.338-.262l-1.05.35-.7-1.2.85-.75q-.05-.125-.05-.2v-.4q0-.075.05-.2l-.85-.75.7-1.2 1.05.35q.15-.15.338-.263.187-.112.412-.187l.2-1.05h1.4l.2 1.05q.225.075.413.187.187.113.337.263l1.05-.35.7 1.2-.85.75q.05.125.05.2v.4q0 .075-.05.2l.85.75-.7 1.2-1.05-.35q-.15.15-.337.262-.188.113-.413.188l-.2 1.05Zm.7-2.25q.525 0 .888-.363.362-.362.362-.887t-.362-.888q-.363-.362-.888-.362t-.887.362q-.363.363-.363.888t.363.887q.362.363.887.363Zm-3 7.25-.15-.75q-.15-.05-.287-.113-.138-.062-.263-.187l-.7.25-.5-.9.55-.5v-.6l-.55-.5.5-.9.7.25q.1-.1.25-.175.15-.075.3-.125l.15-.75h1l.15.75q.15.05.3.125t.25.175l.7-.25.5.9-.55.5v.6l.55.5-.5.9-.7-.25q-.125.125-.262.187-.138.063-.288.113l-.15.75Zm.5-1.75q.3 0 .525-.225.225-.225.225-.525 0-.3-.225-.525-.225-.225-.525-.225-.3 0-.525.225-.225.225-.225.525 0 .3.225.525.225.225.525.225Zm-8-.25q-1.65 0-2.825-1.175Q5.05 10.65 5.05 9H4.8q-.225 0-.362-.137Q4.3 8.725 4.3 8.5q0-.225.138-.363Q4.575 8 4.8 8h.25q0-1.125.55-2.025.55-.9 1.45-1.425v.95q0 .225.138.362Q7.325 6 7.55 6q.225 0 .363-.138.137-.137.137-.362V4.15q.225-.075.475-.113Q8.775 4 9.05 4t.525.037q.25.038.475.113V5.5q0 .225.138.362.137.138.362.138.225 0 .363-.138.137-.137.137-.362v-.95q.9.525 1.45 1.425.55.9.55 2.025h.25q.225 0 .363.137.137.138.137.363 0 .225-.137.363Q13.525 9 13.3 9h-.25q0 1.65-1.175 2.825Q10.7 13 9.05 13Zm0-2q.825 0 1.413-.588.587-.587.587-1.412h-4q0 .825.588 1.412Q8.225 11 9.05 11Zm-8 10v-2.8q0-.825.425-1.55.425-.725 1.175-1.1 1.275-.65 2.875-1.1Q7.125 14 9.05 14q1.925 0 3.525.45 1.6.45 2.875 1.1.75.375 1.175 1.1.425.725.425 1.55V21Zm2-2h12v-.8q0-.275-.137-.5-.138-.225-.363-.35-.9-.45-2.312-.9Q10.825 16 9.05 16q-1.775 0-3.187.45-1.413.45-2.313.9-.225.125-.362.35-.138.225-.138.5Zm6 0Z" />
            </SvgIcon>{" "}
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ m: 0, p: 1 }}>
          <Paper
            elevation={0}
            sx={{
              ...paperSx,
            }}
          >
            <Stack>
              <Typography variant="h5" component="span" sx={{ ...sumText }}>
                Onboard
              </Typography>

              <Typography variant="h6" component="span" sx={{ ...dtlText }}>
                We plan and execute all onboarding steps.
              </Typography>
            </Stack>
          </Paper>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem
        sx={{
          m: 0,
          p: 0,
        }}
      >
        <TimelineOppositeContent sx={{ m: 0, p: 1 }}>
          <Typography
            fontWeight="medium"
            variant="h5"
            component="span"
            color="secondary"
          >
            We
          </Typography>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot color="secondary" variant="outlined">
            <SvgIcon
              viewBox="0 0 24 24"
              fontSize="large"
              sx={{
                ...iconSX,
              }}
            >
              <path d="M11 21v-2h8v-7.1q0-1.45-.55-2.725-.55-1.275-1.5-2.225Q16 6 14.725 5.45 13.45 4.9 12 4.9q-1.45 0-2.725.55Q8 6 7.05 6.95q-.95.95-1.5 2.225Q5 10.45 5 11.9V18H4q-.825 0-1.412-.587Q2 16.825 2 16v-2q0-.575.275-1.013.275-.437.725-.712l.075-1.325q.225-1.825 1.038-3.3.812-1.475 2.025-2.5Q7.35 4.125 8.863 3.562 10.375 3 12 3q1.65 0 3.163.562 1.512.563 2.712 1.6 1.2 1.038 2.013 2.5.812 1.463 1.037 3.263l.075 1.3q.45.225.725.662.275.438.275.963v2.3q0 .55-.275.975-.275.425-.725.65V19q0 .825-.587 1.413Q19.825 21 19 21Zm-2-7q-.425 0-.712-.288Q8 13.425 8 13t.288-.713Q8.575 12 9 12t.713.287Q10 12.575 10 13t-.287.712Q9.425 14 9 14Zm6 0q-.425 0-.712-.288Q14 13.425 14 13t.288-.713Q14.575 12 15 12t.713.287Q16 12.575 16 13t-.287.712Q15.425 14 15 14Zm-8.975-1.55q-.1-1.475.413-2.675.512-1.2 1.374-2.038Q8.675 6.9 9.8 6.45 10.925 6 12.05 6q2.275 0 3.938 1.438Q17.65 8.875 18 11.025 15.65 11 13.75 9.762q-1.9-1.237-2.875-3.212-.4 2.025-1.687 3.587Q7.9 11.7 6.025 12.45Z" />
            </SvgIcon>{" "}
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent
          sx={{
            m: 0,
            p: 1,
          }}
        >
          <Paper
            elevation={0}
            sx={{
              ...paperSx,
            }}
          >
            <Stack>
              <Typography variant="h5" component="span" sx={{ ...sumText }}>
                Support & Training
              </Typography>

              <Typography variant="h6" component="span" sx={{ ...dtlText }}>
                Instant support via email/chat.
              </Typography>
            </Stack>
          </Paper>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent
          sx={{
            m: 0,
            p: 1,
          }}
          variant="body2"
          color="secondary"
        >
          <Typography fontWeight="medium" variant="h5" component="span">
            You
          </Typography>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot color="secondary" variant="outlined">
            <SvgIcon
              viewBox="0 0 24 24"
              fontSize="large"
              sx={{
                ...iconSX,
              }}
            >
              <path d="M6.8 20q-.75 0-1.275-.525Q5 18.95 5 18.2q0-.525.3-.988.3-.462.8-.662L10 15v-2.25q-1.35 1.575-3.137 2.412Q5.075 16 3 16v-2q1.7 0 3.088-.7 1.387-.7 2.512-2l1.35-1.6q.3-.35.7-.525.4-.175.85-.175h1q.45 0 .85.175.4.175.7.525l1.35 1.6q1.125 1.3 2.513 2Q19.3 14 21 14v2q-2.075 0-3.862-.838Q15.35 14.325 14 12.75V15l3.9 1.55q.5.2.8.662.3.463.3.988 0 .75-.525 1.275Q17.95 20 17.2 20H10v-.5q0-.65.425-1.075Q10.85 18 11.5 18h3q.225 0 .363-.137.137-.138.137-.363 0-.225-.137-.363Q14.725 17 14.5 17h-3q-1.05 0-1.775.725Q9 18.45 9 19.5v.5ZM12 8q-.825 0-1.412-.588Q10 6.825 10 6t.588-1.412Q11.175 4 12 4t1.413.588Q14 5.175 14 6t-.587 1.412Q12.825 8 12 8Z" />
            </SvgIcon>{" "}
          </TimelineDot>
        </TimelineSeparator>
        <TimelineContent sx={{ m: 0, p: 1 }}>
          <Paper
            elevation={0}
            sx={{
              ...paperSx,
            }}
          >
            <Stack>
              <Typography variant="h5" component="span" sx={{ ...sumText }}>
                Sit Back and Relax!
              </Typography>
              <Typography variant="h6" component="span" sx={{ ...dtlText }}>
                We got this!
              </Typography>
            </Stack>
          </Paper>
        </TimelineContent>
      </TimelineItem>
    </Timeline>
  );
}
