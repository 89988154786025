import { memo } from "react";
import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import { useState } from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { KeenSliderArrows } from "./Overview";
import CircularProgress from "@mui/material/CircularProgress";
// import ArrowDropDownCircleOutlinedIcon from "@mui/icons-material/ArrowDropDownCircleOutlined";
// import Collapse from "@mui/material/Collapse";
// import CardContent from "@mui/material/CardContent";
// import Accordion from "@mui/material/Accordion";
// import AccordionSummary from "@mui/material/AccordionSummary";
// import { AccordionDetails } from "@mui/material";
// import List from "@mui/material/List";
// import ListItem from "@mui/material/ListItem";
// import open from "../pictures/open.png";

// import { FormProvider, useForm } from "react-hook-form";

// import { SignUpCusTxtFld } from "./SignUpCusTxtFld";
// import { addUpdCartItem } from "../store/zuCartActions";

// import StyledButton from "../shared/StyledButton";
// import Divider from "@mui/material/Divider";
// import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
// import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";

// import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
// import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";

// import AddSharpIcon from "@mui/icons-material/AddSharp";
// import RemoveSharpIcon from "@mui/icons-material/RemoveSharp";

// import StraightSharpIcon from "@mui/icons-material/StraightSharp";
// import LaunchSharpIcon from "@mui/icons-material/LaunchSharp";
// import SouthSharpIcon from "@mui/icons-material/SouthSharp";
// import NorthSharpIcon from "@mui/icons-material/NorthSharp";

// import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
// import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined";

// import IconButton from "@mui/material/IconButton";
// import { Image } from "./OverviewIcon";
// import MoreVertIcon from "@mui/icons-material/MoreVert";
// import Tooltip from "@mui/material/Tooltip";
// import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";

function Tiles(props) {
  const { tiles, mode } = props;
  const [added, setAdded] = React.useState(false);

  // console.log("Tiles::tiles:", tiles);

  const SlideStack = {
    position: "relative",
    // bgcolor: "primary.main",
    bgcolor: "common.compbg",
    mx: 0,
    my: 0,
    p: 0,
    // height: "300px",
    // border: 1,
    borderRadius: "20px 20px 20px 20px",
    // borderRadius: "50%",
  };
  const KeenSliderSlide = {
    // bgcolor: "common.compbg",
    bgcolor: "primary.main",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    m: 0,
    p: 0,
    border: "0.0px",
    borderStyle: "solid",
    width: { xs: "250px", sm: "300px" },
    height: { xs: "250px", sm: "300px" },
    // borderRadius: "10px 10px 10px 10px",
    borderRadius: "50%",
    // border: 1,
  };
  const [currentSlide1, setCurrentSlide1] = useState(0);
  const [loaded1, setLoaded1] = useState(false);
  const [sliderRef1, instanceRef1] = useKeenSlider(
    {
      // mode: "free-snap",
      loop: true,
      initial: 0,

      slideChanged(slider) {
        setCurrentSlide1(slider.track.details.rel);
      },
      created() {
        setLoaded1(true);
      },
      breakpoints: {
        "(min-width: 0px)": {
          slides: { perView: "1", spacing: 2 },
        },
        "(min-width: 400px)": {
          slides: { perView: "1", spacing: 2 },
        },
        "(min-width: 820px)": {
          slides: { perView: "2", spacing: 2 },
        },
        "(min-width: 1000px)": {
          slides: { perView: "3", spacing: 2 },
        },
      },
      slides: { perView: "3", spacing: 2 },
      // slides: { origin: "center", perView: "auto", spacing: 20 },

      // slides: { perView: "4", spacing: 12 },
    },
    [
      (slider) => {
        let timeout;
        let mouseOver = false;
        function clearNextTimeout() {
          clearTimeout(timeout);
        }
        function nextTimeout() {
          clearTimeout(timeout);
          if (mouseOver) return;
          timeout = setTimeout(() => {
            slider.next();
          }, 5000);
        }
        slider.on("created", () => {
          slider.container.addEventListener("mouseover", () => {
            mouseOver = true;
            clearNextTimeout();
          });
          slider.container.addEventListener("mouseout", () => {
            mouseOver = false;
            nextTimeout();
          });
          nextTimeout();
        });
        const observer = new ResizeObserver(function () {
          slider.update();
        });

        slider.on("created", () => {
          observer.observe(slider.container);
        });
        slider.on("destroyed", () => {
          observer.unobserve(slider.container);
        });
        slider.on("dragStarted", clearNextTimeout);
        slider.on("animationEnded", nextTimeout);
        slider.on("updated", nextTimeout);
      },
    ]
  );

  // console.log(addons);

  return (
    <>
      {tiles && tiles.length > 0 ? (
        <Stack
          direction="column"
          sx={{
            ...SlideStack,
            p: 0,
            mt: -3,
            // bgcolor: "common.compbg",
            bgcolor: "primary.main",
            borderRadius: "50%",
          }}
        >
          <Stack
            sx={{
              m: 0,
              p: 0,
              // bgcolor: "common.compbg",
              // borderRadius: "50%",
              // border: 1,
            }}
            ref={sliderRef1}
            className="keen-slider"
            direction="row"
          >
            {/* {tiles.data.map((tile, j) => ( */}
            {tiles.map((tile, j) => (
              <Stack
                key={j}
                className="keen-slider__slide"
                sx={{
                  ...KeenSliderSlide,
                  // width: { xs: "250px", sm: "310px" },
                  // height: { xs: "250px", sm: "310px" },

                  borderRadius: "50%",
                  // m: 0,
                  // p: 0,
                  // border: 1,
                  // bgcolor: "common.compbg",
                }}
              >
                <Stack
                  sx={{
                    ...KeenSliderSlide,
                    bgcolor: "common.compbg",
                    borderRadius: "50%",
                    border: "solid",
                    borderWidth: "0px",
                    borderColor: "primary.main",
                  }}
                >
                  <Stack
                    sx={{
                      ...KeenSliderSlide,
                      bgcolor: "common.compbg",
                      p: 2,
                      mx: 0,

                      my: 1,
                      border: "solid",
                      borderWidth: "5px",
                      borderColor: "primary.main",
                    }}
                  >
                    <Typography
                      variant={"h5"}
                      display="inline"
                      sx={{
                        color: "textSecondary",
                        // color: "textPrimary",
                        wordBreak: "break-word",
                        fontWeight: "bold",
                        whiteSpace: "pre-line",
                      }}
                    >
                      {tile.ui_data.title}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            ))}
          </Stack>

          <Stack
            direction="row"
            sx={{ alignItems: "center", justifyContent: "center", mt: 1 }}
          >
            {loaded1 && instanceRef1.current && (
              <KeenSliderArrows
                currentSlide={currentSlide1}
                sliderInstance={instanceRef1}
                arrowPos="bottom"
              />

              // <>
              //       <Arrow
              //         left
              //         onClick={(e) =>
              //           e.stopPropagation() || instanceRef1.current?.prev()
              //         }
              //         disabled={currentSlide1 === 0}
              //       />

              //       <Arrow
              //         onClick={(e) =>
              //           e.stopPropagation() || instanceRef1.current?.next()
              //         }
              //         disabled={
              //           currentSlide1 ===
              //           instanceRef1.current.track.details.slides.length -
              //             instanceRef1.current.options.slides.perView
              //         }
              //       />
              //     </>
            )}
          </Stack>
        </Stack>
      ) : (
        <>
          <CircularProgress size="large" color="secondary" />
        </>
      )}
    </>
  );
}
export default memo(Tiles);
