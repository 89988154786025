import React from "react";
import { useFormContext } from "react-hook-form";
import { Box } from "@mui/material";
import { useCartStore } from "../store/zuCartStore";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";

export default function FSignUpStep1Sum(props) {
  const cart = useCartStore((state) => state.cart);

  console.log("FSignUpStep1Sum::cart", useCartStore.getState().cart);

  function findTotal() {
    var total = 0;
    cart.map((field) => {
      total += field.quantity * field.price;
    });
    return total;
  }
  var total = findTotal();

  const methods = useFormContext();

  return (
    <>
      {cart && cart.length > 1 ? (
        <>
          {cart
            .filter((field) => field.quantity > 0)
            .map((field, j) => (
              <React.Fragment key={j}>
                <Stack
                  sx={{
                    m: 0.5,
                    p: 1.5,
                    width: "100%",
                    // border: "1.0px",
                    // borderStyle: "solid",
                    borderRadius: "10px 10px 10px 10px",
                    bgcolor: "primary.main",
                  }}
                  justifyContent="center"
                  alignItems="flex-start"
                  direction={"row"}
                >
                  <Stack
                    sx={{
                      m: 0,
                      p: 0,
                      width: "100%",
                      // border: "1.0px",
                      // borderStyle: "solid",
                      justifyContent: "flex-start",
                      alignItems: "space-between",
                    }}
                    direction={"column"}
                  >
                    <Stack
                      sx={{
                        m: 0,
                        p: 0,
                        // width: "100%",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                      }}
                    >
                      <Box
                        sx={{
                          typography: "h5",
                          fontWeight: "bold",
                          color: "textSecondary",
                        }}
                      >
                        {field.ui_data.introline}:
                      </Box>
                    </Stack>
                    <Stack
                      sx={{
                        m: 0,
                        // mb: 0,
                        // pt: 0,
                        p: 0,
                        // width: "40%",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                      }}
                    >
                      <Box
                        sx={{
                          typography: "h6",
                          fontWeight: "medium",
                          color: "textSecondary",
                        }}
                      >
                        ({field.quantity} {field.disp_props.unit} x
                        {field.price_curr}/{field.disp_props.freq})
                        {/* {field.setup_fee > 0 && (
                          <> + {field.setup_fee_curr} setup fee</>
                        )} */}
                        {field.base_price > 0 && (
                          <>
                            {" "}
                            + {field.base_price_curr} base fee/
                            {field.disp_props.freq}
                          </>
                        )}
                      </Box>
                    </Stack>
                  </Stack>
                  <Stack
                    sx={{
                      m: 0,
                      p: 0,
                    }}
                  >
                    <Box
                      sx={{
                        typography: "h6",
                        fontWeight: "medium",
                        alignText: "center",
                        color: "textPrimary",
                      }}
                    >
                      {field.line_total_curr}
                    </Box>
                  </Stack>
                </Stack>
              </React.Fragment>
            ))}
        </>
      ) : (
        <div>Loading</div>
      )}
    </>
  );
}
