import React from "react";
import { SignUpCusTxtFld } from "./SignUpCusTxtFld";
import Stack from "@mui/material/Stack";
import { useCustStore } from "../store/zuCustStore";
import { addNewCustInfo } from "../store/zuCustActions";
import Box from "@mui/material/Box";
import StyledButton from "../shared/StyledButton";
import { sendCode } from "../store/zuCustActions";
import { custUI } from "../store/custUIFormFields";
export default function SignUpCust(props) {
  // console.log("signupcust props ", props.formfields, "custUI", custUI);
  const ff = props.formfields;
  const cust = useCustStore((state) => state.cust);
  // const custUI = useCustStore((state) => state.custUI);
  const emailChk = useCustStore((state) => state.emailChk);
  // console.log("SignUpCust:ff", ff);
  return (
    <Stack
      sx={{
        // pl: 7,
        m: 2,
        p: 2,
        width: { xs: "100", sm: "60%" },
        // bgcolor: "primary.main",
        border: "0px",
        borderStyle: "solid",
        borderRadius: "10px 10px 10px 10px",
      }}
      // spacing={2}
      direction={"column"}
    >
      {ff.map((field, j) => (
        <React.Fragment key={j}>
          <Stack
            sx={{
              m: 0.2,
              p: 0.5,
              // p: 0.5,
              // pl: 7,
              // width: "100%",
              // width: { xs: "100", sm: "50%" },
              bgcolor: "primary.main",
              justifyContent: "space-between",
              alignItems: "flex-start",
              border: "0px",
              borderStyle: "solid",
              borderRadius: "10px 10px 10px 10px",
              // border: 1,
            }}
            // direction="row"
            direction={{ xs: "column", sm: "row" }}
            // justifyContent="flex-start"
            alignItems="center"
            display={
              field.ui_data.initHidden !== "Y"
                ? ""
                : useCustStore.getState().emailChk.verifyStatus === 2
                ? ""
                : "none"
            }
          >
            {/* label */}
            <Stack
              sx={{
                m: 0,
                p: 0,
                width: { xs: "100%", sm: "50%" },
                justifyContent: "center",

                // border: 1,
              }}
            >
              <Stack
                direction="row"
                sx={{
                  mx: 0.2,
                  p: 0,
                  // color: "secondary.main",
                  // typography: "h5",
                  // fontWeight: "bold",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                }}
              >
                <Box
                  sx={{
                    m: 0,
                    p: 0,
                    color: "textSecondary",
                    typography: "h5",
                    fontWeight: "bold",
                  }}
                >
                  {field.ui_data.introline}
                </Box>
                {
                  field.ui_data.colname === "everify" &&
                    useCustStore.getState().emailChk.verifyCode === 1 && (
                      <StyledButton
                        onClick={() =>
                          sendCode(
                            useCustStore.getState().emailChk.verifiedEmail,
                            1
                          )
                        }
                        sx={{
                          m: 0,
                          p: 0,
                          // fontSize: "h5",
                          // fontWeight: "bold",
                          border: "0px",
                          borderStyle: "solid",
                          borderRadius: "20px 20px 20px 20px",
                        }}
                      >
                        Resend
                      </StyledButton>
                    )
                  // : (
                  //   <></>
                  // )
                }
              </Stack>
            </Stack>

            {/* field */}
            <Stack
              key={j}
              sx={{
                m: 0,
                p: 0,
                width: { xs: "100%", sm: "50%" },
                // border: 1,
              }}
            >
              <SignUpCusTxtFld
                allprops={field.ui_data}
                // required={field.ui_data.required === "Y" ? true : false}
                // type={field.ui_data.type}
                // label={field.ui_data.collabel}
                name={field.ui_data.colname}
                validationType={field.ui_data.validationType}
                mask={field.ui_data.mask}
                placeholder={field.ui_data.placeholder}
                onfieldChg={(data) => {
                  console.log(
                    "SignupCust:onfieldchg:colname ",
                    field.ui_data.colname
                  );
                  // console.log("SignupCust:onfieldchg:data ", data);
                  addNewCustInfo(field.ui_data.colname, data);
                  // console.log("SignupCust:onfieldchg:cust", cust);
                  if (emailChk.verifyStatus === 1) {
                    // console.log(
                    //   "verifyStatus before sendmail:",
                    //   emailChk.verifyStatus
                    // );
                    // sendCode(cust);
                    // console.log(
                    //   "verifyStatus after sendmail:",
                    //   emailChk.verifyStatus
                    // );
                  }
                }}
              />
              {field.ui_data.colname === "zipcode" && cust.city && (
                <Box>
                  {cust.city}, {cust.state}
                </Box>
              )}
            </Stack>
          </Stack>
        </React.Fragment>
      ))}
    </Stack>
  );
}
