// import Link from "@mui/material/Link";
// import { Link as RouterLink } from "react-router-dom";
// import myAxios from "../MyAxios";
// import { dupEmailcheck } from "./zuCustActions";
// import { dupEmailcheck2 } from "./zuCustActions";
// import { checkZip } from "./zuCustActions";
import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

const initialState = {
  svc_pkg: 1,
  cust: {},
  validatedUser: null,
  reDir: "",
  currentUser: {
    id: 0,
    cust_id: 0,
    login: "",
    pwd: "",
    startdate: "",
    fname: "",
    lname: "",
    role: "",
    startdate: "",
    grp_id: 0,
    grp_name: "",
    curr_view_cust_id: 0,
  },

  emailChk: {
    status: false,
    msg: "",
    verifyStatus: 0,
    verifyCode: 0,
    verifiedEmail: "",
    verifiedZip: "",
    helperText: "",
  },
  navroutes: [],
  valroutes: [],
  valactions: [],

  // emailChk2: {
  //   status: false,
  //   msg: "",
  //   verifyStatus: 0,
  //   verifyCode: 0,
  //   verifiedEmail: "",
  //   verifiedZip: "",
  //   codeStatus: 0,
  // },
};

const useCustStore = create(
  persist(
    (set) => ({
      ...initialState,
      reset: () => {
        console.log("useCustStore:reset");
        set(initialState);
      },
      resetEmailChk: () => {
        set({ emailChk: initialState.emailChk });
      },
    }),
    {
      name: "zu_cust",
      storage: createJSONStorage(() => sessionStorage),
      partialize: (state) => ({
        // cust: state.cust,
        validatedUser: state.validatedUser,
        currentUser: state.currentUser,
        navroutes: state.navroutes,
        valroutes: state.valroutes,
        valactions: state.valactions,
      }),
    }
  )
);

export { useCustStore };
