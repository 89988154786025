import React, { useEffect } from "react";
// import classNames from "classnames";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useCartStore } from "./store/zuCartStore";
import { useCustStore } from "./store/zuCustStore";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Receipt from "./home/Receipt";
import CheckIcon from "@mui/icons-material/Check";
import LogoBr from "./shared/LogoBR";

function FinishScreen(props) {
  const TAX_RATE = 0.07;
  const cart = useCartStore((state) => state.cart);
  const cust = useCustStore((state) => state.cust);
  const today = new Date();

  useEffect(() => {
    console.log("cart in orderrwview", cart);
    console.log("cust in orderreview", cust);
  });
  function findTotal() {
    var total = 0;
    cart.map((field) => {
      total += field.quantity * field.price;
    });
    return total;
  }
  var total = findTotal();

  return (
    <>
      <Stack
        sx={{
          alignItems: "center",
          bgcolor: "common.compbg",
          width: "100%",
          py: 2,
        }}
      >
        <Stack direction="row">
          <Box sx={{ typography: "h4", fontWeight: "bold" }}>
            Thank You For Your Order!
          </Box>
        </Stack>

        <Paper elevation={0}>
          <Stack
            direction="column"
            sx={{
              alignItems: "center",
              justifyContent: "center",
              bgcolor: "common.compbg",
              width: "100%",
            }}
          >
            <Box
              sx={{
                justifyContent: "flex-end",
                typography: "h6",
                color: "secondary.main",
                width: "80%",
                bgcolor: "primary.main",
                p: 1,
              }}
            >
              Thank you for your order, {cust.cust_name}! Details and
              confirmation have been sent via email to {cust.cust_email}. We
              will be in touch within 24 hours of payment and have you all set
              up within 72 hours of payment.
            </Box>
          </Stack>
        </Paper>
        <Stack
          direction="row"
          sx={{
            width: "80%",
            bgcolor: "primary.main",
            justifyContent: "space-around",
            alignItems: "flex-end",
          }}
        >
          <Box sx={{ p: 2 }}>
            <Box
              sx={{
                color: "common.black",
                typography: "h6",
                fontWeight: "bold",
              }}
            >
              Your order details:
            </Box>
            <Box
              sx={{
                color: "secondary.main",
                typography: "h6",
              }}
            >
              Order Number: {cart[0].order_id}
            </Box>
            <Box
              sx={{
                color: "secondary.main",
                typography: "h6",
              }}
            >
              Order Date: {today.getMonth() + 1}/{today.getDate()}/
              {today.getFullYear()}
            </Box>
            <Box
              sx={{
                color: "secondary.main",
                typography: "h6",
              }}
            >
              Expected Setup: {today.getMonth() + 1}/{today.getDate() + 3}/
              {today.getFullYear()}
            </Box>
          </Box>
          <Box sx={{ p: 2 }}>
            <Box
              sx={{
                color: "common.black",
                typography: "h6",
                fontWeight: "bold",
              }}
            >
              Your details:
            </Box>
            <Box
              sx={{
                color: "secondary.main",
                typography: "h6",
              }}
            >
              Customer ID: {cust.cust_id}
            </Box>
            <Box
              sx={{
                color: "secondary.main",
                typography: "h6",
              }}
            >
              Name: {cust.cust_name}
            </Box>
            <Box
              sx={{
                color: "secondary.main",
                typography: "h6",
              }}
            >
              Email: {cust.cust_email}
            </Box>
          </Box>
        </Stack>
        <Box sx={{ pt: 1 }}>
          <LogoBr />
        </Box>
      </Stack>
      <Stack
        sx={{
          alignItems: "center",
          width: "100%",
          bgcolor: "common.compbg",
        }}
      >
        <Box sx={{ typography: "h6", fontWeight: "bold", pb: 3 }}>
          Payment Method: Quickbooks Invoicing (Invoice Details sent via Email.)
        </Box>
      </Stack>
      <Box sx={{ bgcolor: "common.compbg", width: "100%", py: 3 }}>
        <Receipt />
      </Box>
    </>
  );
}

FinishScreen.propTypes = {
  // classes: PropTypes.object.isRequired,
  // item: PropTypes.oneOfType([
  //   PropTypes.string,
  //   PropTypes.element,
  //   PropTypes.array,
  // ]).isRequired,
};

export default FinishScreen;
