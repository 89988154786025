import React, { useEffect, useLayoutEffect } from "react";
import PropTypes from "prop-types";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

import Stack from "@mui/material/Stack";

import useScrollTrigger from "@mui/material/useScrollTrigger";
import Zoom from "@mui/material/Zoom";

import useTheme from "@mui/material/styles/useTheme";
import useMediaQuery from "@mui/material/useMediaQuery";
import Tooltip from "@mui/material/Tooltip";

import Overview from "./Overview";
import Howitworks from "./Howitworks";
import SignUp from "./SignUp";
import { Link, Outlet, useLocation } from "react-router-dom";

const tabbutton = {
  color: "primary.main",
  bgcolor: "secondary.main",
};

function ScrollTop(props) {
  const { children } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      "#back-to-top-anchor"
    );

    if (anchor) {
      anchor.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  return (
    <Zoom in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: "fixed", bottom: 16, right: 16 }}
      >
        {children}
      </Box>
    </Zoom>
  );
}

ScrollTop.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function SimpleTabs(props) {
  const theme = useTheme();
  let path = useLocation();
  // function getInitialTab() {
  //   console.log("Executing getInitialState...");
  //   if (path.pathname === "/overview") {
  //     return 0;
  //   } else if (path.pathname === "/howitworks") {
  //     return 1;
  //   } else if (path.pathname === "/signup") {
  //     return 2;
  //   } else if (path.pathname === "/") {
  //     return 0;
  //   }
  // }
  // const [value, setValue] = React.useState(() => {
  //   const initialTab = getInitialTab();
  //   return initialTab;
  // });
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    // console.log("value:", value);
    // console.log("location:", location.pathname);
    // if (location.pathname === "/overview" && value !== 0) {
    if (path.pathname === "/overview") {
      // console.log("in :", path.pathname);
      // handleChange(0);
      // setValue((v) => (v = 0));
      setTimeout(() => setValue((v) => (v = 0)), 100);
      // } else if (location.pathname === "/howitworks" && value !== 1) {
    } else if (path.pathname === "/howitworks") {
      // console.log("in :", path.pathname);
      // handleChange(1);
      // setValue((v) => (v = 1));
      setTimeout(() => setValue((v) => (v = 1)), 100);
      // } else if (location.pathname === "/signup" && value !== 2) {
    } else if (path.pathname === "/signup") {
      // handleChange(2);
      // setValue((v) => (v = 2));
      setTimeout(() => setValue((v) => (v = 2)), 100);
      // console.log("in :", path.pathname);
    } else if (path.pathname === "/") {
      // setValue((v) => (v = 0));
      setTimeout(() => setValue((v) => (v = 0)), 100);
      // console.log("in :", path.pathname);
    }
    // switch (path.pathname) {
    //   case "/":
    //     setTimeout(() => setValue((v) => (v = 0)), 100);

    //   case "/overview":
    //     setTimeout(() => setValue((v) => (v = 0)), 100);

    //   case "/howitworks":
    //     setTimeout(() => setValue((v) => (v = 1)), 100);

    //   case "/signup":
    //     setTimeout(() => setValue((v) => (v = 2)), 100);

    //   default:
    //     setTimeout(() => setValue((v) => (v = 0)), 100);
    // }
    return () => null;
  }, [path.pathname, value]);

  console.log("value2:", value);
  // console.log("location2:", path.pathname);
  const isMobile = useMediaQuery(theme.breakpoints.up("xs"));
  return (
    <>
      <Tabs
        // action={tabsRef}
        id="back-to-top-anchor"
        value={value}
        onChange={handleChange}
        aria-label="simple tabs example"
        centered
        // selectionFollowsFocus
        TabIndicatorProps={{
          sx: {
            display: "none",
          },
        }}
        sx={{
          mb: 2,
          // border: 1,
          minHeight: "40px",

          "& .MuiTabs-scroller": {
            // alignItems: "center",
            // justifyContent: "center",
          },
          // "& .MuiTab-root": {
          //   color: "primary.main",
          //   bgcolor: "secondary.main",
          //   alignItems: "center",
          //   justifyContent: "center",
          // },
          "& .MuiButtonBase-root.MuiTab-root": {
            color: "primary.main",
            bgcolor: "secondary.main",
            fontSize: { xs: "h5", sm: "h4" },
            fontWeight: "bold",
            minHeight: "42px",
            textTransform: "none",
            my: 0,
            mx: { xs: 0.5, sm: 1 },
            // py: 0.4,
            py: 0,
            px: { xs: 1, sm: 2 },
            borderRadius: "20px 20px 20px 20px",
            borderColor: "primary.main",
          },
          "& .MuiButtonBase-root.MuiTab-root:hover": {
            // bgcolor: "common.secondary5",
            // color: "primary.main",
            bgcolor: "common.compbg",
            color: "secondary.main",
            border: 0,
          },
          "& .MuiButtonBase-root.MuiTab-root:focus": {
            bgcolor: "common.compbg",
            color: "secondary.main",
            border: 1,
            // color: "primary.main",
            // bgcolor: "secondary.main",
          },
          "& .MuiButtonBase-root.MuiTab-root:active": {
            // color: "primary.main",
            // bgcolor: "secondary.main",

            bgcolor: "common.compbg",
            color: "secondary.main",
            border: 1,
          },
          "& .MuiButtonBase-root.Mui-selected": {
            bgcolor: "common.compbg",
            color: "secondary.main",
            border: 1,
          },
        }}
      >
        <Tooltip title="Overview">
          <Tab
            aria-label="Overview"
            // sx={{
            //   ...tabbutton,
            // }}
            label={isMobile ? "Overview" : "Overview"}
            icon={isMobile ? null : null}
            {...a11yProps(0)}
            component={Link}
            to="overview"
          />
        </Tooltip>
        <Tooltip title="How it Works">
          <Tab
            aria-label="How it Works"
            // sx={{
            //   ...tabbutton,
            // }}
            {...a11yProps(1)}
            label={isMobile ? "HowItWorks" : "HowItWorks"}
            icon={isMobile ? null : null}
            component={Link}
            to="howitworks"
          />
        </Tooltip>

        <Tooltip title="Sign Up">
          <Tab
            aria-label="Sign Up"
            // sx={{
            //   ...tabbutton,
            // }}
            {...a11yProps(2)}
            label={isMobile ? "SignUp" : "SignUp"}
            icon={isMobile ? null : null}
            component={Link}
            to="/signup"
          />
        </Tooltip>
      </Tabs>

      <Outlet />
    </>
  );
}
export default SimpleTabs;
