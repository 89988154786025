import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";

import Stack from "@mui/material/Stack";
import StyledHeader from "./StyledHeader";
import Divider from "@mui/material/Divider";
import LogoBr from "./LogoBR";
import OutlinedDiv from "./OutlinedDiv";
import StyledButtonWIcon from "./StyledButtonWIcon";
import StyledButton from "./StyledButton";
import DashboardToolbar from "../dashboard/pages/DashboardToolbar";
function StyledSection(props) {
  const {
    children,
    bgcolor,
    to,
    validated,
    secVm,
    rounded,
    appendSx,
    logo,
    headerText,
    backBtn,
    saveBtn,
    toolbar,
    ...otherProps
  } = props;

  return (
    <>
      {logo && (
        <Stack>
          <LogoBr />
        </Stack>
      )}
      <Stack
        sx={{
          mb: secVm ?? 4,
          p: 0.3,
          // p: { xs: 1, sm: 0.2 },
          // m: 1,
          bgcolor: bgcolor ?? "common.compbg",
          borderColor: "common.compbg",
          // border: 1,
          borderRadius: rounded === "N" ? "0x 0x 0x 0x" : "10px 10px 10px 10px",
          ...appendSx,
        }}
      >
        {toolbar && (
          <>
            <Stack
              sx={{
                width: "100%",
                m: 0,
                p: 0.5,
                // ml: 1,
                my: 0,
                alignItems: "center",
                justifyContent: "center",
                // border: 1,
              }}
            >
              <DashboardToolbar />
            </Stack>
            <Divider
              sx={{
                color: "primary.main",
                bgcolor: "primary.main",
                border: 2,
                m: 0,
                p: 0,
                ml: 0.5,
              }}
            />
          </>
        )}
        <Stack
          direction="row"
          sx={{
            // px: 3,
            // mx: 0,

            // mb: 0.5,
            width: "100%",
            m: 0,
            p: 0.5,
            // ml: 1,
            // border: 1,
            justifyContent: backBtn ? "space-between" : "center",
            alignItems: "center",
          }}
        >
          {backBtn && props.allowback && (
            <Stack
              sx={{
                m: 0,
                p: 0,
                ml: 0,
                my: 0,
                // width: "10%",
                justifyContent: "flex-start",
              }}
            >
              <StyledButtonWIcon
                iconName="KeyboardBackspaceIcon"
                onClick={backBtn}
                sx={{
                  border: 0,
                  // textTransform: "capitalize",
                  // alignItems: "flex-start",
                  // justifyContent: "flex-start",
                }}
                variant="text"
              >
                Back
              </StyledButtonWIcon>
            </Stack>
          )}

          {headerText && (
            <Stack
              sx={{
                m: 0,
                p: 0,
                // border: 1,
                width: "100%",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  alignItems: "center",
                  justifyContent: "center",
                  // alignItems: "flex-start",
                  // justifyContent: "space-between",
                  // mx: { xs: 2, sm: 2 },
                }}
              >
                <>
                  {/* <Stack
                  direction="row"
                  sx={
                    {
                      // alignItems: "center",
                      // justifyContent: "center",
                      // alignItems: "flex-start",
                      // justifyContent: "space-between",
                      // mx: { xs: 2, sm: 2 },
                    }
                  }
                > */}
                  <Box
                    sx={{
                      // width: "100%",
                      // mx: 1,
                      typography: "h4",
                      color: "textSecondary",
                      // bgcolor: "common.compbg",
                      fontWeight: "bold",
                      // textAlign: "center",
                      // borderRadius: "20px 20px 20px 20px",
                      fontFamily: "default",
                      textAlign: "center",
                    }}
                  >
                    {headerText}
                  </Box>
                  {/* </Stack> */}
                </>
              </Box>
            </Stack>
          )}

          {saveBtn && (
            <Stack
              sx={{
                m: 0,
                p: 0,
                mr: { xs: 1.5, sm: 1 },
                my: 0.2,
                // border: 1,
                width: "15%",
                justifyContent: "flex-end",
              }}
            >
              <StyledButton
                // iconName="KeyboardBackspaceIcon"
                onClick={saveBtn}
                sx={{
                  border: 0,
                  textTransform: "capitalize",
                  // alignItems: "flex-start",
                  // justifyContent: "flex-start",
                }}
                variant="text"
              >
                Save
              </StyledButton>
            </Stack>
          )}
        </Stack>
        {headerText && (
          <Divider
            sx={{
              color: "primary.main",
              bgcolor: "primary.main",
              border: 2,
              mx: { xs: 0.5, sm: 0.5 },
              // mb: 2,
            }}
          />
        )}
        <Stack
          {...otherProps}
          sx={{
            p: 0,
          }}
        >
          {children}
        </Stack>
        {/* </OutlinedDiv> */}
      </Stack>
    </>
  );
}

// StyledSection.propTypes = {
//   headertext: PropTypes.string.isRequired,
// };

export default StyledSection;
